import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import {
  CopyAllOutlined,
  ExtensionOutlined,
  SendOutlined,
} from "@mui/icons-material";

export default function DraftSendEmailButton({ draft, style }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [tooltipOpen, setTooltipOpen] = React.useState(false);

  function handleTooltipOpen() {
    setTooltipOpen(true);
    setTimeout(() => {
      setTooltipOpen(false);
    }, 2000);
  }

  const handleClose = (target) => {
    if (target === "plugin") {
      window.location.href =
        "https://chrome.google.com/webstore/detail/modern-sales-ai/lbeckaghpcojalkiknleahiaelgemhkp";
    }

    if (target === "copy") {
      console.log("draft.body", draft.body);
      handleTooltipOpen();
      navigator.clipboard.writeText(draft.body);
    }
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <Box
        sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
        style={style}
      >
        <Tooltip
          open={tooltipOpen}
          title={"Copied to clipboard"}
          placement="top"
        >
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <SendOutlined />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {/* <MenuItem
          onClick={() => {
            handleClose("send_now");
          }}
        >
          <ListItemIcon>
            <SendOutlined fontSize="small" />
          </ListItemIcon>
          Send Email Now
        </MenuItem> */}
        <MenuItem
          onClick={() => {
            handleClose("plugin");
          }}
        >
          <ListItemIcon>
            <ExtensionOutlined fontSize="small" />
          </ListItemIcon>
          Send Email via Chrome Plugin
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose("copy");
          }}
        >
          <ListItemIcon>
            <CopyAllOutlined fontSize="small" />
          </ListItemIcon>
          Copy & Paste
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
