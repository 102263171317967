import {
  CheckCircle as CheckCircleIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
} from "@mui/icons-material";
import { Button, Divider } from "@mui/material";
import React, { useState } from "react";

import { EditFlowForm } from "./EditFlowForm/EditFlowForm";

export function FlowSection({
  flow,
  campaign,
  conversation,
  saveCampaign,
  getCampaign,
}) {
  const [showFlowSection, setShowFlowSection] = useState();

  return (
    <>
      <Divider width="100%" style={{ marginTop: 32, marginBottom: 32 }} />
      <div style={{ display: "flex", flexDirection: "row" }}>
        {campaign?.flow ? (
          <CheckCircleIcon style={{ color: "#6366f1", marginRight: 8 }} />
        ) : (
          <CheckCircleOutlineIcon style={{ color: "#999", marginRight: 8 }} />
        )}
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <div style={{ display: "flex" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p
                style={{
                  color: "#333",
                  marginTop: 2,
                  marginBottom: 8,
                  fontSize: 18,
                }}
              >
                Flows
              </p>
              <div style={{ color: "#999" }}>
                {!flow.goal &&
                  !showFlowSection &&
                  "Emailing and follow up rules."}
                {flow.goal && !showFlowSection && <>{flow.goal}</>}
              </div>
            </div>
            <div style={{ flexGrow: 1 }} />
            {!showFlowSection && (
              <div>
                <Button
                  variant="contained"
                  disableElevation
                  onClick={() => {
                    setShowFlowSection(!showFlowSection);
                  }}
                  color="secondary"
                  style={{ marginTop: -8 }}
                >
                  Edit flow
                </Button>
              </div>
            )}
          </div>
          {showFlowSection && (
            <EditFlowForm
              conversation={conversation}
              getCampaign={getCampaign}
              saveCampaign={saveCampaign}
              setShowFlowSection={setShowFlowSection}
            />
          )}
        </div>
      </div>
    </>
  );
}
