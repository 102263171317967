import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Box,
  Button,
  CircularProgress,
  circularProgressClasses,
} from "@mui/material";
import dayjs from "dayjs";
import { eventSource } from "jsx/Api";
import { UserContext } from "jsx/context";
import { useContext } from "react";
import { useParams } from "react-router-dom";

export function InboxButtons({
  conversation,
  isRefreshingInbox,
  isGeneratingDraft,
  setGeneratingDraft,
  setCurrentDraft,
  checkForUpdates,
  onConversationGet,
}) {
  const { campaignId, leadId } = useParams();
  const { user, workspace } = useContext(UserContext);

  async function createDraft() {
    setGeneratingDraft(true);
    let text = "";
    var source = await eventSource(
      `leads/createDraftEmailsForConversation/${workspace.id}/${campaignId}/${conversation.id}/${leadId}`
    );
    source.onmessage = function (e) {
      const data = JSON.parse(e.data);
      text = text.concat(data.choices[0].text.replaceAll("\n", "<br>"));
      const subject = "";
      let html = text.trim();
      html = html.trim();
      if (html.length < 10) {
        // remove leading <br>
        var regex = /^\s*(?:<br\s*\/?\s*>)+|(?:<br\s*\/?\s*>)+\s*$/gi;
        html = html.replace(regex, ""); //changed replacement
      }
      const newDraft = {
        subject,
        html,
        prompt: "",
        createdAt: { seconds: Date.now() / 1000 },
      };
      setCurrentDraft(newDraft);
    };

    source.addEventListener("close", async function (e) {
      setTimeout(async () => {
        await onConversationGet();
        setGeneratingDraft(false);
        setCurrentDraft({});
      }, 200);
      source.close();
    });
  }

  return (
    <>
      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ flexGrow: 1 }}></div>
        {conversation?.threadId && (
          <Button
            startIcon={
              isRefreshingInbox ? (
                <CircularProgress
                  variant="indeterminate"
                  disableShrink
                  sx={{
                    color: (theme) =>
                      theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
                    animationDuration: "550ms",
                    left: 0,
                    [`& .${circularProgressClasses.circle}`]: {
                      strokeLinecap: "round",
                    },
                  }}
                  size={20}
                  thickness={4}
                />
              ) : (
                <RefreshIcon />
              )
            }
            variant="contained"
            disableElevation
            disabled={isRefreshingInbox}
            color="secondary"
            onClick={checkForUpdates}
            sx={{ marginRight: 2 }}
          >
            Refresh inbox
          </Button>
        )}
        <Button
          variant="contained"
          disableElevation
          disabled={isGeneratingDraft}
          onClick={createDraft}
          startIcon={
            isGeneratingDraft ? (
              <CircularProgress
                variant="indeterminate"
                disableShrink
                sx={{
                  color: (theme) =>
                    theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
                  animationDuration: "550ms",
                  left: 0,
                  [`& .${circularProgressClasses.circle}`]: {
                    strokeLinecap: "round",
                  },
                }}
                size={20}
                thickness={4}
              />
            ) : null
          }
        >
          {conversation?.draft ? "Regenerate email" : "Generate draft email"}
        </Button>
      </Box>
      {conversation?.refreshedAt && (
        <div
          style={{
            float: "Right",
            fontSize: 14,
            marginBottom: 16,
            marginTop: 16,
            color: "#666",
          }}
        >
          Last refreshed{" "}
          {dayjs(parseInt(conversation?.refreshedAt?.seconds * 1000)).fromNow()}
        </div>
      )}
    </>
  );
}
