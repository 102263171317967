import { Button, Divider } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

export function AdminWorkspaceItem({ workspace, updateWorkspace }) {
  function onImpersonate() {
    updateWorkspace({ ...workspace, impersonating: true });
  }

  return (
    <div>
      <Divider style={{ width: "100%" }} />
      <div
        style={{
          display: "flex",
          paddingTop: 24,
          paddingBottom: 24,
          justifyItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ flexGrow: 1 }}>
          <div style={{ marginBottom: 12 }}>{workspace.name ?? "Untitled"}</div>
          {/* <div style={{ color: "#666" }}>
            {dayjs(parseInt(e.createdAt?.seconds * 1000)).fromNow()}
          </div> */}
        </div>
        <Link to={`/dashboard`}>
          <Button onClick={onImpersonate}>Impersonate</Button>
        </Link>
        {/* <Link to={`/autopilot/${e.id}`} style={{ textDecoration: "none" }}>
          <Button variant="contained" disableElevation color="secondary">
            View
          </Button>
        </Link> */}
      </div>
    </div>
  );
}
