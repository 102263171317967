import { Grid } from "@mui/material";
import { SignInForm } from "jsx/components";
import { UserContext } from "jsx/context";
import { useContext, useEffect } from "react";

export function SignInPage() {
  const { user } = useContext(UserContext);

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={12} style={{ maxWidth: "400px", width: "100%" }}>
        <SignInForm isSignin={true} hideSignup={true}></SignInForm>
      </Grid>
    </Grid>
  );
}
