import { ArrowRightAlt as ArrowRightAltIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Chip, Grid, Paper, Slider } from "@mui/material";
import classNames from "classnames";
import MSAnalytics from "jsx/api/analytics";
import React, { useEffect, useState } from "react";

import { getPriceByIndex } from "../../../utils";
import { PricingBenefit, PricingTooltip } from "../../";
import styles from "../styles.module.scss";
import { bossModeBenefits, defaultPricingMarks } from "./data";

export function PricingBossModeCard({
  billingPlan,
  loading,
  selectedPlanId,
  onPlanSubmit,
}) {
  const [priceIndex, setPriceIndex] = useState(1);
  const classes = classNames(styles.card, styles.selected);

  const [isAnnually, setIsAnnually] = useState(false);

  useEffect(() => {
    setIsAnnually(billingPlan?.name === "Annually");
  }, [selectedPlanId]);

  const selectedPrice = getPriceByIndex(billingPlan?.prices || [], priceIndex);

  function onPriceIndexChange(e) {
    const price = getPriceByIndex(e.target.value);
    MSAnalytics.track("Selection Changed", {
      page: "Pricing",
      selectionType: "Word Count Slider",
      billingPlan: price?.interval,
      planSize: price?.transform_quantity?.divide_by,
      price: price?.unit_amount / 100,
      priceId: price?.id,
    });

    setPriceIndex(e.target.value);
  }

  let formattedPrice;

  if (isAnnually) {
    formattedPrice = (
      (selectedPrice?.unit_amount / 100 / 12).toFixed(0) || 0
    ).toLocaleString("en-US");
  } else {
    formattedPrice = (selectedPrice?.unit_amount / 100 || 0).toLocaleString(
      "en-US"
    );
  }

  let pricingMarks;
  let maxSteps;
  if (billingPlan?.prices) {
    pricingMarks = [
      {
        value: 0,
        label:
          billingPlan.prices[0].transform_quantity.divide_by.toLocaleString(
            "en-US"
          ),
      },
      {
        value: billingPlan.prices.length - 1,
        label:
          billingPlan.prices[
            billingPlan.prices.length - 1
          ].transform_quantity.divide_by.toLocaleString("en-US"),
      },
    ];

    maxSteps = billingPlan?.prices.length - 1;
  } else {
    pricingMarks = defaultPricingMarks;
    maxSteps = 3;
  }

  return (
    <Paper className={classes}>
      <div className={styles.top}>
        <div className={styles.type}>
          <Chip className={styles.chip} label="Pro Plan" color="primary" />
          <PricingTooltip title="Writing lots of sales emails? Modern Sales AI will do it for you 100x faster, and better">
            <div style={{ fontSize: 14 }}>
              For Individuals and Small Businesses
            </div>
          </PricingTooltip>
        </div>
        <Grid className={styles.billing} container>
          <div className={styles.price}>
            ${formattedPrice}
            <span>/month</span>
            {isAnnually && (
              <span className={styles.description}> - billing annually</span>
            )}
          </div>
        </Grid>
        <div className={styles.info}>
          Powerful tools to write personalized, relevant emails like an
          enterprise sales team without the cost
        </div>
        <div className={styles.words}>
          Email up to{" "}
          {(selectedPrice?.transform_quantity?.divide_by || 0).toLocaleString(
            "en-US"
          )}{" "}
          leads
        </div>
        <Slider
          className={styles.slider}
          aria-label="Pricing marks"
          value={priceIndex}
          onChange={onPriceIndexChange}
          getAriaValueText={(value, i) => `${value} words`}
          valueLabelDisplay="off"
          min={0}
          max={maxSteps}
          step={0.01}
          marks={pricingMarks}
        />
      </div>
      <div className={styles.divider} />
      <div className={styles.bottom}>
        <div className={styles.benefits}>
          {bossModeBenefits.map((benefit, i) => (
            <PricingBenefit key={i} benefit={benefit} />
          ))}
        </div>
        <LoadingButton
          className={styles.submit}
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          disableElevation={true}
          onClick={() => {
            MSAnalytics.track("Button Clicked", {
              page: "Pricing",
              buttonType: "Start free trial",
              billingPlan: selectedPrice?.interval,
              planSize: selectedPrice?.transform_quantity?.divide_by,
              price: selectedPrice?.unit_amount / 100,
              priceId: selectedPrice?.id,
            });
            onPlanSubmit(selectedPrice?.id, selectedPrice);
          }}
          loading={loading}
          style={{ height: 50 }}
        >
          <span>Start free trial</span>
          <ArrowRightAltIcon />
        </LoadingButton>
      </div>
    </Paper>
  );
}
