import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  Check,
  SaveAsOutlined,
  SaveOutlined,
  VisibilityOff,
} from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import SendIcon from "@mui/icons-material/Send";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Divider,
  Grid,
  Paper,
  TextField,
  Tooltip,
} from "@mui/material";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { post } from "jsx/Api";
import { useContext, useMemo, useRef, useState } from "react";

import { UserContext } from "../../context/UserContext";

const dayjs = require("dayjs");
var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

export function EmailCell({
  style,
  key,
  from,
  to,
  html,
  timestamp,
  draft,
  sendDraft,
  campaignId,
  leadId,
  conversationId,
  getConversation,
  email,
}) {
  const [isSending, setSending] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [editedHtml, setEditedHtml] = useState(html);
  const [editedSubject, setEditedSubject] = useState(draft?.subject);
  const [isSavingDraft, setSavingDraft] = useState(false);

  const [viewPrompt, setViewPrompt] = useState(false);
  const { user, workspace } = useContext(UserContext);
  const saveDraftButton = useRef();

  const db = getFirestore();

  let buttons;
  let bodySection;
  let promptSection;

  async function saveDraft() {
    const newDraft = draft;
    newDraft.html = editedHtml;
    newDraft.subject = editedSubject;
    await setDoc(
      doc(
        db,
        "workspaces",
        workspace.id,
        "workspaces_private",
        "data",
        "campaigns",
        campaignId,
        "leads",
        leadId,
        "conversations",
        conversationId
      ),
      { draft: newDraft },
      { merge: true }
    );

    if (getConversation) {
      getConversation();
    }
  }

  const saveDraftToGmail = async () => {
    setSavingDraft(true);
    await saveDraft();

    await post("leads/saveDraftEmailsForConversation", {
      campaignId,
      leadId,
      conversationId: conversationId,
    });

    setSavingDraft(false);
  };

  if (viewPrompt) {
    promptSection = (
      <>
        <Divider width={"100%"} style={{ marginTop: 32, marginBottom: 32 }} />
        <div
          dangerouslySetInnerHTML={{
            __html: draft.prompt.replaceAll("\n", "<br />"),
          }}
        />
      </>
    );
  }

  if (draft) {
    if (isEditing) {
      buttons = (
        <>
          <Button
            color="gray"
            variant="outlined"
            onClick={() => {
              setEditing(false);
            }}
          >
            Cancel
          </Button>
          <Button
            style={{ marginLeft: 16 }}
            variant="outlined"
            onClick={async () => {
              setSaving(true);
              await saveDraft();
              setSaving(false);
              setEditing(false);
            }}
            disabled={isSaving}
            startIcon={
              isSaving ? (
                <CircularProgress
                  variant="indeterminate"
                  disableShrink
                  sx={{
                    color: (theme) =>
                      theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
                    animationDuration: "550ms",
                    left: 0,
                    [`& .${circularProgressClasses.circle}`]: {
                      strokeLinecap: "round",
                    },
                  }}
                  size={20}
                  thickness={4}
                />
              ) : (
                <></>
              )
            }
          >
            Save
          </Button>
        </>
      );
    } else {
      buttons = (
        <>
          <Button
            color="gray"
            variant="outlined"
            size="small"
            onClick={() => {
              setViewPrompt(!viewPrompt);
            }}
            style={{ marginRight: 8 }}
          >
            {viewPrompt ? "Hide" : "View"} prompt
          </Button>
          <LoadingButton
            ref={saveDraftButton}
            color="gray"
            variant="outlined"
            size="small"
            startIcon={<SaveOutlined />}
            onClick={() => {
              saveDraftToGmail();
            }}
            style={{ marginRight: 8 }}
            loading={isSavingDraft}
          >
            Save draft
          </LoadingButton>

          <Button
            color="gray"
            variant="outlined"
            size="small"
            startIcon={<EditIcon />}
            onClick={() => {
              setEditing(true);
            }}
          >
            Edit draft
          </Button>
          <Button
            style={{ marginLeft: 8 }}
            variant="outlined"
            onClick={async () => {
              setSending(true);
              await sendDraft();
              setSending(false);
            }}
            size="small"
            disabled={isSending}
            startIcon={
              isSending ? (
                <CircularProgress
                  variant="indeterminate"
                  disableShrink
                  sx={{
                    color: (theme) =>
                      theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
                    animationDuration: "550ms",
                    left: 0,
                    [`& .${circularProgressClasses.circle}`]: {
                      strokeLinecap: "round",
                    },
                  }}
                  size={20}
                  thickness={4}
                />
              ) : (
                <SendIcon />
              )
            }
          >
            Send email
          </Button>
        </>
      );
    }
  }
  if (isEditing) {
    const cleanedHtml = html.replace(/<img class="ms-logo-asset".*?\/>/g, "");

    bodySection = (
      <CKEditor
        editor={ClassicEditor}
        config={{
          toolbar: [
            "heading",
            "|",
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList",
            "blockQuote",
            "|",
            "undo",
            "redo",
          ],
        }}
        data={cleanedHtml}
        onReady={(editor) => {}}
        onChange={(event, editor) => {
          const data = editor.getData();
          setEditedHtml(data);
        }}
      />
    );
  } else {
    const cleanedHtml = html.replace(/<img class="ms-logo-asset".*?\/>/g, "");
    bodySection = (
      <div
        dangerouslySetInnerHTML={{
          __html: cleanedHtml,
        }}
      />
    );
  }

  let subjectSection;

  if (draft?.subject) {
    if (isEditing) {
      subjectSection = (
        <>
          <TextField
            defaultValue={draft.subject}
            onChange={(e) => {
              setEditedSubject(e.target.value);
            }}
          ></TextField>
        </>
      );
    } else {
      subjectSection = (
        <div style={{ fontSize: 16, marginBottom: 16 }}>{draft.subject}</div>
      );
    }
  }

  let trackingSection;
  if (email && email.trackingId) {
    trackingSection = (
      <Tooltip
        title={
          email.openCount
            ? `Email has been opened ${email.openCount} times`
            : "Email has not been opened"
        }
      >
        <div style={{ marginTop: -8, display: "flex" }}>
          <div style={{ marginRight: -7 }}>
            <Check
              sx={{
                stroke: email.openCount ? "#6366f1" : "#6366f1",
                strokeWidth: 2,
              }}
              fontSize="small"
            />
          </div>

          <div style={{ marginRight: -10 }}>
            <Check
              sx={{
                stroke: email.openCount ? "#6366f1" : "#CCC",
                strokeWidth: 2,
              }}
              fontSize="small"
            />
          </div>
        </div>
      </Tooltip>
    );
  }

  return (
    <>
      <div key={key} style={{ ...style }}>
        <div style={{ display: "flex", marginBottom: 8 }}>
          <div style={{ flexGrow: 1 }} />
          <div>{buttons}</div>
        </div>
        <Paper variant="outlined" style={{ padding: 24 }}>
          {timestamp && (
            <div style={{ display: "flex" }}>
              <div
                style={{
                  color: "#666",
                  fontSize: 14,
                }}
              >
                {dayjs(parseInt(timestamp)).format("ddd, MMM D, YYYY h:mm A	")}(
                {dayjs(parseInt(timestamp)).fromNow()})
              </div>
              <div style={{ flexGrow: 1 }} />
              {trackingSection}
            </div>
          )}

          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
              }}
            >
              {subjectSection}
              <h4 style={{ lineHeight: 0 }}>From: {from}</h4>
              <div
                style={{
                  color: "#666",
                  marginBottom: 16,
                  fontSize: 14,
                }}
              >
                to {to}
              </div>
            </div>
          </div>
          <Grid container>
            {bodySection}
            {promptSection}
          </Grid>
        </Paper>
      </div>
    </>
  );
}
