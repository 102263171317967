import { LoadingButton } from "@mui/lab";
import { Grid, Paper, TextField } from "@mui/material";
import { post } from "jsx/Api";
import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { UserContext } from "../../../context/UserContext";

export function CreateWorkSpaceForm() {
  const { user, reloadUser, updateWorkspace } = useContext(UserContext);

  const uid = useParams()["userId"] ?? user.uid;
  const [workspaceName, setWorkspaceName] = useState();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  async function createWorkspace() {
    setLoading(true);
    const result = await post("workspaces/createWorkspace", {
      name: workspaceName,
    });
    await reloadUser();
    updateWorkspace({ id: result.data.id, name: workspaceName });
    navigate(`/dashboard` + document.location.search, { replace: true });
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (!workspaceName) {
      alert("Requires a workspace name.");
      return;
    }

    createWorkspace(workspaceName);
  }

  return (
    <Grid
      container
      direction="column"
      sx={{
        height: "100%",
        width: "100%",
        maxWidth: "540px",
        p: { lg: 6, xs: 2 },
        pt: { lg: 10, xs: 4 },
      }}
    >
      <Paper variant="outlined" style={{ padding: 32 }}>
        <Grid>
          <h2 style={{ marginTop: 0 }}>Create a workspace</h2>
          <p>Workspaces help your organize your work</p>
          <h5 style={{ marginBottom: 12 }}>Company name</h5>
          <form onSubmit={handleSubmit}>
            <TextField
              autoFocus={true}
              placeholder="Acme, Inc."
              style={{ width: "100%" }}
              onChange={(e) => {
                setWorkspaceName(e.target.value);
              }}
            />
            <LoadingButton
              variant="contained"
              size="large"
              type="submit"
              style={{ width: "100%", marginTop: 32, height: 50 }}
              disableElevation={true}
              loading={loading}
            >
              Create workspace
            </LoadingButton>
          </form>
        </Grid>
      </Paper>
    </Grid>
  );
}
