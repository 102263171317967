import { Grid } from "@mui/material";
import React from "react";

import { IntegrationItem } from "./IntegrationItem/IntegrationItem";

export function IntegrationsList({ integrations }) {
  return (
    <Grid
      container
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        paddingBottom: "20px",
      }}
      spacing={2}
    >
      {integrations.map((integration, i) => (
        <IntegrationItem key={i} integration={integration} />
      ))}
    </Grid>
  );
}
