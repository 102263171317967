import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { collection, doc, getDoc, getDocs, setDoc } from "firebase/firestore";
import { get } from "jsx/Api";
import MSAnalytics from "jsx/api/analytics";
import firebaseApp, { isLocal } from "jsx/api/firebase.setup";
import { useEffect, useState } from "react";

import { useLocalStorage } from "./useLocalStorage";

const { auth, db } = firebaseApp;

const defaultCtx = {
  user: {
    // displayName: "",
    // photoURL: "",
    // uid: "",
    // email: "",
    // organizations: [],
  },
};

async function getSubscriptions(workspaceId) {
  let subscriptions = [];

  if (workspaceId) {
    // check if the workspace has subscriptions first
    subscriptions = (
      await get("workspaces/subscriptions", {
        workspaceId,
      })
    ).data;
  }

  // if empty check on the user
  // this is when a workspace isn't creted yet but the user is the one creating the subscription
  // on the paywall
  if (subscriptions.length === 0) {
    console.log("subscriptions.length", subscriptions.length);
    const s = await getDocs(
      collection(db, "users", auth.currentUser.uid, "subscriptions")
    );

    console.log("s.docs", s.docs);

    const map = s.docs.map((e) => {
      return {
        id: e.id,
        ...e.data(),
      };
    });
    subscriptions = map;
    console.log("auth.currentUser.uid", auth.currentUser.uid);
    console.log("subscriptions", subscriptions);
  }

  return subscriptions;
}

export function useAuthState() {
  const [ctx, setCtx] = useState(defaultCtx);
  const [hasProcessedAuth, setHasProcessedAuth] = useState(false);
  const [workspace, setWorkspace, removeWorkspace] =
    useLocalStorage("workspace");

  const isSiteAdminUNSAFE =
    isLocal ||
    ctx?.user?.email === "vu0tran@gmail.com" ||
    ctx?.user?.email === "rwu721@gmail.com";

  function saveCtx() {
    localStorage.setItem("ctx", JSON.stringify(ctx));
  }

  function updateCtx(ctx) {
    setCtx((prev) => {
      return {
        ...ctx,
      };
    });
  }

  function updateWorkspace(workspace) {
    console.log("udpate workspace", workspace);
    setWorkspace(workspace);
  }

  // Logout updates the user data to default
  async function logout() {
    const auth = getAuth();
    try {
      await signOut(auth);
      localStorage.removeItem("workspace");
      localStorage.clear();
      setWorkspace(null);
      updateCtx(null);

      console.log("Check", localStorage.getItem("workspace"));

      setCtx({
        user: {
          displayName: "",
          photoURL: "",
          uid: "",
          email: "",
          userLoggedOut: true,
        },
      });
    } catch (e) {
      console.log("error", e);
    }
  }

  async function reloadUser() {
    if (!auth.currentUser?.uid) {
      return;
    }
    const newCtx = ctx;

    const gotUser = await getDoc(
      doc(db, "users", auth.currentUser.uid, "users_private", "data")
    );

    const userAdmin = await getDoc(
      doc(db, "users", auth.currentUser.uid, "users_admin", "data")
    );

    let workspaceId;

    if (!workspace && userAdmin?.workspaces && userAdmin?.workspaces.length) {
      workspaceId = userAdmin?.workspaces[0];
      updateWorkspace(userAdmin?.workspaces[0]);
    } else {
      workspaceId = workspace.id;
    }

    const details = gotUser.data();
    details.admin = userAdmin.data() ?? {};

    if (workspaceId) {
      console.log("workspaceId", workspaceId);
      const workspaceAdmin = await getDoc(
        doc(db, "workspaces", workspaceId, "workspaces_admin", "data")
      );

      details.workspaceAdmin = workspaceAdmin.data();

      const workspacePrivate = await getDoc(
        doc(db, "workspaces", workspaceId, "workspaces_private", "data")
      );

      updateWorkspace({
        id: workspaceId,
        ...workspacePrivate.data(),
      });
    }

    details.subscriptions = await getSubscriptions(workspaceId);

    newCtx.user = details;
    updateCtx(newCtx);
  }

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const newCtx = ctx;
        newCtx.user = user;

        await setDoc(
          doc(db, "users", auth.currentUser.uid, "users_private", "data"),
          JSON.parse(JSON.stringify(user)),
          { merge: true }
        );

        const gotUser = await getDoc(
          doc(db, "users", auth.currentUser.uid, "users_private", "data")
        );
        const userAdmin = await getDoc(
          doc(db, "users", auth.currentUser.uid, "users_admin", "data")
        );

        const details = gotUser.data();
        details.admin = userAdmin.data() ?? {};

        let workspaceId;

        if (
          !workspace &&
          details.admin?.workspaces &&
          details.admin?.workspaces.length
        ) {
          workspaceId = details.admin?.workspaces[0];
          updateWorkspace(details.admin?.workspaces[0]);
        } else {
          workspaceId = workspace.id;
        }

        if (workspaceId) {
          console.log("workspaceId222", workspaceId);

          const workspaceAdmin = await getDoc(
            doc(db, "workspaces", workspaceId, "workspaces_admin", "data")
          );

          details.workspaceAdmin = workspaceAdmin.data();

          const workspacePrivate = await getDoc(
            doc(db, "workspaces", workspaceId, "workspaces_private", "data")
          );

          updateWorkspace({
            id: workspaceId,
            ...workspacePrivate.data(),
          });
        }

        details.subscriptions = await getSubscriptions(workspaceId);

        newCtx.user = details;
        updateCtx(newCtx);

        const {
          createdAt,
          displayName,
          email,
          emailVerified,
          isAnonymous,
          lastLoginAt,
          photoURL,
          uid,
        } = details;

        MSAnalytics.identify(auth.currentUser.uid, {
          createdAt,
          displayName,
          email,
          emailVerified,
          isAnonymous,
          lastLoginAt,
          photoURL,
          uid,
        });
      } else {
        // User is signed out
        // ...
      }
      console.log("BOTTOM");
      setHasProcessedAuth(true);
    });
  }, []);

  return {
    ctx,
    workspace,
    hasProcessedAuth,
    isSiteAdminUNSAFE,
    logout,
    updateCtx,
    reloadUser,
    updateWorkspace,
  };
}
