import * as React from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { collection, getDocs, getFirestore } from "firebase/firestore";
import { UserContext } from "jsx/context";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { getFirstNameLastName } from "jsx/utils";

const filter = createFilterOptions();

export default function AddableContactAutocomplete({
  style,
  onAddedNewContact,
  onSelectedContact,
  selectedContactId,
  contacts,
  generate,
  fullnameInput = false,
}) {
  const [value, setValue] = React.useState(null);
  const [open, toggleOpen] = React.useState(false);
  const { workspace } = React.useContext(UserContext);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (contacts && contacts.length && selectedContactId) {
      const selected = contacts.find((e) => e.id === selectedContactId);
      setValue(selected);
      onSelectedContact(selected);
    }
  }, [contacts, selectedContactId]);

  const handleClose = () => {
    setDialogValue({
      jobTitle: "",
      year: "",
    });
    toggleOpen(false);
  };

  const [dialogValue, setDialogValue] = React.useState({
    jobTitle: "",
    year: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    setValue({
      jobTitle: dialogValue.jobTitle,
      year: parseInt(dialogValue.year, 10),
    });
    handleClose();
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      setTimeout(() => {
        generate();
      }, 10);
    }
  };

  return (
    <React.Fragment>
      <Autocomplete
        freeSolo
        value={value ?? ""}
        onKeyDown={handleKeyDown}
        onChange={(event, newValue) => {
          console.log("NEWVALUE", newValue);
          if (typeof newValue === "string") {
            // // timeout to avoid instant validation of the dialog's form.
            // setTimeout(() => {
            //   navigate(`/contacts/new?fullName=${newValue}`);
            // });

            setValue(newValue);

            if (fullnameInput) {
              const { firstName, lastName } = getFirstNameLastName(newValue);
              return onAddedNewContact({ firstName, lastName });
            } else {
              return onAddedNewContact({ firstName: newValue });
            }
          } else {
            if (
              newValue &&
              newValue.firstName &&
              newValue.firstName.includes(`Add new contact "`)
            ) {
              const { firstName, lastName } = getFirstNameLastName(
                newValue.inputValue
              );

              newValue.firstName = firstName;
              newValue.lastName = lastName;
            }

            setValue(newValue);
            onSelectedContact(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== "") {
            filtered.push({
              inputValue: params.inputValue,
              firstName: `Add new contact "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        id="free-solo-dialog-demo"
        options={contacts}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return formatOption(option);
        }}
        // selectOnFocus
        autoSelect
        blurOnSelect
        handleHomeEndKeys
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id ?? "add-cell"}>
              {formatOption(option)}
            </li>
          );
        }}
        sx={{ ...style }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={fullnameInput ? "Jane Doe" : "Jane"}
            InputLabelProps={{ shrink: false }}
            InputProps={{
              ...params.InputProps,
              classes: {
                notchedOutline: "no-border-notched",
              },
            }}
            size="small"
            style={{
              height: 39,
              border: "1px solid #DDD",
              borderRadius: 4,
              boxShadow: "2px 2px 3px rgba(0, 0, 0, 0.03)",
            }}
          />
        )}
      />
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Add a new film</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Did you miss any film in our list? Please, add it!
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              value={dialogValue.firstName}
              onChange={(event) =>
                setDialogValue({
                  ...dialogValue,
                  firstName: event.target.value,
                })
              }
              label="jobTitle"
              type="text"
              variant="standard"
            />
            <TextField
              margin="dense"
              id="name"
              value={dialogValue.year}
              onChange={(event) =>
                setDialogValue({
                  ...dialogValue,
                  year: event.target.value,
                })
              }
              label="year"
              type="number"
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Add</Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}

function formatOption(option) {
  let valueFormat = (
    (option.firstName ?? "") +
    " " +
    (option.lastName ?? "")
  ).trim();

  // if (option.companyName) {
  //   valueFormat += ` – ${option.companyName}`;
  // }

  // if (option.title) {
  //   valueFormat += ` – ${option.title}`;
  // }

  return valueFormat.trim();
}
