import { SmartToyOutlined } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Grid, Tab, Button } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";

import { DraftCell } from "..";
import { DraftsHistory } from "./DraftsHistory/DraftsHistory";
import { EmptyDraft } from "./EmptyDraft/EmptyDraft";

export function DraftsSection({ drafts, currentDraft }) {
  const [currentTab, setCurrentTab] = useState("1");

  const onCurrentTabChange = (tab, value) => {
    setCurrentTab(value);
  };

  return (
    <Grid
      item
      md={12}
      lg={6}
      sx={{
        height: "calc(100vh)",
        overflowY: { lg: "auto", md: "" },
        background: "white",
        paddingRight: 0,
        paddingLeft: 0,
        borderLeft: "1px solid #E9E9E9",
        width: "100%",
      }}
    >
      {/* {isGeneratingDraft && <DraftLoadingCell style={{ marginBottom: 16 }} />} */}
      <TabContext value={currentTab}>
        <Box
          sx={{
            borderBottom: 1,
            width: "100%",
            zIndex: 1,
            background: "white",
            position: { lg: "sticky", md: "block" },
            borderTop: { lg: "0px", md: "1px" },
            borderColor: "divider",
            top: 0,
          }}
        >
          <div style={{ display: "flex" }}>
            <TabList
              onChange={onCurrentTabChange}
              aria-label="lab API tabs example"
            >
              <Tab label="New Outputs" value="1" />
              <Tab label="Drafts History" value="2" />
            </TabList>
            <div style={{ flexGrow: 1 }} />
            <Link to={"/autopilot"} style={{ textDecoration: "none" }}>
              <Button
                style={{
                  margin: 8,
                  right: 0,
                }}
                startIcon={<SmartToyOutlined />}
                variant="contained"
                size="small"
                disableElevation
              >
                Send more with Autopilot
              </Button>
            </Link>
          </div>
        </Box>
        <TabPanel value="1">
          {currentDraft ? (
            <div style={{ paddingBottom: 80 }}>
              <DraftCell
                key={currentDraft?.body}
                defaultDraft={currentDraft}
                style={{ marginBottom: 16 }}
              />
            </div>
          ) : (
            <EmptyDraft />
          )}
        </TabPanel>
        <TabPanel value="2">
          <DraftsHistory drafts={drafts} />
        </TabPanel>
      </TabContext>
    </Grid>
  );
}
