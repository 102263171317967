import { ArrowBack } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import { collection, doc, getFirestore } from "firebase/firestore";
import { ErrorMessage, Field, Form, Formik } from "formik";
import Uploader from "jsx/components/Uploader/Uploader";
import { UserContext } from "jsx/context";
import {
  getFirstNameLastName,
  removeFromObjectUndefinedValues,
  uniqueArrayOfObjects,
} from "jsx/utils";
import { batchLimitParallel } from "jsx/utils/batch";
import { getContentWhereFieldInArray } from "jsx/utils/firestore";
import Papa from "papaparse";
import { Fragment, useCallback, useContext, useMemo, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";

import { ChooseImportMethod } from "./ChooseImportMethod";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  bgcolor: "background.paper",
  border: "2px solid #333",
  boxShadow: 24,
  p: 4,
  maxHeight: "80vh",
  minHeight: "320px",
  overflowY: "auto",
};

const allContactsFields = [
  {
    field: "firstName",
    label: "First name",
  },

  {
    field: "lastName",
    label: "Last name",
  },

  {
    field: "fullName",
    label: "Full name",
  },
  {
    field: "email",
    label: "Email",
  },
  {
    field: "website",
    label: "Website",
  },
  {
    field: "linkedin",
    label: "LinkedIn",
  },
  {
    field: "companyName",
    label: "Company name",
  },
  {
    field: "jobTitle",
    label: "Job title",
  },
  {
    field: "location",
    label: "Location",
  },
  {
    field: "interests",
    label: "Interests",
  },
  {
    field: "summary",
    label: "Summary",
  },
];

const formSchema = Yup.object().shape({
  email: Yup.string().required("Required"),
});

export function UploadCSVContactsModal({ showModal, shouldHideModal, tagsOptions = [] }) {
  const [loading, setLoading] = useState(false);
  const db = getFirestore();
  const { workspace } = useContext(UserContext);
  const [parsedData, setParsedData] = useState();
  const [parsedFieldsData, setParsedFieldsData] = useState([]);
  const [allTags, setAllTags] = useState(["All"]);
  const [tagsInputValue, setTagsInputValue] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [importMethod, setImportMethod] = useState();

  const resetAllStates = () => {
    setLoading(false);
    setParsedFieldsData();
    setParsedData();
    setSelectedFile();
    setAllTags(["All"]);
    setImportMethod();
  };

  const parseContacts = useCallback(({ data, errorText }) => {
    Papa.parse(data, {
      header: true,
      complete: (res) => {
        if (res.errors.length > 0) {
          console.log("papaparse res errors", res);
          toast.error(errorText);
          return;
        }
        const filteredEmpty = res.data.filter(
          (item) => !Object.values(item).every((value) => value.length === 0)
        );
        setParsedData(filteredEmpty);
        setParsedFieldsData(res.meta.fields);
      },
      error: () => {
        toast.error(errorText);
      },
    });
  }, []);

  const onFileChanged = useCallback(
    (file) => {
      if (file.type !== "text/csv") {
        setSelectedFile();
        setParsedFieldsData();
        setParsedData();
        toast.error("This is not a CSV file");
        return;
      }
      parseContacts({
        data: file,
        errorText: "Error parsing file. Please try again",
      });
    },
    [parseContacts]
  );

  const onCreateContacts = async (values) => {
    try {
      if (!parsedData) {
        toast.error("Error parsing file. Please try again");
        return;
      }
      setLoading(true);
      const data = parsedData.map((item) => {
        return allContactsFields.reduce(
          (acc, { field }) => ({
            ...acc,
            [field]: item[values[field]],
            tags: allTags,
          }),
          {}
        );
      });

      const withParsedFullNames = data
        .map((item) => {
          let fullnameMap = {};

          if (item.fullName) {
            fullnameMap = getFirstNameLastName(item.fullName);
          }

          return { ...item, ...fullnameMap };
        })
        .map(removeFromObjectUndefinedValues);

      const contactsWithUniqueEmails = uniqueArrayOfObjects(
        withParsedFullNames,
        "email"
      );

      let someContactsEmailsExists = false;
      const emailsToAdd = contactsWithUniqueEmails.map(
        (contact) => contact.email
      );

      const contactsCollectionRef = collection(
        db,
        [
          "workspaces",
          workspace.id,
          "workspaces_private",
          "data",
          "contacts",
        ].join("/")
      );

      const existingContacts = await getContentWhereFieldInArray({
        collectionRef: contactsCollectionRef,
        field: "email",
        valuesToSearchIn: emailsToAdd,
      });

      const existEmailsInDB = existingContacts.map((contact) => contact.email);
      if (existEmailsInDB.length) {
        someContactsEmailsExists = true;
      }

      const contactsToAdd = contactsWithUniqueEmails.filter(
        (contact) => !existEmailsInDB.includes(contact.email)
      );

      await batchLimitParallel({
        firestore: db,
        items: contactsToAdd,
        onEach: async (contact, batch) => {
          const ref = doc(
            collection(
              db,
              [
                "workspaces",
                workspace.id,
                "workspaces_private",
                "data",
                "contacts",
              ].join("/")
            )
          );
          batch.set(ref, {
            ...contact,
            createdAt: new Date(),
            source: "csvImportContacts",
          });
        },
      });

      if (someContactsEmailsExists) {
        toast.info(
          "Some contacts with emails that already exist in your contact list were not added"
        );
      }

      resetAllStates();
      shouldHideModal();
    } catch (error) {
      console.log("error", error);

      toast.error("Error with creating contacts please try again");
      setLoading(false);
    }
  };

  const onChooseImportMethod = useCallback((method) => {
    setImportMethod(method);
  }, []);

  const titleModal = useMemo(() => {
    if (!importMethod) return "How would you like to add contacts?";
    if (importMethod === "file") return "Upload CSV file contacts";
    if (importMethod === "text") return "Copy and paste your contacts";
    return "Upload other contacts";
  }, [importMethod]);

  const uploadField = useMemo(() => {
    if (!importMethod) return <></>;
    if (importMethod === "file") {
      return (
        <Uploader
          mainText="Drag and drop a CSV file to upload"
          onFileChanged={onFileChanged}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
        />
      );
    }
    if (importMethod === "text") {
      return (
        <>
          <Formik
            initialValues={{
              textImport: "",
            }}
            onSubmit={(values) => {
              parseContacts({
                data: values.textImport.trim(),
                errorText:
                  "Error with parse text, please check the correctness of the copied text",
              });
            }}
          >
            {(props) => (
              <>
                <div>Paste your contact information into this field. </div>

                <Form>
                  <Field
                    id="textImport"
                    name="textImport"
                    placeholder="Email Address			              First Name	                  Last Name	                  Website                                                    
                                ryan@gmail.com	                       Ryan		                  Rolansky		           https://www.linkedin.com/in/ryanroslansky"
                    component="textarea"
                    rows="8"
                    className="form-textarea"
                  />
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "end",
                      mt: 1,
                    }}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{ marginLeft: "auto" }}
                      disableElevation
                    >
                      Upload
                    </Button>
                  </Box>
                </Form>
              </>
            )}
          </Formik>
        </>
      );
    }
    return <></>;
  }, [importMethod, onFileChanged, parseContacts, selectedFile]);

  if (!importMethod) {
    return (
      <Modal
        open={showModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onBackdropClick={() => {
          resetAllStates();
          shouldHideModal();
        }}
      >
        <Box sx={style}>
          <Typography
            variant="h4"
            id="modal-modal-title"
            sx={{ mb: 4, display: "flex", alignItems: "center", gap: 2 }}
          >
            {titleModal}
          </Typography>

          <ChooseImportMethod onChoose={onChooseImportMethod} />
        </Box>
      </Modal>
    );
  }

  return (
    <Modal
      open={showModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onBackdropClick={() => {
        resetAllStates();
        shouldHideModal();
      }}
    >
      <Box sx={style}>
        <Typography
          variant="h4"
          id="modal-modal-title"
          sx={{ mb: 2, display: "flex", alignItems: "center", gap: 2 }}
        >
          <IconButton onClick={() => resetAllStates()}>
            <ArrowBack />
          </IconButton>
          {titleModal}
        </Typography>
        <div>{uploadField}</div>
        {parsedData && setParsedFieldsData && (
          <Formik
            enableReinitialize
            initialValues={allContactsFields.reduce(
              (acc, { field }) => ({
                ...acc,
                [field]:
                  parsedFieldsData.find(
                    (i) =>
                      field.trim().toLocaleLowerCase() ===
                      i.trim().toLocaleLowerCase()
                  ) ?? "",
              }),
              {}
            )}
            validationSchema={formSchema}
            onSubmit={onCreateContacts}
          >
            {({ handleChange, errors, values }) => (
              <Form>
                <h4>Compare fields to columns in file</h4>
                {allContactsFields.map(({ field: contactField, label }) => {
                  return (
                    <Fragment key={contactField}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 4,
                          justifyContent: "space-between",
                        }}
                      >
                        <label htmlFor={contactField} className="form-label">
                          {label}
                        </label>
                        <Field
                          as="select"
                          name={contactField}
                          style={{ maxWidth: "50%" }}
                          value={
                            parsedFieldsData.find(
                              (i) =>
                                contactField.trim().toLocaleLowerCase() ===
                                i.trim().toLocaleLowerCase()
                            ) ??
                            values[contactField] ??
                            ""
                          }
                        >
                          <option key="DEFAULT" value="" disabled>
                            Choose column
                          </option>
                          {parsedFieldsData.map((field) => (
                            <option key={contactField + field} value={field}>
                              {field}
                            </option>
                          ))}
                        </Field>
                      </Box>
                      <ErrorMessage
                        name={contactField}
                        className="form-error"
                        component="div"
                      />
                    </Fragment>
                  );
                })}

                <h4>Add tags to contacts</h4>
                <Autocomplete
                  multiple
                  id="tags-outlined"
                  options={tagsOptions}
                  filterSelectedOptions
                  value={allTags}
                  freeSolo
                  onChange={(e, tags) => {
                    setAllTags(tags);
                    setTagsInputValue("");
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  onBlur={(e) => {
                    if (!e.target.value.trim()) return;
                    setTagsInputValue("");
                    setAllTags((prev) => [...prev, e.target.value.trim()]);
                  }}
                  inputValue={tagsInputValue}
                  onInputChange={(e) => setTagsInputValue(e.target.value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="New tag"
                    />
                  )}
                />

                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                    mt: 2,
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={loading}
                    sx={{ marginLeft: "auto" }}
                    disableElevation
                  >
                    Create
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        )}
      </Box>
    </Modal>
  );
}
