import { Button, Grid, Skeleton, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { EditLeadModal } from "jsx/components";
import { PromptSection, SideMenu } from "jsx/components";
import { UserContext } from "jsx/context";
import { useCampaignsService, useLeadsService } from "jsx/hooks";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

import { LeadsSection } from "./components";

export function Campaign(props) {
  const defaultCampaign = props.campaign;
  const { user } = useContext(UserContext);
  const { campaignId } = useParams();
  const uid = useParams()["userId"] ?? user.uid;

  const [campaign, setCampaign] = useState(defaultCampaign);
  const [leads, setLeads] = useState();
  const [editName, setEditName] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editedName, setEditedName] = useState();
  const [showAddModal, setShowAddModal] = useState(false);

  const { getCampaign, saveCampaign } = useCampaignsService();
  const { addLeads, createLead, deleteLead, getLeads } =
    useLeadsService(campaignId);

  const onLeadDelete = (leadId) => {
    deleteLead(leadId, () => setLeads(null));
  };

  const onLeadCreate = (options) => {
    return createLead(options, campaign);
  };

  const onCampaignSave = () => {
    saveCampaign(campaignId, editedName);
    getCampaign(campaignId, (data) => setCampaign(data));
  };

  useEffect(() => {
    if (editedName?.length) {
      setEditMode(true);
    }
  }, [editedName]);

  useEffect(() => {
    if (uid && !campaign) {
      getCampaign(campaignId, (data) => setCampaign(data));
    }

    if (uid) {
      getLeads((leads) => setLeads(leads));
    }
  }, [uid]);

  let content;

  if (campaign) {
    content = (
      <>
        <Box
          sx={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            {editName && (
              <TextField
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.target.blur();
                    onCampaignSave();
                  }
                }}
                onBlur={() => {
                  setEditName(false);
                  onCampaignSave();
                }}
                onFocus={() => {
                  setEditedName(campaign.name ?? "Untitled");
                }}
                onChange={(e) => {
                  setEditedName(e.target.value);
                }}
                style={{ padding: 0, margin: 0 }}
                InputProps={{ style: { fontSize: 42 } }} // font size of input label
                defaultValue={campaign.name ?? "Untitled"}
              />
            )}
            {!editName && (
              <h1 style={{ marginBottom: -8 }}>
                {campaign.name ?? "Untitled"}
              </h1>
            )}
            {!editName && (
              <div
                style={{
                  cursor: "pointer",
                  marginTop: 16,
                  fontSize: 14,
                  color: "#1a90ff",
                }}
                onClick={() => {
                  setEditName(true);
                }}
              >
                Edit campaign name
              </div>
            )}
          </div>
          <div style={{ flexGrow: 1 }} />
          {(editMode || editName) && (
            <>
              <Button
                variant="contained"
                disableElevation
                color="secondary"
                style={{ marginRight: 8, marginLeft: 16 }}
                onClick={() => {
                  setEditMode(false);
                  setEditName(false);
                }}
                size="large"
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                disableElevation
                onClick={() => {
                  onCampaignSave();
                  setEditMode(false);
                  setEditName(false);
                }}
                size="large"
              >
                Save
              </Button>
            </>
          )}
        </Box>
        <PromptSection
          campaign={campaign}
          getCampaign={() => {
            getCampaign(campaignId, (data) => setCampaign(data));
          }}
        />
        <div style={{ paddingBottom: 128 }}>
          <LeadsSection
            leads={leads}
            campaign={campaign}
            onLeadDelete={onLeadDelete}
            setShowAddModal={setShowAddModal}
            getLeads={getLeads}
          />
        </div>
      </>
    );
  } else {
    content = (
      <>
        <Skeleton
          variant="rectangular"
          height={400}
          style={{ marginTop: 32 }}
        />
      </>
    );
  }

  return (
    <Grid container>
      <ToastContainer />
      <EditLeadModal
        showModal={showAddModal}
        shouldHideModal={() => {
          setShowAddModal(false);
        }}
        getLeads={getLeads((leads) => setLeads(leads))}
        onAddContacts={(selectedContacts) => {
          addLeads(selectedContacts, campaign).then(
            ({ someLeadEmailsExists }) => {
              if (someLeadEmailsExists) {
                toast.info(
                  "Some recipients with emails that already exist in this recipients list were not added"
                );
              }
            }
          );
        }}
      />
      <SideMenu />
      <Box
        className="container"
        sx={{ flexGrow: 1, justifyContent: "center", alignItems: "center" }}
      >
        <Box sx={{ height: 400, maxWidth: 1200, margin: "0 auto" }}>
          {content}
        </Box>
      </Box>
    </Grid>
  );
}
