import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";

import { UserContext } from "../../../context/UserContext";

export function WorkspaceSelectorButton() {
  const { user, workspace, updateWorkspace } = React.useContext(UserContext);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    if (user.admin) {
    }
  }, [user.admin]);

  return (
    <div>
      <div
        id="workspace-selector-button"
        className="workspace-selector-button"
        onClick={handleClick}
      >
        <div style={{ flexDirection: "row", display: "flex" }}>
          <div style={{ flexDirection: "column", display: "flex" }}>
            <span
              style={{
                color: "#666",
                fontSize: "11px",
                letterSpacing: "0.5px",
              }}
            >
              WORKSPACE
            </span>
            <div style={{ fontWeight: 600, marginTop: 4 }}>
              {workspace?.name}
            </div>
          </div>
          <div style={{ flexGrow: 1 }} />
          <UnfoldMoreIcon
            style={{ color: "#999", marginTop: 6, marginRight: -10 }}
          />
        </div>
      </div>
      <Menu
        id="workspace-selector-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "workspace-selector-button",
        }}
      >
        {user?.admin?.workspaces &&
          user?.admin?.workspaces.map((e, i) => {
            return (
              <MenuItem
                key={i}
                onClick={() => {
                  updateWorkspace(e);
                  handleClose();
                }}
              >
                {e.name}
              </MenuItem>
            );
          })}

        <MenuItem onClick={handleClose}>Create new workspace</MenuItem>
      </Menu>
    </div>
  );
}
