import React, { useState, useEffect } from "react";
import styles from "./UseCases.module.scss";
import { Button } from "@mui/material";
import { ArrowRightAlt as ArrowRightAltIcon } from "@mui/icons-material";
import { UseCaseOption } from "./components";

const useCasesList = [
  "Blog posts",
  "Social posts",
  "Email",
  "Website content",
  "Ads",
  "Other",
];

export function UseCases() {
  const [selectedOptions, setSelectedOptions] = useState([]);

  function onUseCaseSelect(useCase, isSelected) {
    setSelectedOptions((prevOptions) =>
      isSelected
        ? prevOptions.filter((option) => option !== useCase)
        : [...prevOptions, useCase]
    );
  }

  return (
    <div className={styles.page}>
      <div className={styles.top}>
        <div className={styles.title}>
          How do you plan to use Modern Sales AI?
        </div>
        <div className={styles.subtitle}>
          Select all that apply. You can change this later at any time/
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.options}>
          {useCasesList.map((useCase, i) => (
            <UseCaseOption
              key={i}
              useCase={useCase}
              isSelected={selectedOptions.some((option) => option === useCase)}
              onUseCaseSelect={onUseCaseSelect}
            />
          ))}
        </div>
        <Button
          className={styles.submit}
          variant="contained"
          color="primary"
          size="large"
          disabled={!Boolean(selectedOptions?.length)}
        >
          <span>Next</span>
          <ArrowRightAltIcon />
        </Button>
      </div>
    </div>
  );
}
