import { Button, FormHelperText, Grid, TextField } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { UserContext } from "jsx/context";
import _ from "lodash";
import React, { useContext } from "react";

import { campaignFormSchema } from "./campaign-form.schema";

export function EditCampaignForm({
  campaign,
  showAdvanced,
  isSiteAdminUNSAFE,
  getCampaign,
  saveCampaign,
  setShowAdvanced,
  setShowCampaignSection,
}) {
  const { workspace } = useContext(UserContext);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        goal: campaign?.goal ?? "",
        senderCaseStudies: campaign?.senderCaseStudies ?? "",
        senderCustomerWins: campaign?.senderCustomerWins ?? "",
        senderConstraints: campaign?.senderConstraints ?? "",
        senderOtherInfo: campaign?.senderOtherInfo ?? "",
        senderCompanyContext:
          campaign?.senderCompanyContext ??
          workspace?.senderCompanyContext ??
          "",
        senderCompanyName:
          campaign?.senderCompanyName ?? workspace?.companyName ?? "",
        persona: campaign?.persona ?? "",
      }}
      validationSchema={campaignFormSchema}
      onSubmit={async (values) => {
        const filtered = _(values)
          .omitBy(_.isEmpty)
          .omitBy(_.isUndefined)
          .omitBy(_.isNull)
          .value();

        await saveCampaign(filtered);
        setShowCampaignSection(false);
        await getCampaign();
      }}
    >
      {({ handleChange, errors, values, isSubmitting }) => (
        <Form>
          <h5 style={{ marginBottom: 12 }}>Goal of the outreach *</h5>
          <TextField
            size="small"
            multiline={true}
            placeholder="Try to schedule a 30 minute meeting with recipient next Tuesday or Thursday."
            style={{ width: "100%" }}
            onChange={handleChange}
            value={values.goal}
            name="goal"
          />
          {errors.goal && <FormHelperText error>Required</FormHelperText>}

          <h5 style={{ marginBottom: 12 }}>Company name*</h5>
          <TextField
            style={{ width: "100%" }}
            size="small"
            multiline={true}
            placeholder="Acme, Inc."
            onChange={handleChange}
            value={values.senderCompanyName}
            name="senderCompanyName"
          />
          {errors.senderCompanyName && (
            <FormHelperText error>Required</FormHelperText>
          )}

          <h5 style={{ marginBottom: 12, marginTop: 32 }}>
            Context to include in email*
          </h5>
          <TextField
            size="small"
            multiline={true}
            placeholder="Modern Sales AI automates your sales process to increase revenue with unmatched efficiency."
            style={{ width: "100%" }}
            onChange={handleChange}
            value={values.senderCompanyContext}
            name="senderCompanyContext"
          />
          {errors.senderCompanyContext && (
            <FormHelperText error>Required</FormHelperText>
          )}

          <Grid item xs={12}>
            <div>
              <label htmlFor="persona" className="form-label">
                Persona
              </label>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Field
              as="select"
              id="persona"
              name="persona"
              className="form-input"
            >
              <option value="default">Default</option>
              <option value="sameCulturePersona">Same Culture</option>
              <option value="sameCollegePersona">Same College</option>
              <option value="favoriteSportPersona">Favorite Sport</option>
              <option value="commonInvestorPersona">Common Investor</option>
              <option value="sharedInterestPersona">Shared Interest</option>
              <option value="mostRecentNewsPersona">Company Recent News</option>
              <option value="companyContextPersona">Company Profile</option>
              <option value="linkedInProfilePersona">Linkedin Profile</option>
              <option value="publicationPersona">Linkedin Publication</option>
              <option value="leadExperiencePersona">LinkedIn Experience</option>
              <option value="recommendationPersona">
                LinkedIn Recommendation
              </option>
              <option value="linkedinProfileCommonalityPersona">
                LinkedIn Commonality
              </option>
              <option value="linkedinProfileCityCommonalityPersona">
                Common City
              </option>
            </Field>
          </Grid>

          {/* <h5 style={{ marginBottom: 12, marginTop: 32 }}>Case studies</h5>
          <TextField
            size="small"
            multiline={true}
            placeholder="Modern Sales boosted qualified leads for Acme, Inc by 345% in the first month, which led to annual contracts worth over $1.1M."
            style={{ width: "100%" }}
            onChange={handleChange}
            value={values.senderCaseStudies}
            name="senderCaseStudies"
          />
          <h5 style={{ marginBottom: 12, marginTop: 32 }}>
            Other customer wins
          </h5>
          <TextField
            size="small"
            multiline={true}
            placeholder="We have helped over 1000 customers including Apple, Google, and Microsoft generate over $1B in sales."
            style={{ width: "100%" }}
            onChange={handleChange}
            value={values.senderCustomerWins}
            name="senderCustomerWins"
          /> */}
          {/* <h5 style={{ marginBottom: 12, marginTop: 32 }}>Constraints</h5>
          <TextField
            size="small"
            multiline={true}
            placeholder="Only offer free trials to XYZ type of companies. Never offer discounts."
            style={{ width: "100%" }}
            onChange={handleChange}
            value={values.senderConstraints}
            name="senderConstraints"
          />
          <h5 style={{ marginBottom: 12, marginTop: 32 }}>
            Other information that can be useful
          </h5>
          <TextField
            size="small"
            multiline={true}
            placeholder="We are happy to be offer you 10% in your first month."
            style={{ width: "100%" }}
            onChange={handleChange}
            value={values.senderOtherInfo}
            name="senderOtherInfo"
          /> */}
          {showAdvanced && (
            <>
              <h5 style={{ marginBottom: 12, marginTop: 32 }}>Prompt</h5>
              <TextField
                multiline={true}
                placeholder="Prompt"
                style={{ width: "100%" }}
                onChange={handleChange}
                value={values.prompt}
                name="prompt"
              />
            </>
          )}
          <div style={{ marginTop: 32 }}>
            <Button
              variant="contained"
              disableElevation
              type="submit"
              disabled={isSubmitting}
              style={{ marginRight: 12 }}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              disableElevation
              type="submit"
              disabled={isSubmitting}
              onClick={() => {
                setShowCampaignSection(false);
              }}
            >
              Cancel
            </Button>
            {isSiteAdminUNSAFE && (
              <div
                style={{
                  cursor: "pointer",
                  marginTop: 24,
                  fontSize: 14,
                  color: "#999",
                  float: "right",
                  marginBottom: 32,
                }}
                onClick={() => {
                  setShowAdvanced(!showAdvanced);
                }}
              >
                {showAdvanced ? "Hide" : "Show"} advanced
              </div>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
}
