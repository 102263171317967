import dayjs from "dayjs";

export function getSubscriptionInfo(subscription) {
  let credits = 0;
  let text;

  if (subscription.items) {
    credits = subscription.items[0].price.transform_quantity.divide_by;

    if (subscription.current_period_end) {
      const date = dayjs(subscription.current_period_end.seconds * 1000);
      const formatedDate = `${date.fromNow()} (${date.format(
        "MMMM DD, YYYY"
      )})`;

      text = subscription.cancel_at_period_end
        ? `Subscription is cancelled and will end ${formatedDate}`
        : `Billing cycle renews ${formatedDate}`;
    }
  }

  return { subscriptionText: text, subscriptionCredits: credits };
}
