import { Search, SummarizeOutlined } from "@mui/icons-material";
import { Avatar, Grid, Paper } from "@mui/material";
import { Box } from "@mui/system";
import { SideMenu } from "jsx/components";
import { Link, useParams } from "react-router-dom";

export function TranscriptTemplates() {
  const params = useParams();
  const transcriptId = useParams()["transcriptId"];

  return (
    <Grid sx={{ display: "flex" }}>
      <SideMenu />
      <Box
        className="container"
        sx={{ flexGrow: 1, justifyContent: "center", alignItems: "center" }}
      >
        <Box sx={{ height: 400, maxWidth: 1200, margin: "0 auto" }}>
          <Box
            sx={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h2>Transcript Actions</h2>
            <div style={{ flexGrow: 1 }}></div>
          </Box>
          <Grid
            container
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              paddingBottom: "20px",
            }}
            spacing={2}
          >
            <Grid item lg={4} md={6} xs={12}>
              <Link
                style={{ textDecoration: "none" }}
                to={`/transcripts/summarize/${transcriptId}`}
              >
                <Paper
                  className="integration-cell"
                  variant="outlined"
                  style={{
                    padding: 24,
                    paddingTop: 32,
                    paddingBottom: 32,
                  }}
                >
                  <Avatar
                    style={{ margin: 0 }}
                    sx={{ width: 56, height: 56, background: "#EEE" }}
                  >
                    <SummarizeOutlined fontSize="medium" color="primary" />
                  </Avatar>

                  <h3>Summarize</h3>
                  <div style={{ color: "#888" }}>
                    Summarize transcripts into an executive summary
                  </div>
                </Paper>
              </Link>
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <Link
                style={{ textDecoration: "none" }}
                to={`/transcripts/query/${transcriptId}`}
              >
                <Paper
                  className="integration-cell"
                  variant="outlined"
                  style={{
                    padding: 24,
                    paddingTop: 32,
                    paddingBottom: 32,
                  }}
                >
                  <Avatar
                    style={{ margin: 0 }}
                    sx={{ width: 56, height: 56, background: "#EEE" }}
                  >
                    <Search fontSize="medium" color="primary" />
                  </Avatar>

                  <h3>Query</h3>
                  <div style={{ color: "#888" }}>
                    Search through your transcript for answers
                  </div>
                </Paper>
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Grid>
  );
}
