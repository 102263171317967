import { ArrowRightAlt as ArrowRightAltIcon } from "@mui/icons-material";
import { Button, Chip, Grid, Paper } from "@mui/material";
import React from "react";

import { PricingBenefit } from "../../";
import styles from "../styles.module.scss";
import { businessModeBenefits } from "./data";

export function PricingBusinessCard() {
  return (
    <Paper
      className={styles.card}
      variant="outlined"
      style={{ borderColor: "#EEE", boxShadow: "0px 1px 5px rgba(0,0,0,0.08)" }}
    >
      <div className={styles.top}>
        <div className={styles.type}>
          <Chip
            className={styles.chip}
            label="Business and Enterprise"
            color="secondary"
          />
        </div>
        <Grid className={styles.billing} container>
          <div className={styles.price}>Contact Sales</div>
        </Grid>
        <div className={styles.info}>
          Deliver at any volume to any size list. Fine tune emails to your
          specifications. Get the onboarding and support you need to scale with
          Modern Sales AI.
        </div>
        <div className={styles.words}>Custom Plan & Price</div>
        <div className={styles.divider} />
        <div className={styles.benefits}>
          {businessModeBenefits.map((benefit, i) => (
            <PricingBenefit key={i} benefit={benefit} />
          ))}
        </div>
      </div>

      <div className={styles.bottom}>
        <Button
          className={styles.submit}
          variant="contained"
          color="secondary"
          size="large"
          fullWidth
          disableElevation={true}
          style={{ height: 50 }}
        >
          <span>Contact Sales</span>
          <ArrowRightAltIcon />
        </Button>
      </div>
    </Paper>
  );
}
