import { Button, Tooltip } from "@mui/material";
import { getBaseUrl } from "jsx/Api";
import { useState } from "react";

export function InviteURLCopyButton({ inviteUrl }) {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  function handleTooltipOpen() {
    setTooltipOpen(true);
    setTimeout(() => {
      setTooltipOpen(false);
    }, 2000);
  }
  return (
    <Tooltip title={tooltipOpen ? "Copied" : "Copy"} placement="top">
      <Button
        aria-label="copy"
        size="small"
        className="small-icon-button"
        placement="top"
        variant="outlined"
        color="gray"
        onClick={() => {
          navigator.clipboard.writeText(`${getBaseUrl()}${inviteUrl}`);
          handleTooltipOpen();
        }}
      >
        Copy Link
      </Button>
    </Tooltip>
  );
}
