import * as React from "react";
import TextField from "@mui/material/TextField";
import { ToastContainer, toast } from "react-toastify";

import Button from "@mui/material/Button";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  getFirestore,
  setDoc,
} from "firebase/firestore";
import { UserContext } from "jsx/context";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { Chip, Grid, IconButton } from "@mui/material";
import { Form, ErrorMessage, Field, Formik } from "formik";
import * as Yup from "yup";
import { Edit, EditOutlined } from "@mui/icons-material";
import { getFirstNameLastName } from "jsx/utils";

export const newContactSchema = Yup.object().shape({
  leadFirstName: Yup.string().required("Please complete this required field."),
});

const filter = createFilterOptions();

const initialValues = {
  firstName: "Jane",
  lastName: "Doe",
  jobTitle: "VP of Marketing",
  goal: "Try to schedule a 30 minute meeting with recipient next Tuesday or Thursday.",
  senderCompanyContext:
    "We've developed a new computer chip that improves compute efficiency by 37%.",
  companyName: "IBM",
  leadFirstName: "John",
  leadJobTitle: "VP of Engineering",
  leadCompanyName: "Microsoft",
  leadBio:
    "This person went to Harvard and played tennis there. They have worked at IBM for a year.",
};

export default function ParticipantsAutocomplete({
  style,
  onSelectedContacts,
  selectedContactId,
  contacts,
  onUpdateContact,
  participants,
}) {
  const { user, workspace } = React.useContext(UserContext);
  const { firstName, lastName } = getFirstNameLastName(user.displayName);

  const userAsParticipant = {
    id: user.uid,
    firstName: user.firstName ?? firstName,
    lastName: user.lastName ?? lastName,
    jobTitle: user.jobTitle ?? "",
    companyName: workspace.companyName ?? workspace.name,
  };

  const contactsWithUser = [userAsParticipant, ...contacts];

  const [value, setValue] = React.useState([userAsParticipant]);
  const [open, setOpen] = React.useState(false);

  const [contact, setContact] = React.useState();
  const [showSnack, setShowSnack] = React.useState(false);

  React.useEffect(() => {
    if (contactsWithUser) {
      // merge currently selected value with new contact data
      let newValues = value;
      newValues = newValues.map((e) => {
        const found = contactsWithUser.find((c) => {
          return e.id === c.id;
        });

        if (found) {
          return found;
        } else {
          return e;
        }
      });

      setValue(newValues);
    }
  }, [contacts]);

  const navigate = useNavigate();

  React.useEffect(() => {
    onSelectedContacts(value);
  }, [value]);

  const action = (
    <React.Fragment>
      <Button
        color="primary"
        variant="contained"
        disableElevation
        size="small"
        onClick={() => {
          navigate(`/contacts/${contact.id}`);
        }}
      >
        View in Contacts
      </Button>
    </React.Fragment>
  );

  let addContactSection;
  const db = getFirestore();

  async function updateContact(values) {
    if (contact) {
      if (contact.id) {
        toast.success(`Updated Contact info for ${values.firstName}`, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        await setDoc(
          doc(
            db,
            [
              "workspaces",
              workspace.id,
              "workspaces_private",
              "data",
              "contacts",
              contact.id,
            ].join("/")
          ),
          {
            ...values,
            updatedAt: new Date(),
          },
          { merge: true }
        );
      }
    }
    onUpdateContact();
  }

  if (contact) {
    addContactSection = (
      <>
        <Formik
          enableReinitialize
          validationSchema={newContactSchema}
          initialValues={{
            firstName: contact?.firstName ?? "",
            lastName: contact?.lastName ?? "",
            jobTitle: contact?.jobTitle ?? "",
            companyName: contact?.companyName ?? "",
            summary: contact?.summary ?? "",
          }}
          onSubmit={() => {
            alert("hi");
          }}
          style={{ height: "100%" }}
        >
          {({ values }) => (
            <Form
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <>
                <Grid container style={{ width: "100%" }}>
                  <Grid item xs={12} md={6}>
                    <div style={{ marginRight: 16 }}>
                      <label htmlFor="firstName" className="form-label">
                        Participants's first name*
                      </label>
                      <Field
                        id="firstName"
                        name="firstName"
                        className="form-input"
                        placeholder={initialValues.firstName}
                      />

                      <ErrorMessage
                        name="firstName"
                        className="form-error"
                        component="div"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div>
                      <label htmlFor="lastName" className="form-label">
                        Participants's last name*
                      </label>
                      <Field
                        id="lastName"
                        name="lastName"
                        className="form-input"
                        placeholder={initialValues.lastName}
                      />

                      <ErrorMessage
                        name="lastName"
                        className="form-error"
                        component="div"
                      />
                    </div>
                  </Grid>
                  <Grid container style={{ width: "100%" }}>
                    <Grid item xs={12} md={6}>
                      <div style={{ marginRight: 16 }}>
                        <label htmlFor="jobTitle" className="form-label">
                          Participants's job title / role
                        </label>
                        <Field
                          id="jobTitle"
                          name="jobTitle"
                          className="form-input"
                          placeholder={initialValues.title}
                        />
                        <ErrorMessage
                          name="jobTitle"
                          className="form-error"
                          component="div"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div>
                        <label htmlFor="companyName" className="form-label">
                          Participants's company
                        </label>
                        <Field
                          id="companyName"
                          name="companyName"
                          placeholder={initialValues.companyName}
                          className="form-input"
                        />
                        <ErrorMessage
                          name="companyName"
                          className="form-error"
                          component="div"
                        />
                      </div>
                    </Grid>
                  </Grid>
                  {/* <Grid item xs={12} md={12}>
                <div>
                  <label htmlFor="leadBio" className="form-label">
                    Participants's bio
                  </label>
                  <Field
                    id="leadBio"
                    name="leadBio"
                    placeholder={initialValues.leadBio}
                    component="textarea"
                    rows="4"
                    className="form-textarea"
                  />
                </div>
              </Grid> */}
                </Grid>

                <div style={{ display: "flex", marginTop: 8 }}>
                  <div style={{ flexGrow: 1 }} />
                  <Button
                    color="gray"
                    style={{ marginRight: 8 }}
                    onClick={() => {
                      setContact(null);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      updateContact(values);
                      setContact(null);
                    }}
                  >
                    Update contact
                  </Button>
                </div>
              </>
            </Form>
          )}
        </Formik>
      </>
    );
  }

  const handleClose = () => {
    setOpen(false);
  };

  const [dialogValue, setDialogValue] = React.useState({
    jobTitle: "",
    year: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    setValue({
      jobTitle: dialogValue.jobTitle,
      year: parseInt(dialogValue.year, 10),
    });
    handleClose();
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      setTimeout(() => {}, 10);
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <Autocomplete
        style={{ background: "white" }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        multiple
        freeSolo
        value={value}
        renderTags={(tagValue, getTagProps) => {
          return tagValue.map((option, index) => {
            return (
              <Chip
                icon={
                  <IconButton
                    size="small"
                    onClick={() => {
                      setOpen(false);
                      setContact(option, user);
                    }}
                  >
                    <EditOutlined
                      size="small"
                      style={{ width: 16, height: 16 }}
                    />
                  </IconButton>
                }
                {...getTagProps({ index })}
                label={formatOption(option, user)}
              />
            );
          });
        }}
        onKeyDown={handleKeyDown}
        onChange={async (event, newValue, reason) => {
          let lastValue = newValue[newValue.length - 1];

          if (
            lastValue &&
            lastValue.firstName &&
            lastValue.firstName.includes(`Add new contact "`)
          ) {
            lastValue = lastValue.inputValue;
          }

          if (typeof lastValue === "string") {
            // // timeout to avoid instant validation of the dialog's form.
            // setTimeout(() => {
            //   navigate(`/contacts/new?fullName=${newValue}`);
            // });

            let details = getFirstNameLastName(lastValue);
            const filtered = _(details)
              .omitBy(_.isEmpty)
              .omitBy(_.isUndefined)
              .omitBy(_.isNull)
              .value();

            const cc = await addDoc(
              collection(
                db,
                [
                  "workspaces",
                  workspace.id,
                  "workspaces_private",
                  "data",
                  "contacts",
                ].join("/")
              ),
              {
                ...filtered,
                createdAt: new Date(),
                source: "transcripts",
              },
              { merge: true }
            );
            const newContact = { id: cc.id, ...filtered };
            setContact(newContact);

            console.log("test", newContact);

            toast.success(`New Contact created for ${newContact?.firstName}`, {
              position: toast.POSITION.BOTTOM_LEFT,
            });

            const newNewValue = newValue;
            newNewValue.pop();

            newNewValue.push(newContact);
            setValue(newNewValue);
          } else {
            setValue(newValue);
            setContact(null);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== "") {
            filtered.push({
              inputValue: params.inputValue,
              firstName: `Add new contact "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        id="free-solo-dialog-demo"
        options={contactsWithUser}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => {
          if (!option) {
            return;
          }
          // e.g value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return formatOption(option, user);
        }}
        // selectOnFocus
        blurOnSelect
        handleHomeEndKeys
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id ?? "add-cell"}>
              {formatOption(option, user)}
            </li>
          );
        }}
        sx={{ ...style }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Jane"
            InputLabelProps={{ shrink: false }}
            InputProps={{
              ...params.InputProps,
              classes: {
                notchedOutline: "no-border-notched",
              },
            }}
            size="small"
            style={{
              border: "1px solid #DDD",
              borderRadius: 4,
              boxShadow: "2px 2px 3px rgba(0, 0, 0, 0.03)",
            }}
          />
        )}
      />
      {addContactSection}
    </React.Fragment>
  );
}

function formatOption(option, user = null) {
  if (option.id === user.uid) {
    return "Yourself";
  }
  const valueFormat = (
    (option.firstName ?? "") +
    " " +
    (option.lastName ?? "")
  ).trim();

  // if (option.companyName) {
  //   valueFormat += ` – ${option.companyName}`;
  // }

  // if (option.title) {
  //   valueFormat += ` – ${option.title}`;
  // }

  return valueFormat.trim();
}
