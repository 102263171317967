import { LoadingButton } from "@mui/lab";
import { Grid, Paper } from "@mui/material";
import React from "react";

export function BillingSubscription({
  subscription,
  subscriptionCredits,
  subscriptionText,
  editPlanLoading,
  editPaymentDetailsLoading,
  getPortalLink,
  setEditPlanLoading,
  setEditPaymentDetailsLoading,
}) {
  return (
    <Grid container style={{ width: "100%", marginBottom: 16 }} spacing={2}>
      <Grid item xs={12}>
        <Paper
          variant="outlined"
          style={{ padding: 24, paddingTop: 24, paddingBottom: 24 }}
        >
          <div style={{ display: "flex" }}>
            <h4 style={{ margin: 0, fontSize: 18, marginBottom: 4 }}>
              Subscription
            </h4>
            <div style={{ marginLeft: 8, marginTop: -3 }}>
              <div
                style={{
                  padding: "5px",
                  background: "rgb(240 253 244)",
                  borderRadius: 4,
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  border: "1px solid rgb(34 197 94)",
                  color: "rgb(34 197 94)",
                  fontWeight: 500,
                  fontSize: 12,
                  textTransform: "uppercase",
                }}
              >
                {subscription.status}
              </div>
            </div>
          </div>

          <div style={{ color: "#666", marginTop: 8, fontWeight: 500 }}>
            {subscriptionCredits.toLocaleString()} word credits
          </div>
          <div style={{ color: "#666", marginTop: 8, fontSize: 14 }}>
            {subscriptionText}
          </div>

          <div style={{ display: "flex", marginTop: 32 }}>
            <div style={{ flexGrow: 1 }}></div>
            <LoadingButton
              variant="contained"
              size="small"
              color="secondary"
              disableElevation
              style={{ marginRight: 16 }}
              loading={editPaymentDetailsLoading}
              onClick={() => {
                setEditPaymentDetailsLoading(true);
                getPortalLink();
              }}
            >
              Edit payment details
            </LoadingButton>

            <LoadingButton
              variant="outlined"
              size="small"
              color="gray"
              onClick={() => {
                setEditPlanLoading(true);
                getPortalLink();
              }}
              loading={editPlanLoading}
            >
              Edit plan
            </LoadingButton>
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
}
