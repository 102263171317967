import {
  CheckCircle as CheckCircleIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
} from "@mui/icons-material";
import { Button } from "@mui/material";
import React, { useState } from "react";

import { EditConversationForm } from "./EditConversationForm/EditConversationForm";

export function ConversationSection({
  conversation,
  showAdvanced,
  showCampaignSection,
  isSiteAdminUNSAFE,
  getConversation,
  saveConversation,
  setShowAdvanced,
  setShowCampaignSection,
}) {
  const [showConversationSection, setShowConversationSection] = useState();

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      {conversation.goal && (
        <CheckCircleIcon style={{ color: "#6366f1", marginRight: 8 }} />
      )}

      {!conversation.goal && (
        <CheckCircleOutlineIcon style={{ color: "#999", marginRight: 8 }} />
      )}

      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <div style={{ display: "flex" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p
              style={{
                color: "#333",
                marginTop: 2,
                marginBottom: 8,
                fontSize: 18,
              }}
            >
              Goal of the campaign
            </p>
            <div style={{ color: "#999" }}>
              {!conversation.goal && !showConversationSection && (
                <>Requires a goal.</>
              )}
              {conversation.goal && !showConversationSection && (
                <>{conversation.goal}</>
              )}
            </div>
          </div>
          <div style={{ flexGrow: 1 }} />
          {!showCampaignSection && (
            <div>
              <Button
                variant="contained"
                disableElevation
                onClick={() => {
                  setShowConversationSection(!showConversationSection);
                }}
                color="secondary"
                style={{ marginTop: -8 }}
              >
                Edit goals
              </Button>
            </div>
          )}
        </div>

        {showConversationSection && (
          <EditConversationForm
            conversation={conversation}
            showAdvanced={showAdvanced}
            isSiteAdminUNSAFE={isSiteAdminUNSAFE}
            getConversation={getConversation}
            saveConversation={saveConversation}
            setShowAdvanced={setShowAdvanced}
            setShowCampaignSection={setShowCampaignSection}
            setShowConversationSection={setShowConversationSection}
          />
        )}
      </div>
    </div>
  );
}
