import { DeleteOutline } from "@mui/icons-material";
import { Button, Grid, IconButton, Skeleton, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import dayjs from "dayjs";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
} from "firebase/firestore";
import { SideMenu } from "jsx/components";
import { CustomNoRowsOverlay } from "jsx/components/EmptyState";
import { UserContext } from "jsx/context";
import { getFullNameFromContact } from "jsx/utils";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

const columns = [
  // { field: "id", headerName: "ID", width: 90 },
  {
    field: "transcriptName",
    headerName: "Name",
    width: 260,
    editable: false,
  },
  {
    field: "participants",
    headerName: "Participants",
    width: 370,
    editable: false,
    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }

      return params.value.map((e) => getFullNameFromContact(e)).join(", ");
    },
  },
  {
    field: "createdAt",
    headerName: "Created at",
    width: 200,
    editable: false,
    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }

      return dayjs(new Date(params.value.seconds * 1000)).fromNow();
    },
  },
];

export function Transcripts() {
  const [transcripts, setTranscripts] = useState();
  const navigate = useNavigate();
  const [selectionModel, setSelectionModel] = useState([]);
  const { user, workspace } = useContext(UserContext);

  const uid = useParams()["userId"] ?? user.uid;
  const db = getFirestore();

  async function getTranscripts() {
    try {
      const q = await getDocs(
        collection(
          db,
          "workspaces",
          workspace.id,
          "workspaces_private",
          "data",
          "transcripts"
        )
      );

      let map = q.docs.map((e) => {
        return {
          id: e.id,
          ...e.data(),
        };
      });

      map = _.orderBy(map, ["createdAt"], ["desc"]);
      setTranscripts(map);
    } catch (e) {
      console.log("error fetching transcript", e);
    }
  }

  useEffect(() => {
    getTranscripts();
  }, [workspace, uid]);

  async function deleteSelected() {
    for (const e of selectionModel) {
      await deleteDoc(
        doc(
          db,
          "workspaces",
          workspace.id,
          "workspaces_private",
          "data",
          "transcripts",
          e
        )
      );
    }

    await getTranscripts();
  }

  let content;

  if (transcripts) {
    content = (
      <>
        <DataGrid
          autoHeight
          pageSize={20}
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          rows={transcripts}
          columns={columns}
          checkboxSelection
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          onRowClick={(e) => {
            navigate(`/transcripts/templates/${e.id}`);
          }}
          components={{
            NoRowsOverlay: CustomNoRowsOverlay,
          }}
          style={{ minHeight: 400 }}
        />
      </>
    );
  } else {
    content = (
      <Skeleton variant="rectangular" height={400} style={{ marginTop: 32 }} />
    );
  }

  let selectedSection;

  if (selectionModel.length) {
    selectedSection = (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            justifyItems: "start",
            alignItems: "start",
          }}
        >
          <div style={{ fontWeight: 500, marginTop: 16, color: "#333" }}>
            Selected {selectionModel.length}
          </div>
          <Tooltip title="Delete" placement="top">
            <IconButton
              style={{ marginTop: 10, marginBottom: -2, marginLeft: 16 }}
              aria-label="copy"
              size="small"
              className="small-icon-button"
              placement="top"
              onClick={() => {
                deleteSelected();
              }}
            >
              <DeleteOutline fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </div>
      </>
    );
  } else {
    selectedSection = <div style={{ height: 36 }}></div>;
  }

  return (
    <>
      <Grid container style={{ minHeight: "100vh" }}>
        <Box
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "start",
            alignItems: "start",
            minHeight: "100%",
          }}
        >
          <SideMenu />
          <Box
            className="container"
            sx={{ flexGrow: 1, justifyContent: "center", alignItems: "center" }}
          >
            <Box sx={{ height: 400, maxWidth: 900, margin: "0 auto" }}>
              <Box
                sx={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: 2,
                }}
              >
                <div>
                  <h2 style={{ marginBottom: 0 }}>Transcripts</h2>
                  {selectedSection}
                </div>

                <div style={{ flexGrow: 1 }}></div>
                <Link
                  to={"/transcripts/new"}
                  style={{ textDecoration: "none" }}
                >
                  <Button variant="contained" disableElevation>
                    New Transcript
                  </Button>
                </Link>
              </Box>
              {content}
            </Box>
          </Box>
        </Box>
      </Grid>
    </>
  );
}
