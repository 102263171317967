import * as Yup from "yup";

export const enterpriseFormSchema = Yup.object().shape({
  businessEmail: Yup.string()
    .email("Email must be formatted correctly.")
    .required("Please complete this required field."),
  firstName: Yup.string().required("Please complete this required field."),
  lastName: Yup.string().required("Please complete this required field."),
  companyName: Yup.string().required("Please complete this required field."),
  companySize: Yup.string().required("Please complete this required field."),
});
