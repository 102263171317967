import { DeleteOutline } from "@mui/icons-material";
import { IconButton, Skeleton, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { deleteDoc, doc, getFirestore } from "firebase/firestore";
import { UserContext } from "jsx/context";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

const columns = [
  // { field: "id", headerName: "ID", width: 90 },
  {
    field: "name",
    headerName: "Campaign name",
    width: 400,
    editable: false,
  },
  {
    field: "createdAt",
    headerName: "Created at",
    width: 400,
    editable: false,
    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }

      return dayjs(new Date(params.value.seconds * 1000)).fromNow();
    },
  },
];

export function CampaignsList({ campaigns, getCampaigns }) {
  const [selectionModel, setSelectionModel] = useState([]);
  const navigate = useNavigate();
  const { user, workspace } = useContext(UserContext);

  const db = getFirestore();

  async function deleteSelected() {
    for (const e of selectionModel) {
      await deleteDoc(
        doc(
          db,
          "workspaces",
          workspace.id,
          "workspaces_private",
          "data",
          "campaigns",
          e
        )
      );
    }

    getCampaigns();
  }

  if (!campaigns) {
    return (
      <Skeleton variant="rectangular" height={400} style={{ marginTop: 32 }} />
    );
  }

  let selectedSection;

  if (selectionModel.length) {
    selectedSection = (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            justifyItems: "start",
            alignItems: "start",
            marginBottom: 10,
            marginTop: -10,
          }}
        >
          <div style={{ fontWeight: 500, marginTop: 4, color: "#333" }}>
            Selected {selectionModel.length}
          </div>
          <Tooltip title="Delete" placement="top">
            <IconButton
              style={{ marginTop: 0, marginBottom: -2, marginLeft: 16 }}
              aria-label="copy"
              size="small"
              className="small-icon-button"
              placement="top"
              onClick={() => {
                deleteSelected();
              }}
            >
              <DeleteOutline fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </div>
      </>
    );
  } else {
    selectedSection = <div style={{ height: 26 }}></div>;
  }

  return (
    <>
      <div>{selectedSection}</div>
      <DataGrid
        onSelectionModelChange={(newSelectionModel) => {
          setSelectionModel(newSelectionModel);
        }}
        autoHeight
        pageSize={20}
        rows={campaigns}
        columns={columns}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
        onRowClick={(e) => {
          navigate(`/autopilot/${e.id}`);
        }}
      />
    </>
  );
}
