import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { collection, getDocs, getFirestore } from "firebase/firestore";
import { UserContext } from "jsx/context";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { AdminWorkspaces } from "./components";

export function Admin() {
  const { user, updateWorkspace } = useContext(UserContext);
  const db = getFirestore();
  const uid = useParams()["userId"] ?? user.uid;
  const [workspaces, setWorkspaces] = useState();

  useEffect(() => {
    if (uid) {
      getWorkspaces();
    }
  }, [uid]);

  async function getWorkspaces() {
    console.log("getWorkspaces");
    const q = await getDocs(collection(db, "workspaces"));

    const map = q.docs.map((e) => {
      return {
        id: e.id,
        ...e.data(),
      };
    });

    console.log("map", map);

    setWorkspaces(map);
  }

  return (
    <>
      <Grid container>
        {/* <SideMenu /> */}
        <Box
          className="container"
          sx={{ flexGrow: 1, justifyContent: "center", alignItems: "center" }}
        >
          <Box sx={{ height: 400, maxWidth: 1200, margin: "0 auto" }}>
            <Box
              sx={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h1>Workspaces</h1>
              <div style={{ flexGrow: 1 }}></div>
            </Box>
            <AdminWorkspaces
              workspaces={workspaces}
              updateWorkspace={updateWorkspace}
            />
          </Box>
        </Box>
      </Grid>
    </>
  );
}
