import dayjs from "dayjs";
import { InviteURLCopyButton } from "jsx/components";
import React from "react";

import { TeamSettingsActionButton } from "../../TeamSettingsActionButton/TeamSettingsActionButton";
import { StyledTableCell, StyledTableRow } from "../styles";

export function TeamMembersTableRow({ teamMember, onUserDelete }) {
  const { id, role, status, joinedAt, inviteLink } = teamMember;

  function handleUserDelete() {
    onUserDelete(teamMember);
  }

  return (
    <StyledTableRow>
      <StyledTableCell component="th" scope="row">
        {id}
      </StyledTableCell>
      <StyledTableCell align="right">{role}</StyledTableCell>
      <StyledTableCell align="right">{status}</StyledTableCell>
      <StyledTableCell align="right">
        {joinedAt
          ? dayjs(parseInt(joinedAt?.seconds * 1000)).format(
              "MMM D, YYYY h:mm A	"
            )
          : ""}
      </StyledTableCell>
      <StyledTableCell align="right">
        {inviteLink ? <InviteURLCopyButton inviteUrl={inviteLink} /> : null}
      </StyledTableCell>
      <StyledTableCell align="right">
        <TeamSettingsActionButton deleteUser={handleUserDelete} />
      </StyledTableCell>
    </StyledTableRow>
  );
}
