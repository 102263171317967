import {
  Check,
  InboxOutlined,
  MoveToInboxOutlined,
  RecordVoiceOverOutlined,
} from "@mui/icons-material";
import { Grid, LinearProgress, Paper } from "@mui/material";
import React from "react";

import { OnboardRow } from "..";

export function OnboardTranscriptsTable({ onboardingTasks }) {
  return (
    <Paper variant="outlined" style={{ marginTop: 24, paddingBottom: 0 }}>
      <Grid container style={{ flexWrap: "nowrap", padding: "16px 0" }}>
        <Grid item style={{ display: "flex" }}>
          <RecordVoiceOverOutlined
            style={{ height: 28, width: 28, color: "#999", marginLeft: 16 }}
          />
        </Grid>
        <Grid container style={{ alignItems: "center", padding: "0 16px " }}>
          <Grid
            item
            style={{ fontSize: 20, fontWeight: 600, marginBottom: "4px" }}
          >
            Track and analyze call with Talking Points
          </Grid>
          <div style={{ flexGrow: 1 }}></div>
          <div style={{ fontSize: 16 }}>1,000 credits available</div>
        </Grid>
      </Grid>

      <LinearProgress variant="determinate" value={0.5} color="green" />

      <OnboardRow title="Create an account" credits="-" complete={true} />

      <OnboardRow
        path="/transcripts/new"
        title="Upload a call or transcript to summarize"
        credits="1,000"
        complete={onboardingTasks?.uploadTranscript}
      />

      <OnboardRow
        path="/settings/team"
        title="Invite teammates"
        credits="1,000"
        complete={onboardingTasks?.uploadTranscript}
      />
    </Paper>
  );
}
