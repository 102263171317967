import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
} from "firebase/firestore";
import { UserContext } from "jsx/context";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";

export function useContacts() {
  const db = getFirestore();
  const { workspace } = useContext(UserContext);
  const [contacts, setContacts] = useState();

  useEffect(() => {
    if (workspace) {
      getContacts();
    }
  }, [workspace]);

  async function getContacts() {
    const q = await getDocs(
      collection(
        db,
        "workspaces",
        workspace.id,
        "workspaces_private",
        "data",
        "contacts"
      )
    );

    let map = q.docs.map((e) => {
      return {
        id: e.id,
        ...e.data(),
      };
    });

    map = _.orderBy(map, ["createdAt"], ["desc"]);
    console.log("map", map);
    setContacts(map);
  }

  return { contacts, getContacts };
}
