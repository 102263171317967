import * as React from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { collection, getDocs, getFirestore } from "firebase/firestore";
import { UserContext } from "jsx/context";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { getFirstNameLastName } from "jsx/utils";

const filter = createFilterOptions();

export default function TranscriptsAutocomplete({
  style,
  onSelectedTranscript,
  selectedTranscriptId,
  transcripts,
  generate,
}) {
  const [value, setValue] = React.useState(null);
  const [open, toggleOpen] = React.useState(false);
  const { workspace } = React.useContext(UserContext);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (transcripts) {
      const selected = transcripts.find((e) => e.id === selectedTranscriptId);
      setValue(selected);
    }
  }, [transcripts]);

  const handleClose = () => {
    setDialogValue({
      jobTitle: "",
      year: "",
    });
    toggleOpen(false);
  };

  const [dialogValue, setDialogValue] = React.useState({
    jobTitle: "",
    year: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    setValue({
      jobTitle: dialogValue.jobTitle,
      year: parseInt(dialogValue.year, 10),
    });
    handleClose();
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      setTimeout(() => {
        generate();
      }, 10);
    }
  };

  return (
    <React.Fragment>
      <Autocomplete
        value={value}
        onKeyDown={handleKeyDown}
        onChange={(event, newValue) => {
          onSelectedTranscript(newValue);
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          // if (params.inputValue !== "") {
          //   filtered.push({
          //     inputValue: params.inputValue,
          //     firstName: `Add "${params.inputValue}"`,
          //   });
          // }

          return filtered;
        }}
        id="free-solo-dialog-demo"
        options={transcripts}
        getOptionLabel={(option) => {
          console.log("option", option);
          // e.g value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.name;
        }}
        // selectOnFocus
        autoSelect
        blurOnSelect
        handleHomeEndKeys
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id ?? "add-cell"}>
              {option.name}
            </li>
          );
        }}
        sx={{ ...style }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={"Transcript name"}
            InputLabelProps={{ shrink: false }}
            InputProps={{
              ...params.InputProps,
              classes: {
                notchedOutline: "no-border-notched",
              },
            }}
            size="small"
            style={{
              height: 39,
              border: "1px solid #DDD",
              borderRadius: 4,
              boxShadow: "2px 2px 3px rgba(0, 0, 0, 0.03)",
            }}
          />
        )}
      />
    </React.Fragment>
  );
}
