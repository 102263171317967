import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  ArticleOutlined,
  CopyAllOutlined,
  EditOutlined,
  SaveOutlined,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  Tooltip,
} from "@mui/material";
import dayjs from "dayjs";
import { doc, getDoc, getFirestore, setDoc } from "firebase/firestore";
import DraftSendEmailButton from "jsx/components/buttons/DraftSplitButton/DraftSendEmailButton";
import { UserContext } from "jsx/context";
import React, { useContext, useState } from "react";

function toHTML(text) {
  return text.replaceAll("\n", "<br />");
}

export function DraftCell({ style, defaultDraft }) {
  const { user, workspace, isSiteAdminUNSAFE } = useContext(UserContext);
  const [viewPrompt, setViewPrompt] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [draft, setDraft] = useState(defaultDraft);
  const [editedHtml, setEditedHtml] = useState(defaultDraft?.html);
  const [editedSubject, setEditedSubject] = useState(defaultDraft?.subject);
  const [loading, setLoading] = useState(false);

  const db = getFirestore();

  let bodySection;
  let buttons;
  let promptSection;

  if (isEditing && draft?.body) {
    bodySection = (
      <CKEditor
        editor={ClassicEditor}
        config={{
          toolbar: [
            "heading",
            "|",
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList",
            "blockQuote",
            "|",
            "undo",
            "redo",
          ],
        }}
        data={toHTML(draft.body)}
        onReady={(editor) => {}}
        onChange={(event, editor) => {
          const data = editor.getData();
          setEditedHtml(data);
        }}
      />
    );
  } else if (draft?.body) {
    bodySection = (
      <div
        dangerouslySetInnerHTML={{
          __html: draft.body.replaceAll("\n", "<br />"),
        }}
      />
    );
  }

  if (viewPrompt) {
    promptSection = (
      <>
        <Divider width={"100%"} style={{ marginTop: 32, marginBottom: 32 }} />
        <div
          dangerouslySetInnerHTML={{
            __html: draft.prompt?.replaceAll("\n", "<br />"),
          }}
        />
      </>
    );
  }

  function handleTooltipOpen() {
    setTooltipOpen(true);
    setTimeout(() => {
      setTooltipOpen(false);
    }, 2000);
  }

  async function reloadDraft() {
    const _draft_ = await getDoc(
      doc(
        db,
        "workspaces",
        workspace.id,
        "workspaces_private",
        "data",
        "drafts",
        draft.id
      )
    );

    setDraft({ id: _draft_.id, ..._draft_.data() });
  }

  buttons = (
    <div style={{ marginTop: -8, marginBottom: 8 }}>
      <Tooltip title="Edit draft" placement="top">
        <IconButton
          aria-label="edit"
          size="small"
          className="small-icon-button"
          placement="top"
          onClick={() => {
            setEditing(true);
          }}
        >
          <EditOutlined fontSize="inherit" />
        </IconButton>
      </Tooltip>

      <Tooltip title={tooltipOpen ? "Copied" : "Copy"} placement="top">
        <IconButton
          aria-label="copy"
          size="small"
          className="small-icon-button"
          placement="top"
          onClick={() => {
            navigator.clipboard.writeText(draft.body);
            handleTooltipOpen();
          }}
        >
          <CopyAllOutlined fontSize="inherit" />
        </IconButton>
      </Tooltip>
      {isSiteAdminUNSAFE && (
        <>
          <Tooltip title="Show prompt" placement="top">
            <IconButton
              aria-label="show prompt"
              size="small"
              className="small-icon-button"
              placement="top"
              onClick={() => {
                setViewPrompt(!viewPrompt);
              }}
            >
              <ArticleOutlined fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </>
      )}
    </div>
  );

  async function saveDraft() {
    const newDraft = draft;
    newDraft.html = editedHtml;
    newDraft.subject = editedSubject;
    await setDoc(
      doc(
        db,
        "workspaces",
        workspace.id,
        "workspaces_private",
        "data",
        "drafts",
        draft.id
      ),
      { body: editedHtml },
      { merge: true }
    );

    setLoading(true);
    await reloadDraft();
    setEditing(false);
    setLoading(false);
  }

  let content;

  if (isEditing) {
    content = (
      <>
        <div style={{ display: "flex" }}>
          <div style={{ flexGrow: 1 }} />
          <Button
            variant="outlined"
            color="gray"
            size="small"
            style={{ marginRight: 8 }}
            onClick={() => {
              setEditing(false);
            }}
          >
            Cancel
          </Button>

          <LoadingButton
            variant="outlined"
            color="primary"
            size="small"
            onClick={saveDraft}
            loading={loading}
          >
            Save
          </LoadingButton>
        </div>

        <div>
          {draft.createdAt && (
            <>
              <div
                style={{
                  color: "#666",
                  fontSize: 14,
                }}
              >
                {dayjs(parseInt(draft.createdAt.seconds * 1000)).format(
                  "ddd, MMM D, YYYY h:mm A	"
                )}
                ({dayjs(parseInt(draft.createdAt.seconds * 1000)).fromNow()})
              </div>
            </>
          )}
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
            }}
          >
            <span style={{ minHeight: "21px" }}>{draft.subject}</span>
            <h4 style={{ lineHeight: 0 }}>{draft.to}</h4>
            <div
              style={{
                color: "#666",
                marginBottom: 16,
                fontSize: 14,
              }}
            >
              from {draft.from}
            </div>
          </div>
        </div>
        <Grid container>
          {bodySection}
          {promptSection}
        </Grid>
      </>
    );
  } else if (draft) {
    content = (
      <>
        <div style={{ display: "flex", zIndex: 0 }}>
          {buttons}
          <div style={{ flexGrow: 1 }} />
          <DraftSendEmailButton
            draft={draft}
            style={{ marginTop: -8, marginRight: -8 }}
          />
        </div>

        <div>
          {draft.createdAt && (
            <>
              <div
                style={{
                  color: "#666",
                  fontSize: 14,
                }}
              >
                {dayjs(parseInt(draft.createdAt.seconds * 1000)).format(
                  "ddd, MMM D, YYYY h:mm A	"
                )}
                ({dayjs(parseInt(draft.createdAt.seconds * 1000)).fromNow()})
              </div>
            </>
          )}
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
            }}
          >
            <span style={{ minHeight: "21px" }}>{draft.subject}</span>
            <h3 style={{ lineHeight: 0 }}>{draft.to}</h3>
            <div
              style={{
                color: "#666",
                marginBottom: 16,
                fontSize: 14,
              }}
            >
              from {draft.from}
            </div>
          </div>
        </div>
        <Grid container>
          {bodySection}
          {promptSection}
        </Grid>
      </>
    );
  }

  return (
    <div style={{ ...style }}>
      <div style={{ display: "flex", marginBottom: 8 }}>
        <div style={{ flexGrow: 1 }} />
      </div>
      <Paper variant="outlined" style={{ padding: 24 }}>
        {content}
      </Paper>
    </div>
  );
}
