import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { SettingsMenu } from "jsx/components";

import { TeamMembersTable } from "./TeamMembersTable/TeamMembersTable";

const style = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 560,
  bgcolor: "background.paper",
  border: "2px solid #333",
  boxShadow: 24,
  p: 4,
};

export function SettingsTeam() {
  return (
    <Grid sx={{ display: "flex" }}>
      <SettingsMenu />
      <Box
        className="container"
        sx={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          padding: { lg: "32px", xs: "20px" },
        }}
      >
        <Box
          sx={{
            height: 400,
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: "100%",
              flexDirection: "row",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <h2>Team</h2>
          </Box>
          <TeamMembersTable />
        </Box>
      </Box>
    </Grid>
  );
}
