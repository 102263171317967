import { Box, Button, Grid, Paper, Skeleton } from "@mui/material";
import dayjs from "dayjs";
import { CustomCircularProgress } from "jsx/components";
import React from "react";

export function TranscriptsList({
  goal,
  transcripts,
  transcriptsCardDetails,
  retryGenerateTranscriptAnswers,
}) {
  if (!transcripts) {
    return (
      <Skeleton
        variant="rectangular"
        height={400}
        style={{ marginBottom: 12, marginTop: 48 }}
      />
    );
  } else if (transcripts && !transcripts.length) {
    return <EmptyTranscripts />;
  }

  const getAnswerColor = (answer) => {
    if (answer === undefined) return "white";
    const greenInHex = "#008000";
    return answer ? greenInHex : "red";
  };

  const getLastAnswerByQuery = (transcriptId) => {
    return (
      transcriptsCardDetails[transcriptId] &&
      transcriptsCardDetails[transcriptId].lastAnswer
    );
  };

  const getTranscriptStatus = (transcriptId) => {
    return (
      transcriptsCardDetails[transcriptId] &&
      transcriptsCardDetails[transcriptId].status
    );
  };

  const getTranscriptErrorMessage = (transcriptId) => {
    return (
      transcriptsCardDetails[transcriptId] &&
      transcriptsCardDetails[transcriptId].errorMessage
    );
  };

  return (
    <div style={{ paddingBottom: 48 }}>
      {transcripts.map((transcript, i) => {
        return (
          <div style={{ marginBottom: 16 }} key={transcript.id}>
            <div style={{ display: "flex", marginBottom: 8 }}>
              <div style={{ flexGrow: 1 }} />
            </div>
            <Paper variant="outlined" style={{ padding: 24 }}>
              <Grid container>
                {transcript.createdAt && (
                  <Grid container>
                    <div
                      style={{
                        color: "#666",
                        fontSize: 14,
                      }}
                    >
                      {dayjs(
                        parseInt(transcript.createdAt.seconds * 1000)
                      ).format("ddd, MMM D, YYYY h:mm A	")}
                      (
                      {dayjs(
                        parseInt(transcript.createdAt.seconds * 1000)
                      ).fromNow()}
                      )
                    </div>
                  </Grid>
                )}
                <Grid container display={"flex"}>
                  Transcript name: {transcript.transcriptName}
                </Grid>
                <Grid container display={"flex"}>
                  Details: {transcript.transcriptDetails}
                </Grid>
                {getTranscriptStatus(transcript.id) !== "loading" ? (
                  <Grid container display={"flex"} style={{ marginTop: 16 }}>
                    Question: {goal.question} <br />
                    Goal: {goal.text}
                    <Grid
                      container
                      display={"flex"}
                      alignItems="center"
                      gap={1}
                    >
                      Answer:{" "}
                      <Box
                        sx={{
                          backgroundColor: getAnswerColor(
                            getLastAnswerByQuery(transcript.id)
                          ),
                          borderRadius: 50,
                          width: 20,
                          height: 20,
                        }}
                      />
                    </Grid>
                    {/* <Grid
                      container
                      display={"flex"}
                      alignItems="center"
                      gap={2}
                      marginLeft={2}
                    >
                      Query 2:{" "}
                      {
                        <Box
                          sx={{
                            borderRadius: 50,
                            width: 20,
                            height: 20,
                            backgroundColor: getAnswerColor(
                              getLastAnswerByQuery(transcript.id, "query2")
                            ),
                          }}
                        />
                      }
                    </Grid>
                    <Grid
                      container
                      display={"flex"}
                      alignItems="center"
                      gap={2}
                      marginLeft={2}
                    >
                      Query 3:{" "}
                      {
                        <Box
                          sx={{
                            borderRadius: 50,
                            width: 20,
                            height: 20,
                            backgroundColor: getAnswerColor(
                              getLastAnswerByQuery(transcript.id, "query3")
                            ),
                          }}
                        />
                      }
                    </Grid> */}
                  </Grid>
                ) : (
                  <Box sx={{ mt: 2 }}>
                    <CustomCircularProgress />
                  </Box>
                )}
                {getTranscriptStatus(transcript.id) === "error" && (
                  <>
                    <div className="form-error">
                      Error in generating response:{" "}
                      {getTranscriptErrorMessage(transcript.id)}
                    </div>
                    <Grid container>
                      <Button
                        style={{
                          marginTop: 8,
                        }}
                        variant="outlined"
                        size="small"
                        disableElevation
                        onClick={() => {
                          retryGenerateTranscriptAnswers(transcript.id);
                        }}
                      >
                        Retry
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            </Paper>
          </div>
        );
      })}
    </div>
  );
}

export function EmptyTranscripts() {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ marginTop: "40vh" }}
    >
      <div
        style={{
          display: "flex",
          marginTop: 0,
          flexDirection: "column",
          border: "1px solid #DDD",
          padding: 16,
          borderRadius: 8,
        }}
      >
        <h4
          style={{
            color: "#333",
            marginBottom: 8,
            fontSize: 16,
            marginTop: 0,
          }}
        >
          Select transcripts for the goal to get started
        </h4>
        <p
          style={{
            fontWeight: 400,
            fontSize: 14,
            color: "#999",
            marginTop: 0,
            marginBottom: 0,
          }}
        >
          Answer the prompts and click the "Generate" button.
        </p>
        <div style={{ flexGrow: 1 }} />
      </div>
    </Grid>
  );
}
