import {
  CheckCircle as CheckCircleIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
} from "@mui/icons-material";
import { Button } from "@mui/material";
import React from "react";

import { EditCampaignForm } from "./EditCampaignForm/EditCampaignForm";

export function CampaignSection({
  campaign,
  showCampaignSection,
  showAdvanced,
  isSiteAdminUNSAFE,
  getCampaign,
  saveCampaign,
  setShowAdvanced,
  setShowCampaignSection,
}) {
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      {campaign.goal && (
        <CheckCircleIcon style={{ color: "#6366f1", marginRight: 8 }} />
      )}

      {!campaign.goal && (
        <CheckCircleOutlineIcon style={{ color: "#999", marginRight: 8 }} />
      )}

      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <div style={{ display: "flex" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p
              style={{
                color: "#333",
                marginTop: 2,
                marginBottom: 8,
                fontSize: 18,
              }}
            >
              Goal of the campaign and company info
            </p>
            <div style={{ color: "#999" }}>
              {!campaign.goal && !showCampaignSection && (
                <>The objective of the outreach campaign.</>
              )}
              {campaign.goal && !showCampaignSection && <>{campaign.goal}</>}
            </div>
          </div>
          <div style={{ flexGrow: 1 }} />
          {!showCampaignSection && (
            <div>
              <Button
                variant="contained"
                disableElevation
                onClick={() => {
                  setShowCampaignSection(!showCampaignSection);
                }}
                color="secondary"
                style={{ marginTop: -8, width: "100px", marginLeft: 16 }}
              >
                Edit goals
              </Button>
            </div>
          )}
        </div>
        {showCampaignSection && (
          <EditCampaignForm
            campaign={campaign}
            showAdvanced={showAdvanced}
            isSiteAdminUNSAFE={isSiteAdminUNSAFE}
            getCampaign={getCampaign}
            saveCampaign={saveCampaign}
            setShowAdvanced={setShowAdvanced}
            setShowCampaignSection={setShowCampaignSection}
          />
        )}
      </div>
    </div>
  );
}
