import { Grid } from "@mui/material";
import { CreateAccountForm } from "jsx/components";

export function CreateAccount() {
  return (
    <Grid
      container
      style={{
        height: "100vh",
        display: "flex",
      }}
    >
      <Grid
        style={{ background: "white", paddingTop: "20vh" }}
        sx={{
          display: {
            xs: "none",
            sm: "none",
            md: "none",
            lg: "flex",
            height: "100vh",
            background:
              "linear-gradient(58.66deg, #5E5BF9 0.49%, #EA4495 95.45%);",
          },
        }}
      >
        <CreateAccountForm />
      </Grid>

      <Grid
        style={{ background: "white", paddingTop: "20vh", width: "100%" }}
        sx={{
          display: {
            md: "flex",
            lg: "none",
            height: "100vh",
          },
        }}
      >
        <CreateAccountForm />
      </Grid>

      <div style={{ flexGrow: 1 }}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "none",
              lg: "flex",
              height: "100vh",
              background:
                "linear-gradient(58.66deg, #5E5BF9 0.49%, #EA4495 95.45%);",
            },
          }}
        >
          <div
            style={{
              maxWidth: "600px",
              textAlign: "center",
            }}
          >
            <h1 style={{ color: "white", fontWeight: 500 }}>
              Modern Sales AI gives you the power of 100 high performing sales
              reps
            </h1>
          </div>
        </Grid>
      </div>
    </Grid>
  );
}
