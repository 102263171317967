import { LoadingButton } from "@mui/lab";
import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  getFirestore,
  setDoc,
} from "firebase/firestore";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { post } from "jsx/Api";
import { SideMenu } from "jsx/components";
import { UserContext } from "jsx/context";
import { useContact } from "jsx/hooks";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom/dist";
import * as Yup from "yup";

import ParticipantsAutocomplete from "./ParticipantsAutocomplete";
import Uploader from "./Uploader";

const TranscriptsForm = Yup.object().shape({
  transcriptDetails: Yup.string().required("Required"),
});

export function TranscriptsNew() {
  const { contact } = useContact();
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [uploadTask, setUploadTask] = useState();
  const { workspace, user } = useContext(UserContext);
  const [participants, setParticipants] = useState();

  const [contacts, setContacts] = useState([]);
  const [uploadModeText, setUploadModeText] = useState(false);

  const navigate = useNavigate();
  const db = getFirestore();

  async function getContacts() {
    const q = await getDocs(
      collection(
        db,
        "workspaces",
        workspace.id,
        "workspaces_private",
        "data",
        "contacts"
      )
    );

    let map = q.docs.map((e) => {
      return {
        id: e.id,
        ...e.data(),
      };
    });

    map = _.orderBy(map, ["createdAt"], ["desc"]);
    console.log("map", map);
    setContacts(map);
  }

  // triggers when file is selected with click
  const onFileChanged = function (file) {
    setSelectedFile(file);
  };

  function cancel() {
    setLoading(false);
    uploadTask.cancel();
    setProgress();
  }

  useEffect(() => {
    getContacts();
  }, []);

  async function uploadText(values) {
    const t = await addDoc(
      collection(
        db,
        [
          "workspaces",
          workspace.id,
          "workspaces_private",
          "data",
          "transcripts",
        ].join("/")
      ),
      {
        ...values,
        createdAt: new Date(),
        userId: user.uid,
        transcriptName: values.name ?? "Untitled",
        participants: participants,
        text: values.transcriptText,
      },
      { merge: true }
    );

    const res = await post("transcripts/summarizeTranscript", {
      transcriptId: t.id,
    });

    navigate(`/transcripts/templates/${t.id}`, {
      replace: true,
    });
  }

  async function uploadToStorage(file, details, name) {
    if (!file.name) {
      console.log("No file");
      return;
    }

    const transcriptRef = doc(
      collection(
        db,
        [
          "workspaces",
          workspace.id,
          "workspaces_private",
          "data",
          "transcripts",
        ].join("/")
      )
    );

    const filepath = `/workspaces/${workspace.id}/${transcriptRef.id}/${file.name}`;

    await setDoc(transcriptRef, {
      filename: file.name,
      status: "uploading",
      transcriptDetails: details,
      transcriptName: name ?? "Untitled",
      createdAt: new Date(),
      userId: user.uid,
      filepath,
      participants: participants,
    });

    const storage = getStorage();
    const storageRef = ref(storage, filepath);

    // 'file' comes from the Blob or File API
    const _uploadTask = uploadBytesResumable(storageRef, selectedFile);

    _uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {
        // Handle unsuccessful uploads
      },
      async () => {
        let downloadUrl = await getDownloadURL(_uploadTask.snapshot.ref);

        downloadUrl = downloadUrl.replace(
          "http://localhost:9199",
          "https://modernsalesstorage.ngrok.io"
        );
        console.log("loadnload url", downloadUrl);
        await post("transcripts/transcribeFile", {
          transcriptId: transcriptRef.id,
          downloadUrl,
        });

        await post("workspaces/completeOnboardingStep", {
          onboardingStep: "uploadTranscript",
        });

        navigate(`/transcripts/summarize/${transcriptRef.id}`, {
          replace: true,
        });
      }
    );

    setUploadTask(_uploadTask);
  }
  return (
    <>
      <Grid container style={{ minHeight: "100vh" }}>
        <Box
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "start",
            alignItems: "start",
            minHeight: "100%",
          }}
        >
          <SideMenu />
          <Box
            className="container"
            sx={{ flexGrow: 1, justifyContent: "center", alignItems: "center" }}
          >
            <Box sx={{ height: 400, maxWidth: 700, margin: "0 auto" }}>
              <Box
                sx={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h2>New Transcript</h2>
                <div style={{ flexGrow: 1 }}></div>
                <Button
                  onClick={() => {
                    setUploadModeText(!uploadModeText);
                  }}
                >
                  Upload {uploadModeText ? "Audio / Video" : "Text"}
                </Button>
              </Box>
              {!uploadModeText && (
                <Uploader
                  progress={progress}
                  onFileChanged={onFileChanged}
                  cancel={cancel}
                  uploadModeText={uploadModeText}
                  setUploadModeText={setUploadModeText}
                />
              )}

              <Formik
                enableReinitialize
                initialValues={{
                  transcriptDetails: "",
                  transcriptName: "Untitled",
                }}
                validationSchema={TranscriptsForm}
                onSubmit={async (values) => {
                  setLoading(true);

                  if (uploadModeText) {
                    await uploadText(values);
                  } else {
                    await uploadToStorage(
                      selectedFile,
                      values.transcriptDetails,
                      values.transcriptName
                    );
                  }
                }}
              >
                {({ handleChange, errors, values }) => {
                  return (
                    <Form>
                      {uploadModeText && (
                        <>
                          <label
                            htmlFor="transcriptText"
                            className="form-label"
                            style={{ marginTop: 0 }}
                          >
                            Transcript text*
                          </label>

                          <Field
                            id="transcriptText"
                            name="transcriptText"
                            placeholder="Copy and paste transcript text here..."
                            className="form-textarea"
                            component="textarea"
                            rows="6"
                          />
                        </>
                      )}
                      <label htmlFor="transcriptDetails" className="form-label">
                        Transcript name
                      </label>
                      <Field
                        id="transcriptName"
                        name="transcriptName"
                        placeholder="Untitled"
                        className="form-textarea"
                      />

                      <label htmlFor="transcriptDetails" className="form-label">
                        Topics discussed on this call*
                      </label>
                      <Field
                        id="transcriptDetails"
                        name="transcriptDetails"
                        placeholder="The purpose of the call was Acme pitching IBM."
                        component="textarea"
                        rows="3"
                        className="form-textarea"
                      />
                      <ErrorMessage
                        name="transcriptDetails"
                        className="form-error"
                        component="div"
                      />

                      <label
                        htmlFor="transcriptDetails"
                        className="form-label"
                        style={{ marginBottom: 8 }}
                      >
                        Participants*
                      </label>

                      <ParticipantsAutocomplete
                        contacts={contacts}
                        onSelectedContacts={(v) => {
                          setParticipants(v);
                        }}
                        onUpdateContact={() => {
                          getContacts();
                        }}
                      />
                      <ErrorMessage
                        name="participants"
                        className="form-error"
                        component="div"
                      />

                      <div style={{ marginTop: 32, float: "right" }}>
                        <Button
                          size="large"
                          variant="contained"
                          disableElevation
                          color="secondary"
                          style={{ marginRight: 16 }}
                          onClick={() => {
                            navigate(-1);
                          }}
                        >
                          Cancel
                        </Button>

                        <LoadingButton
                          size="large"
                          variant="contained"
                          disableElevation
                          type="submit"
                          loading={loading}
                        >
                          Submit
                        </LoadingButton>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </Box>
          </Box>

          {/* <Grid
            container
            spacing={4}
            style={{
              flexGrow: 1,
              background: "#F9FAFC",
              height: "100%",
            }}
          >
            <Grid
              item
              md={12}
              lg={6}
              style={{
                height: "calc(100vh - 86px)",
                overflowY: "auto",
                paddingTop: 0,
              }}
            >
              <div
                style={{
                  background: "white",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  padding: 24,
                  borderBottom: "1px solid #E9E9E9",
                }}
              >
                <h3 style={{ marginBottom: 4, fontWeight: 500 }}>
                  Transcripts
                </h3>
                <div style={{ fontSize: 14, color: "#666", marginBottom: -8 }}>
                  Bing bong bing
                </div>
              </div>

            </Grid>
          </Grid> */}
        </Box>
      </Grid>
    </>
  );
}
