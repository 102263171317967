import { ArrowBack } from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import {
  collection,
  doc,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { post } from "jsx/Api";
import { SideMenu } from "jsx/components";
import { UserContext } from "jsx/context";
import _ from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";

import { GoalsForm, TranscriptsSection } from "./components";

export function Goal() {
  const { workspace } = useContext(UserContext);
  const [transcriptsCardDetails, setTranscriptsGoalDetails] = useState({});
  const [transcripts, setTranscripts] = useState();
  const [goal, setGoal] = useState({});
  const { goalId } = useParams();
  const navigate = useNavigate();

  const db = useMemo(() => getFirestore(), []);

  useEffect(() => {
    if (goal) {
      getTranscripts();
    }
  }, [goal]);

  async function getTranscripts() {
    if (!goal.transcripts || !goal.transcripts.length) {
      setTranscripts([]);
      return;
    }

    try {
      const q = await getDocs(
        query(
          collection(
            db,
            "workspaces",
            workspace.id,
            "workspaces_private",
            "data",
            "transcripts"
          ),
          where("__name__", "in", goal.transcripts)
        )
      );

      let map = q.docs.map((e) => {
        return {
          id: e.id,
          ...e.data(),
        };
      });

      map = _.orderBy(map, ["createdAt"], ["desc"]);
      setTranscripts(map);
    } catch (e) {
      console.log("error fetching transcript", e);
    }
  }

  useEffect(() => {
    if (!goalId) {
      return;
    }
    const unSub = onSnapshot(
      doc(
        db,
        "workspaces",
        workspace.id,
        "workspaces_private",
        "data",
        "goals",
        goalId
      ),
      (doc) => {
        if (!doc.exists) {
          return;
        }
        const data = doc.data();
        setGoal({
          id: doc.id,
          ...data,
        });
      }
    );

    return () => unSub();
  }, [db, goalId, workspace.id]);

  useEffect(() => {
    if (!goalId) {
      console.log("goalId", goalId);
      return;
    }
    const unSub = onSnapshot(
      collection(
        db,
        "workspaces",
        workspace.id,
        "workspaces_private",
        "data",
        "goals",
        goalId,
        "goalDetails"
      ),
      (snap) => {
        snap.docChanges().forEach((change) => {
          setTranscriptsGoalDetails((prev) => ({
            ...prev,
            [change.doc.id]: change.doc.data(),
          }));
        });
      }
    );

    return () => unSub();
  }, [db, goalId, transcripts, workspace.id]);

  const retryGenerateTranscriptAnswers = useCallback(
    async (transcriptId) => {
      if (!goalId || !goal.question) {
        console.log("not have goalId or goal.question", goalId);
        return;
      }

      await post(
        `transcripts/createTranscriptionQueryTask/${workspace.id}/${transcriptId}/${goalId}`,
        {
          query: goal.question,
        }
      );
    },
    [goal, goalId, workspace.id]
  );

  return (
    <>
      <Grid container style={{ minHeight: "100vh" }}>
        <Box
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "start",
            alignItems: "start",
            minHeight: "100%",
          }}
        >
          <SideMenu />
          <Grid
            container
            spacing={4}
            style={{
              flexGrow: 1,
              background: "#F9FAFC",
              height: "100%",
            }}
          >
            <Grid
              item
              md={12}
              lg={6}
              style={{
                height: "calc(100vh - 16px)",
                overflowY: "auto",
                paddingTop: 0,
              }}
            >
              <div
                style={{
                  background: "white",
                  padding: 24,
                  borderBottom: "1px solid #E9E9E9",
                  gap: 8,
                }}
              >
                {/* <IconButton
                  sx={{ marginBottom: "4px", marginTop: "18px" }}
                  onClick={() => navigate(-1)}
                >
                  <ArrowBack />
                </IconButton> */}
                <h3 style={{ marginBottom: 4, fontWeight: 500 }}>Goal</h3>
                <div style={{ fontSize: 14, color: "#666", marginBottom: -8 }}>
                  Track your goals over all your conversations.
                </div>
              </div>

              <GoalsForm transcripts={transcripts} goal={goal} />
            </Grid>
            <TranscriptsSection
              goal={goal}
              transcripts={transcripts}
              transcriptsCardDetails={transcriptsCardDetails}
              retryGenerateTranscriptAnswers={retryGenerateTranscriptAnswers}
            />
          </Grid>
        </Box>
      </Grid>
    </>
  );
}
