import { ArticleOutlined, CopyAllOutlined } from "@mui/icons-material";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  Tooltip,
} from "@mui/material";
import dayjs from "dayjs";
import { UserContext } from "jsx/context";
import React, { useContext, useState } from "react";

export function TranscriptQueryCell({ style, query }) {
  const { user, isSiteAdminUNSAFE } = useContext(UserContext);
  const [viewPrompt, setViewPrompt] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  let buttons;
  let promptSection;

  function handleTooltipOpen() {
    setTooltipOpen(true);
    setTimeout(() => {
      setTooltipOpen(false);
    }, 2000);
  }

  return (
    <div style={{ ...style }}>
      <div style={{ display: "flex", marginBottom: 8 }}>
        <div style={{ flexGrow: 1 }} />
      </div>
      <Paper variant="outlined" style={{ padding: 24 }}>
        <div style={{ display: "flex" }}>
          <div style={{ flexGrow: 1 }} />
        </div>

        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
            }}
          >
            <span
              style={{
                minHeight: "21px",
                fontWeight: 500,
                fontSize: 18,
                marginBottom: 8,
              }}
            >
              {query.query}
            </span>
          </div>
        </div>
        <Grid container>
          {query.response}
          {promptSection}
        </Grid>
      </Paper>
    </div>
  );
}
