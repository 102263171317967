import React, { useContext, useState } from "react";
import { Chip, Divider, IconButton, Skeleton, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { deleteDoc, doc, getFirestore } from "firebase/firestore";
import MSAnalytics from "jsx/api/analytics";
import { CampaignSplitButton } from "jsx/components";
import { UserContext } from "jsx/context";
import { useNavigate } from "react-router-dom";

import { LeadItem } from "./LeadItem/LeadItem";
import { Check, DeleteOutline } from "@mui/icons-material";

const columns = [
  // { field: "id", headerName: "ID", width: 90 },
  {
    field: "firstName",
    headerName: "First Name",
    width: 150,
    editable: false,
  },
  {
    field: "lastName",
    headerName: "Last Name",
    width: 150,
    editable: false,
  },
  //   {
  //     field: "email",
  //     headerName: "Email",
  //     width: 240,
  //     editable: false,
  //   },
  {
    field: "companyName",
    headerName: "Company",
    width: 190,
    editable: false,
  },
  {
    field: "jobTitle",
    headerName: "Job title",
    width: 200,
    editable: false,
  },

  {
    field: "updatedAt",
    headerName: "Updated at",
    width: 190,
    editable: false,
    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }

      return dayjs(new Date(params.value.seconds * 1000)).fromNow();
    },
  },
  {
    field: "status",
    headerName: "Status",
    width: 170,
    editable: false,
    renderCell: (params) => {
      return (
        <Chip
          variant="outlined"
          label={params.formattedValue}
          size="small"
          style={{ marginRight: 4 }}
        />
      );
    },
  },
  {
    field: "openCount",
    headerName: "Tracking",
    width: 80,
    editable: false,
    renderCell: (params) => {
      return (
        <>
          <Tooltip
            title={
              params.formattedValue
                ? `Email has been opened ${params.formattedValue} times`
                : "Email has not been opened"
            }
          >
            <div style={{ marginTop: -8, display: "flex" }}>
              <div style={{ marginRight: -7 }}>
                <Check
                  sx={{
                    stroke: params.row.status !== "uncontacted" ? "#6366f1" : "#CCC",
                    strokeWidth: 2,
                  }}
                  fontSize="small"
                />
              </div>

              <div style={{ marginRight: -10 }}>
                <Check
                  sx={{
                    stroke: params.formattedValue ? "#6366f1" : "#CCC",
                    strokeWidth: 2,
                  }}
                  fontSize="small"
                />
              </div>
            </div>
          </Tooltip>
        </>
      );
    },
  },
];

export function LeadsList({ leads, campaign, getLeads }) {
  const [selectionModel, setSelectionModel] = useState([]);
  const navigate = useNavigate();
  const { user, workspace } = useContext(UserContext);

  const db = getFirestore();

  async function deleteSelected() {
    for (const e of selectionModel) {
      await deleteDoc(
        doc(
          db,
          "workspaces",
          workspace.id,
          "workspaces_private",
          "data",
          "campaigns",
          campaign.id,
          "leads",
          e
        )
      );
    }

    getLeads();
  }

  let selectedSection;

  if (selectionModel.length) {
    selectedSection = (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            justifyItems: "start",
            alignItems: "start",
            marginBottom: 10,
            marginTop: -10,
          }}
        >
          <div style={{ fontWeight: 500, marginTop: 4, color: "#333" }}>
            Selected {selectionModel.length}
          </div>
          <Tooltip title="Delete" placement="top">
            <IconButton
              style={{ marginTop: 0, marginBottom: -2, marginLeft: 16 }}
              aria-label="copy"
              size="small"
              className="small-icon-button"
              placement="top"
              onClick={() => {
                deleteSelected();
              }}
            >
              <DeleteOutline fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </div>
      </>
    );
  } else {
    selectedSection = <div style={{ height: 26 }}></div>;
  }

  return (
    <>
      <div>{selectedSection}</div>
      <DataGrid
        onSelectionModelChange={(newSelectionModel) => {
          setSelectionModel(newSelectionModel);
        }}
        autoHeight
        pageSize={20}
        rows={leads}
        columns={columns}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
        onRowClick={(e, event) => {
          console.log("meta", event);
          if (event.metaKey) {
            const newTab = window.open(
              `/autopilot/${campaign.id}/leads/${e.id}`,
              "_blank"
            );
          } else {
            navigate(`/autopilot/${campaign.id}/leads/${e.id}`);
          }
        }}
        style={{ background: "white" }}
      />
    </>
  );
}
