import { Grid, Paper } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

function IntegrationItemInner({ name, description, imageUrl }) {
  return (
    <Paper
      className="integration-cell"
      variant="outlined"
      style={{
        padding: 24,
        paddingTop: 32,
        paddingBottom: 32,
      }}
    >
      <div
        style={{
          width: 60,
          height: 60,
          backgroundSize: "contain",
          backgroundImage: `url(${imageUrl})`,
        }}
      />
      <h3>{name}</h3>
      <div style={{ color: "#888" }}>{description}</div>
    </Paper>
  );
}

export function IntegrationItem({ integration }) {
  const { name, description, imageUrl, url } = integration;

  if (!url) {
    return (
      <Grid item lg={3} md={6} xs={12}>
        <IntegrationItemInner
          name={name}
          description={description}
          imageUrl={imageUrl}
        />
      </Grid>
    );
  }

  return (
    <Grid item lg={3} md={6} xs={12}>
      <Link style={{ textDecoration: "none" }} to={url}>
        <IntegrationItemInner
          name={name}
          description={description}
          imageUrl={imageUrl}
        />
      </Link>
    </Grid>
  );
}
