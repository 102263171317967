import { Grid } from "@mui/material";
import React from "react";

export function EmptySummary() {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ marginTop: "40vh" }}
    >
      <div
        style={{
          display: "flex",
          marginTop: 0,
          flexDirection: "column",
          border: "1px solid #DDD",
          padding: 16,
          borderRadius: 8,
        }}
      >
        <h4
          style={{
            color: "#333",
            marginBottom: 8,
            fontSize: 16,
            marginTop: 0,
          }}
        >
          Add meeting context to get started
        </h4>
        <p
          style={{
            fontWeight: 400,
            fontSize: 14,
            color: "#999",
            marginTop: 0,
            marginBottom: 0,
          }}
        >
          Answer the prompts and click the "Generate" button.
        </p>
        <div style={{ flexGrow: 1 }} />
      </div>
    </Grid>
  );
}
