import { getDocs, query, where } from "firebase/firestore";

export const firestoreCollectionToArray = (snapshots) => {
  const data = [];

  snapshots.forEach((snap) => {
    data.push({ ...snap.data(), id: snap.id });
  });

  return data;
};

export async function getContentWhereFieldInArray({collectionRef, field, valuesToSearchIn = []}) {
  const batches = [];

  while (valuesToSearchIn.length) {
    const batch = valuesToSearchIn.splice(0, 10);

    batches.push(
      getDocs(query(collectionRef, where(field, 'in', [...batch]))).then(firestoreCollectionToArray)
    );
  }

  return Promise.all(batches).then((content) => content.flat());
}
