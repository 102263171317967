import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Grid, Tab } from "@mui/material";
import { CustomCircularProgress } from "jsx/components";
import React, { useState } from "react";

import { TranscriptsList } from "./TranscriptsHistory/TranscriptsList";

export function TranscriptsSection({
  goal,
  transcripts,
  transcriptsCardDetails,
  retryGenerateTranscriptAnswers,
}) {
  const [currentTab, setCurrentTab] = useState("1");

  const onCurrentTabChange = (tab, value) => {
    setCurrentTab(value);
  };

  return (
    <Grid
      item
      md={12}
      lg={6}
      style={{
        width: "100%",
        height: "calc(100vh)",
        overflowY: "auto",
        background: "white",
        paddingRight: 0,
        paddingLeft: 0,
        borderLeft: "1px solid #E9E9E9",
      }}
    >
      <TabContext value={currentTab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={onCurrentTabChange}>
            <Tab label="Transcripts" value="1" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <TranscriptsList
            goal={goal}
            transcripts={transcripts}
            transcriptsCardDetails={transcriptsCardDetails}
            retryGenerateTranscriptAnswers={retryGenerateTranscriptAnswers}
          />
        </TabPanel>
      </TabContext>
    </Grid>
  );
}
