import { CheckCircleOutline, EmailOutlined } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Alert, Box, Button, Grid, Tab } from "@mui/material";
import zIndex from "@mui/material/styles/zIndex";
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";

import { ContactCell, ContactExpandedCell, ContactLoadingCell } from "..";
import { ContactsList } from "./ContactsList/ContactsList";
import { EmptyContacts } from "./EmptyContacts/EmptyContacts";
import { ToastContainer, toast } from "react-toastify";

export function ContactsSection({
  currentContact,
  showError,
  isGenerating,
  getContacts,
  onUpdate,
  newContact,
}) {
  const [currentTab, setCurrentTab] = useState("1");
  const submitRef = useRef();
  const [hasUpdatedContactInfo, setHasUpdatedContactInfo] = useState();

  const onTabChange = (tab, value) => {
    setCurrentTab(value);
  };

  let newGenerationSection;

  if (isGenerating) {
    newGenerationSection = <ContactLoadingCell />;
  } else {
    if (currentContact) {
      newGenerationSection = (
        <Box style={{ paddingBottom: 80 }}>
          <ContactExpandedCell
            contact={currentContact}
            isGenerating={isGenerating}
            submitRef={submitRef}
            onUpdate={onUpdate}
            newContact={newContact}
          />
        </Box>
      );
    } else {
      newGenerationSection = <EmptyContacts />;
    }
  }

  return (
    <Grid
      item
      md={12}
      lg={6}
      sx={{
        width: "100%",
        height: "calc(100vh)",
        overflowY: { lg: "auto", md: "" },
        background: "white",
        paddingRight: 0,
        paddingLeft: 0,
        borderLeft: "1px solid #E9E9E9",
      }}
    >
      <TabContext value={currentTab}>
        <Box
          sx={{
            borderBottom: 1,
            width: "100%",
            zIndex: 1,
            background: "white",
            position: { lg: "sticky", md: "block" },
            borderTop: { lg: "0px", md: "1px" },
            borderColor: "divider",
            top: 0,
          }}
        >
          <div style={{ display: "flex" }}>
            <TabList onChange={onTabChange} aria-label="lab API tabs example">
              <Tab label="Contact" value="1" />
              {/* <Tab label="Contacts" value="2" /> */}
            </TabList>
            <div style={{ flexGrow: 1 }} />
            <div
              style={{
                display: "flex",
                margin: 8,
                flexShrink: 0,
              }}
            >
              <div>
                <Button
                  variant={currentContact?.id ? "outlined" : "contained"}
                  type="submit"
                  disableElevation
                  size="small"
                  style={{ marginRight: 8 }}
                  onClick={() => {
                    submitRef.current.click();
                    setHasUpdatedContactInfo(true);
                    setTimeout(() => {
                      setHasUpdatedContactInfo(false);
                    }, 2000);

                    toast.success(
                      `Updated Contact info for ${currentContact?.firstName}`,
                      {
                        position: toast.POSITION.BOTTOM_LEFT,
                      }
                    );
                  }}
                  endIcon={
                    hasUpdatedContactInfo && (
                      <>
                        <CheckCircleOutline />
                      </>
                    )
                  }
                >
                  {currentContact?.id
                    ? hasUpdatedContactInfo
                      ? "Updated"
                      : "Update contact"
                    : "Add new contact"}
                </Button>
              </div>
              {currentContact?.id && (
                <Link
                  to={`/draft?contactId=${currentContact?.id}`}
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    startIcon={<EmailOutlined />}
                    variant="contained"
                    size="small"
                    disableElevation
                  >
                    Open in Drafts
                  </Button>
                </Link>
              )}
            </div>
          </div>
        </Box>
        {showError && (
          <>
            <Alert severity="error">
              Something went wrong when trying to find this person. Try a
              different person.
            </Alert>
          </>
        )}
        <TabPanel value="1">{newGenerationSection}</TabPanel>
        <TabPanel value="2">
          {/* <ContactsList contacts={contacts} getContacts={getContacts} /> */}
        </TabPanel>
      </TabContext>
      <ToastContainer />
    </Grid>
  );
}
