import { isLocal } from "jsx/api/firebase.setup";
import { CustomCircularProgress } from "jsx/components";
import { UserContext } from "jsx/context";
import { AdminUnauthorizedPage } from "jsx/pages";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export function RouteRequiresAdmin(props) {
  const navigate = useNavigate();
  const { user, hasProcessedAuth } = useContext(UserContext);

  useEffect(() => {
    if (!user.uid && hasProcessedAuth) {
      navigate("/login", { replace: true });
    }
  }, [user, hasProcessedAuth]);

  if (
    (hasProcessedAuth &&
      user.admin &&
      user.admin.role &&
      user.admin.role === "admin") ||
    isLocal
  ) {
    return props.children;
  } else if (
    hasProcessedAuth &&
    (!user.admin || !user.admin.role || user.admin.role !== "admin")
  ) {
    return <AdminUnauthorizedPage />;
  } else {
    return (
      <div style={{ top: "40vh", position: "absolute", left: "50vw" }}>
        <CustomCircularProgress size={60} />
      </div>
    );
  }
}
