import { Skeleton } from "@mui/material";
import { decode } from "js-base64";
import { post } from "jsx/Api";
import { EmailCell } from "jsx/components";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { DraftSection } from "./DraftSection/DraftSection";
import { InboxButtons } from "./InboxButtons/InboxButtons";

export function EmailSection({
  lead,
  emails,
  conversation,
  onConversationGet,
  onEmailsGet,
}) {
  const { campaignId, leadId } = useParams();
  const [isGeneratingDraft, setGeneratingDraft] = useState(false);
  const [isRefreshingInbox, setRefreshingInbox] = useState(false);
  const [currentDraft, setCurrentDraft] = useState({});

  useEffect(() => {
    if (conversation?.threadId) {
      checkForUpdates();
    }
  }, [conversation?.threadId]);

  async function checkForUpdates() {
    if (!conversation.threadId) {
      return;
    }
    setRefreshingInbox(true);

    await post("leads/updateEmailsForConversation", {
      campaignId,
      leadId,
      conversationId: conversation.id,
    });

    await Promise.all([onConversationGet(), onEmailsGet()]);
    setRefreshingInbox(false);
  }

  if (!emails) {
    return (
      <Skeleton
        variant="rectangular"
        height={400}
        style={{ marginBottom: 12, marginTop: 48 }}
      />
    );
  }

  if (emails.length || conversation?.draft || isGeneratingDraft) {
    return (
      <div style={{ paddingBottom: 48 }}>
        <DraftSection
          lead={lead}
          conversation={conversation}
          currentDraft={currentDraft}
          isRefreshingInbox={isRefreshingInbox}
          isGeneratingDraft={isGeneratingDraft}
          setGeneratingDraft={setGeneratingDraft}
          setCurrentDraft={setCurrentDraft}
          checkForUpdates={checkForUpdates}
          onConversationGet={onConversationGet}
        />
        {emails.map((email, i) => {
          let payload;

          if (email.payload?.body?.data) {
            payload = decode(email.payload.body.data);
          } else if (email.payload?.parts?.length > 1) {
            payload = decode(email.payload.parts[1].body?.data);
          } else if (email.payload?.parts?.length > 0) {
            payload = decode(email.payload.parts[0].body?.data);
          } else {
            return null;
          }

          return (
            <EmailCell
              from={email.payload.headers.find((e) => e.name === "From").value}
              to={email.payload.headers.find((e) => e.name === "To").value}
              timestamp={email.internalDate}
              html={payload.replaceAll("\n", "<br />")}
              key={i}
              style={{ marginBottom: 32 }}
              email={email}
            />
          );
        })}
      </div>
    );
  }

  return (
    <div style={{ display: "flex", marginTop: 0 }}>
      <h3>Draft a new email to get started.</h3>
      <div style={{ flexGrow: 1 }} />
      <div style={{ marginTop: 16 }}>
        <InboxButtons
          conversation={conversation}
          isRefreshingInbox={isRefreshingInbox}
          isGeneratingDraft={isGeneratingDraft}
          setGeneratingDraft={setGeneratingDraft}
          setCurrentDraft={setCurrentDraft}
          checkForUpdates={checkForUpdates}
          onConversationGet={onConversationGet}
        />
      </div>
    </div>
  );
}
