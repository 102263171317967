import {
  DeleteOutline,
  LocationOnOutlined,
  PersonOutline,
  SportsBasketballOutlined,
  WorkOutline,
} from "@mui/icons-material";
import {
  Button,
  Chip,
  Grid,
  IconButton,
  Skeleton,
  styled,
  Tooltip,
} from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import dayjs from "dayjs";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
} from "firebase/firestore";
import { SideMenu } from "jsx/components";
import { CustomNoRowsOverlay } from "jsx/components/EmptyState";
import { UserContext } from "jsx/context";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { UploadCSVContactsModal } from "./components/Uploading/UploadCSVContactsModal";

export function Contacts() {
  const [contacts, setContacts] = useState();
  const navigate = useNavigate();
  const [selectionModel, setSelectionModel] = useState([]);
  const [showUploadCSVModal, setShowUploadCSVModal] = useState(false);
  const { user, workspace } = useContext(UserContext);

  const uid = useParams()["userId"] ?? user.uid;
  const db = getFirestore();

  const allTags = useMemo(() => {
    const res = Array.from(new Set((contacts || []).reduce((acc, next) => [...acc, ...next?.tags || []], [])));
    localStorage.setItem(
      "contactsTagsOptions",
      JSON.stringify(res)
    );
    return res
  }, [contacts])

  const columns = useMemo(() => {
    const columns = [
      // { field: "id", headerName: "ID", width: 90 },
      {
        field: "firstName",
        headerName: "First Name",
        width: 130,
        editable: false,
      },
      {
        field: "lastName",
        headerName: "Last Name",
        width: 130,
        editable: false,
      },
      //   {
      //     field: "email",
      //     headerName: "Email",
      //     width: 240,
      //     editable: false,
      //   },
      {
        field: "companyName",
        headerName: "Company",
        width: 150,
        editable: false,
      },
      {
        field: "info",
        headerName: "Info",
        width: 120,
        editable: false,
        renderCell: (params) => {
          const icons = [];

          if (params.row.summary?.length > 0) {
            icons.push(
              <Tooltip title="Contact has bio summary">
                <PersonOutline fontSize="small" />
              </Tooltip>
            );
          }
          if (params.row.location?.length > 0) {
            icons.push(
              <Tooltip title="Contact has location info">
                <LocationOnOutlined fontSize="small" />
              </Tooltip>
            );
          }
          if (params.row.jobTitle?.length > 0) {
            icons.push(
              <Tooltip title="Contact has job title info">
                <WorkOutline fontSize="small" />
              </Tooltip>
            );
          }

          if (params.row.interests?.length > 0) {
            icons.push(
              <Tooltip title="Contact has interests info">
                <SportsBasketballOutlined fontSize="small" />
              </Tooltip>
            );
          }

          return (
            <div className="d-flex" style={{ color: "#CCC" }}>
              {icons}
            </div>
          );
        },
      },
      {
        field: "createdAt",
        headerName: "Created at",
        width: 150,
        editable: false,
        valueFormatter: (params) => {
          if (params.value == null) {
            return "";
          }

          return dayjs(new Date(params.value.seconds * 1000)).fromNow();
        },
      },

      // {
      //   field: "actions",
      //   headerName: "Actions",
      //   width: 100,
      //   editable: false,
      //   valueFormatter: (params) => {
      //     if (params.value == null) {
      //       return "";
      //     }

      //     return dayjs(new Date(params.value.seconds * 1000)).fromNow();
      //   },

      //   renderCell: (params) => {
      //     return (
      //       <>
      //         <Button
      //           variant="outlined"
      //           size="small"
      //           color="gray"
      //           sx={{ color: "#666", borderColor: "#CCC" }}
      //           onClick={(e) => {
      //             e.stopPropagation();
      //             e.preventDefault();

      //             navigate(`/draft?contactId=${params.row.id}`);
      //           }}
      //         >
      //           Draft email
      //         </Button>
      //       </>
      //     );
      //   },
      // },
      {
        field: "tags",
        headerName: "Tags",
        width: 180,
        editable: false,
        renderCell: (params) => {
          return (
            params.formattedValue &&
            params.formattedValue.map((e) => {
              return (
                <Chip
                  variant="outlined"
                  label={e}
                  size="small"
                  style={{ marginRight: 4 }}
                />
              );
            })
          );
        },
      },
    ];

    return columns;
  }, []);

  async function getContacts() {
    try {
      const q = await getDocs(
        collection(
          db,
          "workspaces",
          workspace.id,
          "workspaces_private",
          "data",
          "contacts"
        )
      );

      let map = q.docs.map((e) => {
        return {
          id: e.id,
          ...e.data(),
        };
      });

      map = _.orderBy(
        map,
        [(contact) => new Date(contact?.createdAt)],
        ["desc"]
      );

      setContacts(map);

      // if (map.length === 0) {
      //   navigate("/contacts/new");
      // }
    } catch (e) {
      console.log("error fetching contacts", e);
    }
  }

  useEffect(() => {
    getContacts();
  }, [workspace, uid]);

  async function deleteSelected() {
    for (const e of selectionModel) {
      await deleteDoc(
        doc(
          db,
          "workspaces",
          workspace.id,
          "workspaces_private",
          "data",
          "contacts",
          e
        )
      );
    }

    await getContacts();
  }

  let content;

  if (contacts) {
    content = (
      <>
        <DataGrid
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          autoHeight
          pageSize={20}
          rows={contacts}
          columns={columns}
          checkboxSelection
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          onRowClick={(e) => {
            navigate(`/contacts/${e.id}`);
          }}
          components={{
            NoRowsOverlay: CustomNoRowsOverlay,
          }}
          style={{ minHeight: 400 }}
        />
      </>
    );
  } else {
    content = (
      <Skeleton variant="rectangular" height={400} style={{ marginTop: 32 }} />
    );
  }

  let selectedSection;

  if (selectionModel.length) {
    selectedSection = (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            justifyItems: "start",
            alignItems: "start",
          }}
        >
          <div style={{ fontWeight: 500, marginTop: 16, color: "#333" }}>
            Selected {selectionModel.length}
          </div>
          <Tooltip title="Delete" placement="top">
            <IconButton
              style={{ marginTop: 10, marginBottom: -2, marginLeft: 16 }}
              aria-label="copy"
              size="small"
              className="small-icon-button"
              placement="top"
              onClick={() => {
                deleteSelected();
              }}
            >
              <DeleteOutline fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </div>
      </>
    );
  } else {
    selectedSection = <div style={{ height: 36 }}></div>;
  }

  return (
    <>
      <Grid container style={{ minHeight: "100vh" }}>
        <Box
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "start",
            alignItems: "start",
            minHeight: "100%",
          }}
        >
          <SideMenu />
          <Box
            className="container"
            sx={{ flexGrow: 1, justifyContent: "center", alignItems: "center" }}
          >
            <Box sx={{ height: 400, maxWidth: 1000, margin: "0 auto" }}>
              <Box
                sx={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: 2,
                  width: "100%",
                }}
              >
                <div style={{ flexGrow: 1 }}>
                  <h2 style={{ marginBottom: 0 }}>Contacts</h2>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "row",
                    }}
                  >
                    <div>{selectedSection}</div>
                    <div style={{ flexGrow: 1 }}></div>
                  </div>
                </div>

                <div style={{ flexGrow: 1 }}></div>

                <Button
                  variant="outlined"
                  disableElevation
                  style={{ marginRight: 16 }}
                  onClick={() => setShowUploadCSVModal(true)}
                >
                  Import contacts
                </Button>

                <Link to={"/contacts/new"} style={{ textDecoration: "none" }}>
                  <Button variant="contained" disableElevation>
                    New Contact
                  </Button>
                </Link>
              </Box>
              {content}
            </Box>
          </Box>
        </Box>
        <UploadCSVContactsModal
          showModal={showUploadCSVModal}
          shouldHideModal={() => {
            getContacts();
            setShowUploadCSVModal(false);
          }}
          tagsOptions={allTags}
        />
        <ToastContainer />
      </Grid>
    </>
  );
}
