import { LoadingButton } from "@mui/lab";
import { Grid, Paper } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

export function NoSubscription({ setEditPlanLoading, editPlanLoading }) {
  const navigate = useNavigate();

  return (
    <Grid item xs={12}>
      <Paper
        variant="outlined"
        style={{ padding: 24, paddingTop: 24, paddingBottom: 24 }}
      >
        <div style={{ display: "flex" }}>
          <h4 style={{ margin: 0, fontSize: 18, marginBottom: 4 }}>
            Subscription
          </h4>
        </div>

        <div style={{ color: "#666", marginTop: 8, fontSize: 14 }}>
          No subscription plan active
        </div>

        <div style={{ display: "flex", marginTop: 32 }}>
          <div style={{ flexGrow: 1 }}></div>

          <LoadingButton
            variant="outlined"
            size="small"
            color="gray"
            onClick={() => {
              setEditPlanLoading(true);
              navigate("/pricing");
            }}
            loading={editPlanLoading}
          >
            Add plan
          </LoadingButton>
        </div>
      </Paper>
    </Grid>
  );
}
