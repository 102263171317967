import "react-toastify/dist/ReactToastify.css";

import { GlobalStyles } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { UserContext } from "jsx/context";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import { isLocal } from "./api/firebase.setup";
import { useAuthState, useLocalStorage } from "./hooks";
import { AppRoutes } from "./routes";
import { getMainTheme } from "./themes";

function App() {
  const {
    ctx,
    hasProcessedAuth,
    isSiteAdminUNSAFE,
    workspace,
    updateWorkspace,
    updateCtx,
    logout,
    reloadUser,
  } = useAuthState();
  const [darkMode, setDarkMode] = useLocalStorage("darkMode");
  const defaultDark = window.matchMedia("(prefers-color-scheme: dark)").matches;

  const theme = getMainTheme(darkMode);

  function toggleDarkMode() {
    setDarkMode(!darkMode);
  }

  return (
    <div data-theme={darkMode ? "dark" : ""}>
      <ThemeProvider theme={theme}>
        <GoogleOAuthProvider clientId="17517890217-6iqo3for8ln408miksgb7m8pk4dj22h0.apps.googleusercontent.com">
          <GlobalStyles
            styles={{
              body: {
                backgroundColor: darkMode ? "#1E1E1E" : "white",
              },
            }}
          />
          <UserContext.Provider
            value={{
              user: ctx.user,
              updateCtx,
              darkMode,
              toggleDarkMode,
              logout,
              reloadUser,
              workspace,
              isLocal,
              updateWorkspace,
              hasProcessedAuth,
              isSiteAdminUNSAFE,
            }}
          >
            <Router>
              <AppRoutes />
            </Router>
          </UserContext.Provider>
        </GoogleOAuthProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
