import { LoadingButton } from "@mui/lab";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { addDoc, collection, getFirestore } from "firebase/firestore";
import { Form, ErrorMessage, Field, Formik } from "formik";
import { SideMenu } from "jsx/components";
import { UserContext } from "jsx/context";
import { getFirstNameLastName } from "jsx/utils";
import { useContext, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import * as Yup from "yup";

const NewContactForm = Yup.object().shape({
  fullName: Yup.string().required("Required"),
});

export function ContactsNew() {
  const [searchParams] = useSearchParams();

  const db = getFirestore();
  const { workspace, isLocal } = useContext(UserContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState();

  async function handleSubmit(values) {
    const { firstName, lastName } = getFirstNameLastName(values.fullName);

    const v = values;

    if (firstName) {
      v["firstName"] = firstName;
    }

    if (lastName) {
      v["lastName"] = lastName;
    }

    const cc = await addDoc(
      collection(
        db,
        [
          "workspaces",
          workspace.id,
          "workspaces_private",
          "data",
          "contacts",
        ].join("/")
      ),
      { ...v, createdAt: new Date(), source: "contacts" }
    );

    navigate(`/contacts/${cc.id}?new=1`, { replace: true });
  }

  return (
    <>
      <Grid container style={{ minHeight: "100vh" }}>
        <Box
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "start",
            alignItems: "start",
            minHeight: "100%",
          }}
        >
          <SideMenu />
          <Box
            className="container"
            sx={{ flexGrow: 1, justifyContent: "center", alignItems: "center" }}
          >
            <Box sx={{ height: 400, maxWidth: 700, margin: "0 auto" }}>
              <Box
                sx={{
                  flexDirection: "row",
                  display: "flex",
                }}
              >
                <h2>New Contact</h2>
                <div style={{ flexGrow: 1 }}></div>
              </Box>

              <Box sx={{ paddingBottom: "40px" }}>
                <Formik
                  validationSchema={NewContactForm}
                  initialValues={{ fullName: "", email: "" }}
                  onSubmit={handleSubmit}
                  enableReinitialize
                >
                  <Form
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div>
                      <Grid
                        container
                        style={{
                          width: "100%",
                        }}
                      >
                        <Grid item xs={12}>
                          <Grid container style={{ width: "100%" }}>
                            <Grid item xs={12}>
                              <div>
                                <label
                                  htmlFor="fullName"
                                  className="form-label"
                                >
                                  Full name*
                                </label>
                                <Field name="fullName" className="form-input" />
                                <ErrorMessage
                                  name="fullName"
                                  className="form-error"
                                  component="div"
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container style={{ width: "100%" }}>
                            <Grid item xs={12}>
                              <div>
                                <label htmlFor="email" className="form-label">
                                  Email
                                </label>
                                <Field
                                  id="email"
                                  name="email"
                                  className="form-input"
                                />
                                <ErrorMessage
                                  name="email"
                                  className="form-error"
                                  component="div"
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                    <div style={{ marginTop: 16, display: "flex" }}>
                      <div style={{ flexGrow: 1 }} />

                      <LoadingButton
                        variant="contained"
                        disableElevation
                        type="submit"
                        loading={loading}
                      >
                        Create contact
                      </LoadingButton>
                    </div>
                  </Form>
                </Formik>
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>
    </>
  );
}
