import React from "react";
import styles from "./UseCaseOption.module.scss";
import { Button } from "@mui/material";
import classNames from "classnames";

export function UseCaseOption({ useCase, isSelected, onUseCaseSelect }) {
  const classes = classNames(styles.option, { [styles.active]: isSelected });

  function handleOptionSelect() {
    onUseCaseSelect(useCase, isSelected);
  }

  return (
    <div className={classes} onClick={handleOptionSelect}>
      <span>{useCase}</span>
    </div>
  );
}
