import { InfoOutlined } from "@mui/icons-material";
import { Grid, Paper, Tooltip } from "@mui/material";
import React from "react";

export function AvailableCredits({ subscriptionCredits, bonusCredits }) {
  return (
    <Grid item xs={4}>
      <Paper
        variant="outlined"
        style={{ padding: 24, paddingTop: 24, paddingBottom: 24 }}
      >
        <div style={{ display: "flex" }}>
          <div style={{ color: "#666", fontWeight: 500, fontSize: 14 }}>
            Credits available
          </div>
          <Tooltip
            title="Subscription credits plus bonus credits"
            placement="top"
          >
            <InfoOutlined
              sx={{
                fontSize: 16,
                color: "#CCC",
                marginLeft: "8px",
              }}
            />
          </Tooltip>
        </div>
        <h2 style={{ margin: 0, marginTop: 8 }}>
          {(
            parseInt(subscriptionCredits) + parseInt(bonusCredits)
          ).toLocaleString()}
        </h2>
      </Paper>
    </Grid>
  );
}
