import { Button, Divider, Grid } from "@mui/material";
import { EditLeadModal } from "jsx/components";
import { useLeadsService } from "jsx/hooks";
import { getFullNameFromContact } from "jsx/utils";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";

export function LeadSection({ lead }) {
  const [isEditingLead, setEditingLead] = useState(false);
  const { leadId, campaignId } = useParams();

  const { editLead } = useLeadsService(campaignId);

  function onEditingStart() {
    setEditingLead(true);
  }

  function onEditingEnd() {
    setEditingLead(false);
  }

  function onLeadEdit(options) {
    editLead(options, leadId);
  }

  if (!lead) {
    return null;
  }

  const { email, firstName, lastName, company, jobTitle } = lead;

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <h2>{getFullNameFromContact(lead)}</h2>
      <Grid container alignItems={"center"}>
        <h3 style={{ marginBottom: 16 }}>Profile Information</h3>
        <div style={{ flexGrow: 1 }} />
        <Link to={`/contacts/${lead.id}`} style={{ textDecoration: "none" }}>
          <Button style={{ marginBottom: -16 }}>
            Edit Profile Information
          </Button>
        </Link>
      </Grid>
      <EditLeadModal
        editLead={onLeadEdit}
        lead={lead}
        showModal={isEditingLead}
        shouldHideModal={onEditingEnd}
      />
      <Divider width="100%" style={{ marginBottom: 32 }} />

      <Grid container spacing={1}>
        <Grid item xs={3}>
          <div style={{ color: "#666" }}>Email</div>
        </Grid>
        <Grid item xs={9}>
          <div>{email}</div>
        </Grid>

        <Grid item xs={3}>
          <div style={{ color: "#666" }}>First Name</div>
        </Grid>
        <Grid item xs={9}>
          <div>{firstName}</div>
        </Grid>

        <Grid item xs={3}>
          <div style={{ color: "#666" }}>Last Name</div>
        </Grid>
        <Grid item xs={9}>
          <div>{lastName}</div>
        </Grid>

        <Grid item xs={3}>
          <div style={{ color: "#666" }}>Company</div>
        </Grid>
        <Grid item xs={9}>
          <div>{company}</div>
        </Grid>

        <Grid item xs={3}>
          <div style={{ color: "#666" }}>Job Title</div>
        </Grid>
        <Grid item xs={9}>
          <div>{jobTitle}</div>
        </Grid>
      </Grid>
    </div>
  );
}
