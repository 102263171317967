import { Check } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Button, Grid, Tab } from "@mui/material";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { UserContext } from "jsx/context";
import React, { useContext, useState } from "react";

import { DraftLoadingCell } from "../Draft/components";
import { EmptySummary } from "./EmptySummary";
import { TranscriptSummaryCell } from "./TranscriptSummaryCell";

export function TranscriptsSummarySection({ transcript, isGenerating }) {
  const [currentTab, setCurrentTab] = useState("1");
  const [summary, setSummary] = useState(transcript.summary);
  const { workspace } = useContext(UserContext);
  const [showUpdate, setShowUpdated] = useState(false);

  const onCurrentTabChange = (tab, value) => {
    setCurrentTab(value);
  };

  async function updateTranscript() {
    const db = getFirestore();

    const q = await setDoc(
      doc(
        db,
        "workspaces",
        workspace.id,
        "workspaces_private",
        "data",
        "transcripts",
        transcript.id
      ),
      { summary },
      { merge: true }
    );
  }

  let section;

  if (isGenerating) {
    section = <DraftLoadingCell />;
  } else {
    section = (
      <>
        {transcript ? (
          <div style={{ paddingBottom: 48 }}>
            <TranscriptSummaryCell
              key="current"
              style={{ marginBottom: 16 }}
              transcript={transcript}
              onSummaryChange={(e) => {
                setSummary(e.target.value);
              }}
            />
          </div>
        ) : (
          <EmptySummary />
        )}
      </>
    );
  }

  return (
    <Grid
      item
      md={12}
      lg={6}
      style={{
        height: "calc(100vh)",
        overflowY: "auto",
        background: "white",
        paddingRight: 0,
        paddingLeft: 0,
        borderLeft: "1px solid #E9E9E9",
      }}
    >
      <TabContext value={currentTab}>
        <Box
          sx={{
            borderBottom: 1,
            width: "100%",
            zIndex: 1,
            background: "white",
            position: { lg: "sticky", md: "block" },
            borderTop: { lg: "0px", md: "1px" },
            borderColor: "divider",
            top: 0,
          }}
        >
          <div style={{ display: "flex" }}>
            <TabList
              onChange={onCurrentTabChange}
              aria-label="lab API tabs example"
            >
              <Tab label="Summary" value="1" />
            </TabList>
            <div style={{ flexGrow: 1 }} />
            <Button
              style={{
                margin: 8,
                right: 0,
              }}
              variant="outlined"
              size="small"
              disableElevation
              startIcon={showUpdate ? <Check /> : ""}
              onClick={() => {
                updateTranscript();
                setShowUpdated(true);

                setTimeout(() => {
                  setShowUpdated(false);
                }, 3000);
              }}
            >
              {showUpdate ? "Updated" : "Update"} Transcript
            </Button>
          </div>
        </Box>
        <TabPanel value="1">{section}</TabPanel>
      </TabContext>
    </Grid>
  );
}
