import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import {
  connectFirestoreEmulator,
  enableIndexedDbPersistence,
  getFirestore,
} from "firebase/firestore";
import { getPerformance } from "firebase/performance";
import { connectStorageEmulator, getStorage } from "firebase/storage";

import firebaseConfig from "../../configs/firebase-config.json";

export const isLocal =
  window.location.hostname === "localhost" ||
  window.location.hostname === "127.0.0.1";

function setupFirebase() {
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

  getPerformance(app);

  const auth = getAuth();
  const db = getFirestore();
  const storage = getStorage();

  if (isLocal) {
    connectFirestoreEmulator(db, "localhost", 8080);
    connectAuthEmulator(auth, "http://localhost:9099");
    connectStorageEmulator(storage, "localhost", 9199);
  }

  if (!isLocal) {
    enableIndexedDbPersistence(db)
      .then(() => {})
      .catch((err) => {
        if (err.code === "failed-precondition") {
          // Multiple tabs open, persistence can only be enabled
          // in one tab at a a time.
          // ...
        } else if (err.code === "unimplemented") {
          // The current browser does not support all of the
          // features required to enable persistence
          // ...
        } else {
          console.log("failed");
        }
      });
  }

  return { analytics, db, auth };
}

const firebaseApp = setupFirebase();

export default firebaseApp;
