import {
  Alert,
  AlertTitle,
  Button,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { post } from "jsx/Api";
import { UserContext } from "jsx/context";
import { useTeamMembersService } from "jsx/hooks";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";

import { AddTeamMemberModal } from "../AddTeamMemberModal/AddTeamMemberModal";
import { StyledTableCell } from "./styles";
import { TeamMembersList } from "./TeamMembersList/TeamMembersList";

export function TeamMembersTable() {
  const { user, workspace } = useContext(UserContext);
  const [teamMembers, setTeamMembers] = useState();
  const [showError, setShowError] = useState(false);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { getTeamMembers } = useTeamMembersService();

  useEffect(() => {
    console.log("test", workspace);
    if (user && workspace) {
      onTeamMembersGet();
    }
  }, [user, workspace]);

  async function deleteTeamMember(teamMember) {
    await post("workspaces/removeTeamMember", { teamMemberEmail: teamMember });
    onTeamMembersGet();
  }

  function onTeamMembersGet() {
    getTeamMembers((data) => setTeamMembers(data));
  }

  function onUserDelete(teamMember) {
    if (teamMember.email === user.email) {
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 5000);
    } else {
      deleteTeamMember(teamMember.email);
    }
  }

  return (
    <>
      {showError && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          You can't remove yourself from your own team.
        </Alert>
      )}

      <AddTeamMemberModal
        showModal={open}
        shouldHideModal={() => {
          handleClose();
          onTeamMembersGet();
        }}
      />

      <div
        style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
      >
        <Button
          variant="contained"
          disableElevation
          onClick={handleOpen}
          size="medium"
          style={{ marginBottom: 16, marginTop: -48 }}
        >
          Add team member
        </Button>
      </div>

      <TableContainer component={Paper} elevation={0} variant="outlined">
        <Table sx={{}} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Member</StyledTableCell>
              <StyledTableCell align="right">Role</StyledTableCell>
              <StyledTableCell align="right">Status</StyledTableCell>
              <StyledTableCell align="right">Joined</StyledTableCell>
              <StyledTableCell align="right">Invite link</StyledTableCell>
              <StyledTableCell align="right">Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TeamMembersList
              teamMembers={teamMembers}
              onUserDelete={onUserDelete}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
