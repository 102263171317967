import {
  CheckCircle as CheckCircleIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
} from "@mui/icons-material";
import { Button, Divider } from "@mui/material";
import {
  collection,
  doc,
  getDocs,
  getFirestore,
  setDoc,
} from "firebase/firestore";
import { UserContext } from "jsx/context";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { AuthSendersContent } from "./AuthSendersContent/AuthSendersContent";

export function AuthSendersSection({ campaign, getCampaign, saveCampaign }) {
  const db = getFirestore();
  const { user, workspace } = useContext(UserContext);
  const uid = useParams()["userId"] ?? user.uid;

  const [authedSenders, setAuthedSenders] = useState();
  const [showSenderSection, setShowSenderSection] = useState();

  useEffect(() => {
    if (uid) {
      getAuthedSenders();
    }
  }, [uid]);

  async function getAuthedSenders() {
    const q = await getDocs(
      collection(
        db,
        "workspaces",
        workspace.id,
        "workspaces_private",
        "data",
        "authed_senders"
      )
    );

    const map = q.docs.map((e) => {
      return {
        id: e.id,
        ...e.data(),
      };
    });

    setAuthedSenders(map);
  }

  function onShowSenderSectionToggle() {
    setShowSenderSection((prev) => !prev);
  }

  async function onGmailButtonSuccess(res) {
    console.log("res", res);
    console.log("gently sleeps?", res.data.email);
    // set
    await setDoc(
      doc(
        db,
        "workspaces",
        workspace.id,
        "workspaces_private",
        "data",
        "campaigns",
        campaign.id
      ),
      { authedSender: res.data.email },
      { merge: true }
    );
    await getCampaign();
    setAuthedSenders(null);
    getAuthedSenders();
  }

  return (
    <>
      <Divider width="100%" style={{ marginTop: 32, marginBottom: 32 }} />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          alignItems: "start",
          justifyItems: "start",
        }}
      >
        {campaign?.authedSender && (
          <CheckCircleIcon style={{ color: "#6366f1", marginRight: 8 }} />
        )}

        {!campaign?.authedSender && (
          <CheckCircleOutlineIcon style={{ color: "#999", marginRight: 8 }} />
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
          }}
        >
          <p
            style={{
              color: "#333",
              marginTop: 2,
              marginBottom: 8,
              fontSize: 18,
            }}
          >
            Email account used for outreach
          </p>
          <AuthSendersContent
            campaign={campaign}
            authedSenders={authedSenders}
            showSenderSection={showSenderSection}
            onGmailButtonSuccess={onGmailButtonSuccess}
            getAuthedSenders={getAuthedSenders}
            getCampaign={getCampaign}
            saveCampaign={saveCampaign}
            setAuthedSenders={setAuthedSenders}
            setShowSenderSection={setShowSenderSection}
          />
        </div>
        {!showSenderSection && authedSenders?.length > 0 && (
          <Button
            variant="contained"
            disableElevation
            onClick={onShowSenderSectionToggle}
            color="secondary"
            style={{ marginTop: -8 }}
          >
            Edit sender
          </Button>
        )}
      </div>
    </>
  );
}
