import { Skeleton } from "@mui/material";
import React from "react";

import { EmptyQuery } from "./EmptyQuery";
import { TranscriptQueryCell } from "./TranscriptCell";

export function TranscriptQueryHistory({ queries }) {
  if (!queries) {
    return (
      <Skeleton
        variant="rectangular"
        height={400}
        style={{ marginBottom: 12, marginTop: 0 }}
      />
    );
  }

  return queries.length ? (
    <div style={{ paddingBottom: 80 }}>
      {queries.map((query, i) => {
        return (
          <TranscriptQueryCell
            key={i}
            query={query}
            style={{ marginBottom: 16 }}
          />
        );
      })}
    </div>
  ) : (
    <EmptyQuery />
  );
}
