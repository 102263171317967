import { Grid } from "@mui/material";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { post } from "jsx/Api";
import MSAnalytics from "jsx/api/analytics";
import { UserContext } from "jsx/context";
import { useContacts } from "jsx/hooks/useContacts";
import ParticipantsAutocomplete from "jsx/pages/Transcripts/ParticipantsAutocomplete";
import React, { useContext, useState } from "react";
import * as Yup from "yup";

export const transcriptFormSchema = Yup.object().shape({
  transcriptDetails: Yup.string().required(
    "Please complete this required field."
  ),
});

export function TranscriptSummaryForm({
  transcript,
  isGenerating,
  setGenerating,
  getTranscript,
}) {
  const db = getFirestore();
  const { workspace } = useContext(UserContext);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const { contacts, getContacts } = useContacts();
  const [participants, setParticipants] = useState(transcript?.participants);

  function handleTooltipOpen() {
    setTooltipOpen(true);
    setTimeout(() => {
      setTooltipOpen(false);
    }, 2000);
  }

  async function handleSubmit(values) {
    MSAnalytics.track("Button Clicked", {
      page: "Transcripts",
      buttonType: `Generate`,
    });

    setGenerating(true);
    const cc = await setDoc(
      doc(
        db,
        [
          "workspaces",
          workspace.id,
          "workspaces_private",
          "data",
          "transcripts",
          transcript.id,
        ].join("/")
      ),
      {
        participants: participants,
        transcriptDetails: values.transcriptDetails,
      },
      { merge: true }
    );

    const res = await post("transcripts/summarizeTranscript", {
      transcriptId: transcript.id,
    });

    setGenerating(false);
    getTranscript();
  }

  console.log("pp", participants);

  return (
    <div style={{ height: "100%" }}>
      <Formik
        enableReinitialize
        validationSchema={transcriptFormSchema}
        initialValues={{
          transcriptDetails: transcript?.transcriptDetails,
          participants,
        }}
        onSubmit={handleSubmit}
        style={{ height: "100%" }}
      >
        <Form
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <div
            style={{
              paddingRight: 24,
              paddingBottom: 90,
              marginLeft: 24,
            }}
          >
            <label htmlFor="transcriptDetails" className="form-label">
              Topics discussed on this call*
            </label>
            <Field
              id="transcriptDetails"
              name="transcriptDetails"
              placeholder="The purpose of the call was Acme pitching IBM."
              component="textarea"
              rows="3"
              className="form-textarea"
            />
            <ErrorMessage
              name="transcriptDetails"
              className="form-error"
              component="div"
            />

            <label
              htmlFor="transcriptDetails"
              className="form-label"
              style={{ marginBottom: 8 }}
            >
              Participants*
            </label>

            <ParticipantsAutocomplete
              participants={participants}
              contacts={contacts}
              onSelectedContacts={(v) => {
                setParticipants(v);
              }}
              onUpdateContact={() => {
                getContacts();
              }}
            />
            <ErrorMessage
              name="participants"
              className="form-error"
              component="div"
            />
          </div>
          <div
            style={{
              width: "100%",
              bottom: 0,
              position: "fixed",
            }}
          >
            <Grid container style={{ paddingRight: 271 }}>
              <Grid
                item
                lg={6}
                style={{
                  display: "flex",
                  background: "white",
                  borderTop: "1px solid #DDD",
                  zIndex: 99,
                  width: "100%",
                }}
              >
                <div style={{ flexGrow: 1 }} />
                <button
                  type="submit"
                  className={`cool-button ${isGenerating ? "animate" : ""}`}
                  style={{
                    marginTop: 16,
                    width: 130,
                    fontSize: 18,
                    marginBottom: 16,
                    marginRight: 16,
                  }}
                  disabled={isGenerating}
                >
                  {isGenerating ? "Generating" : "Generate"}
                </button>
              </Grid>
              <Grid item lg={6}></Grid>
            </Grid>
          </div>
        </Form>
      </Formik>
    </div>
  );
}
