import { CopyAllOutlined } from "@mui/icons-material";
import { Grid, IconButton, TextareaAutosize, Tooltip } from "@mui/material";
import { addDoc, collection, getDocs, getFirestore } from "firebase/firestore";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { eventSource, post } from "jsx/Api";
import MSAnalytics from "jsx/api/analytics";
import { UserContext } from "jsx/context";
import { uniqueArrayOfObjects } from "jsx/utils";
import _ from "lodash";
import React, { useCallback, useContext, useEffect, useState } from "react";

import * as Yup from "yup";
import TranscriptsAutocomplete from "../Draft/components/Transcripts/TranscriptsAutocomplete";

export const transcriptFormSchema = Yup.object().shape({
  query: Yup.string().required("Please complete this required field."),
});

export function TranscriptQueryForm({
  transcript,
  setCurrentQuery,
  getQueries,
}) {
  const db = getFirestore();
  const { workspace } = useContext(UserContext);
  const [isGeneratingQuery, setGeneratingQuery] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  function handleTooltipOpen() {
    setTooltipOpen(true);
    setTimeout(() => {
      setTooltipOpen(false);
    }, 2000);
  }

  async function handleSubmit(values) {
    MSAnalytics.track("Button Clicked", {
      page: "Transcripts",
      buttonType: `Generate`,
    });

    setGeneratingQuery(true);
    const doc = await addDoc(
      collection(
        db,
        [
          "workspaces",
          workspace.id,
          "workspaces_private",
          "data",
          "transcripts",
          transcript.id,
          "queries",
        ].join("/")
      ),
      { ...values }
    );

    let text = "";
    var source = await eventSource(
      `transcripts/queryTranscript/${doc.id}/${transcript.id}/${workspace.id}`
    );
    source.onmessage = function (e) {
      const data = JSON.parse(e.data);
      text = text.concat(data.choices[0].text);
      let response = text.trim();

      const newQuery = {
        response,
        createdAt: { seconds: Date.now() / 1000 },
        id: doc.id,
        query: values.query,
      };
      setCurrentQuery(newQuery);
    };

    source.addEventListener("close", function (e) {
      setGeneratingQuery(false);
      setTimeout(() => {
        // dumb but do it for now
        // Sometimes it loads before the server saves to firestore?
        getQueries();
      }, 500);
      console.log("close", e.data);
      source.close();

      //   post("workspaces/completeOnboardingStep", {
      //     onboardingStep: "generateDraft",
      //   });
    });
    saveLastInput(values);
  }

  function saveLastInput(values) {
    for (const [key, value] of Object.entries(values)) {
      console.log(key, value);
      localStorage.setItem(key, value);
    }
  }

  return (
    <div style={{ height: "100%" }}>
      <Formik
        enableReinitialize
        validationSchema={transcriptFormSchema}
        initialValues={{
          query: "",
        }}
        onSubmit={handleSubmit}
        style={{ height: "100%" }}
      >
        <Form
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <div
            style={{
              paddingRight: 24,
              paddingBottom: 90,
              marginLeft: 24,
            }}
          >
            <Grid container style={{ width: "100%" }}>
              <Grid item xs={12}>
                <label htmlFor="query" className="form-label">
                  What do you want to know about this transcript?*
                </label>
              </Grid>
              <Grid item xs={12}>
                <Field
                  placeholder="What were the main discussion points?"
                  id="query"
                  name="query"
                  type="query"
                  className="form-input"
                />
              </Grid>
              <ErrorMessage
                name="query"
                className="form-error"
                component="div"
              />

              <Grid item xs={12}>
                <label htmlFor="query" className="form-label">
                  Summary
                </label>
                <Tooltip
                  title={tooltipOpen ? "Copied" : "Copy"}
                  placement="top"
                >
                  <IconButton
                    aria-label="copy"
                    size="small"
                    className="small-icon-button"
                    placement="top"
                    onClick={() => {
                      navigator.clipboard.writeText(transcript.summary);
                      handleTooltipOpen();
                    }}
                  >
                    <CopyAllOutlined fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={12}>
                <TextareaAutosize
                  value={transcript?.summary.trim()}
                  id="query"
                  name="query"
                  type="query"
                  className="form-textarea"
                  component="textarea"
                  rows="3"
                  disabled
                  style={{ background: "white" }}
                />
              </Grid>
            </Grid>
          </div>
          <div
            style={{
              width: "100%",
              bottom: 0,
              position: "fixed",
            }}
          >
            <Grid container style={{ paddingRight: 271 }}>
              <Grid
                item
                lg={6}
                style={{
                  display: "flex",
                  background: "white",
                  borderTop: "1px solid #DDD",
                  zIndex: 99,
                  width: "100%",
                }}
              >
                <div style={{ flexGrow: 1 }} />
                <button
                  type="submit"
                  className={`cool-button ${
                    isGeneratingQuery ? "animate" : ""
                  }`}
                  style={{
                    marginTop: 16,
                    width: 130,
                    fontSize: 18,
                    marginBottom: 16,
                    marginRight: 16,
                  }}
                  disabled={isGeneratingQuery}
                >
                  {isGeneratingQuery ? "Generating" : "Generate"}
                </button>
              </Grid>
              <Grid item lg={6}></Grid>
            </Grid>
          </div>
        </Form>
      </Formik>
    </div>
  );
}
