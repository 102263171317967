import { MoveToInboxOutlined } from "@mui/icons-material";
import { Grid, LinearProgress, Paper } from "@mui/material";
import React from "react";

import { OnboardRow } from "..";

export function OnboardTable({ onboardingTasks }) {
  return (
    <Paper variant="outlined" style={{ marginTop: 24, paddingBottom: 0 }}>
      <Grid container style={{ flexWrap: "nowrap", padding: "16px 0" }}>
        <Grid item style={{ display: "flex" }}>
          <MoveToInboxOutlined
            style={{ height: 28, width: 28, color: "#999", marginLeft: 16 }}
          />
        </Grid>
        <Grid container style={{ alignItems: "center", padding: "0 16px " }}>
          <Grid
            item
            style={{ fontSize: 20, fontWeight: 600, marginBottom: "4px" }}
          >
            Reach more customers with Smart Campaigns
          </Grid>
          <div style={{ flexGrow: 1 }} />
          <div style={{ fontSize: 16 }}>5,000 credits available</div>
        </Grid>
      </Grid>

      <LinearProgress variant="determinate" value={0.5} color="green" />

      <OnboardRow title="Create an account" credits="-" complete={true} />
      <OnboardRow
        path="/draft"
        title="Write a draft email"
        credits="1,000"
        complete={onboardingTasks?.generateDraft}
      />
      <OnboardRow
        path="https://chrome.google.com/webstore/detail/modern-sales-ai/lbeckaghpcojalkiknleahiaelgemhkp"
        title="Write an email with the Chrome extension"
        credits="2,000"
        complete={onboardingTasks?.generateWithChromeExtension}
      />
      <OnboardRow
        path="/contacts/new"
        title="Add or import contacts"
        credits="1,000"
        complete={onboardingTasks?.createContact}
      />

      <OnboardRow
        path="/autopilot"
        title="Create an email campaign with autopilot"
        credits="2,000"
        complete={onboardingTasks?.sendWithAutopilot}
      />
    </Paper>
  );
}
