import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { Form, Formik } from "formik";
import { ConnectGmailButton } from "jsx/components";
import { UserContext } from "jsx/context";
import _ from "lodash";
import React, { useContext } from "react";

import { editAuthSenderFormSchema } from "./edit-auth-senders-form.schema";

export function AuthSendersForm({
  campaign,
  authedSenders,
  saveCampaign,
  getCampaign,
  getAuthedSenders,
  setAuthedSenders,
  setShowSenderSection,
}) {
  const db = getFirestore();
  const { workspace } = useContext(UserContext);

  const authedSender = authedSenders?.find(
    (e) => e.email === campaign?.authedSender
  );

  async function saveAuthedSender(email, values) {
    const q = await setDoc(
      doc(
        db,
        "workspaces",
        workspace.id,
        "workspaces_private",
        "data",
        "authed_senders",
        email
      ),
      values,
      { merge: true }
    );
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        firstName: authedSender?.given_name,
        lastName: authedSender?.family_name,
        authedSender: campaign?.authedSender,
        tone: authedSender?.tone,
        companyName: authedSender?.companyName,
        jobTitle: authedSender?.jobTitle,
      }}
      validationSchema={editAuthSenderFormSchema}
      onSubmit={async (values) => {
        const filtered = _(values)
          .omitBy(_.isEmpty)
          .omitBy(_.isUndefined)
          .omitBy(_.isNull)
          .value();

        await saveAuthedSender(values.authedSender, filtered);
        setShowSenderSection(false);
        await getAuthedSenders();
      }}
    >
      {({ handleChange, errors, values, isSubmitting }) => (
        <Form>
          <h5 style={{ marginBottom: 12 }}>Email *</h5>
          <div style={{ display: "flex", alignItems: "center" }}>
            <FormControl sx={{ minWidth: 120 }} size="small">
              <Select
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                onChange={async (e) => {
                  await saveCampaign({ authedSender: e.target.value });
                  await getCampaign();
                }}
                value={values.authedSender}
                name="authedSender"
              >
                {authedSenders.map((e, i) => (
                  <MenuItem value={e.email} key={i}>
                    {e.email}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <ConnectGmailButton
              add={authedSenders.length}
              style={{ marginLeft: 8 }}
              onSuccess={() => {
                setAuthedSenders(null);
                getAuthedSenders();
              }}
            />
          </div>
          <Grid container spacing={4}>
            <Grid md={6} item>
              <h5 style={{ marginBottom: 12 }}>First name</h5>
              <TextField
                size="small"
                multiline={true}
                placeholder="Jane"
                style={{ width: "100%" }}
                onChange={handleChange}
                value={values.firstName}
                name="firstName"
                disabled
              />
            </Grid>
            <Grid md={6} item>
              <h5 style={{ marginBottom: 12 }}>Last name</h5>
              <TextField
                size="small"
                multiline={true}
                placeholder="Doe"
                style={{ width: "100%" }}
                onChange={handleChange}
                value={values.lastName}
                name="lastName"
                disabled
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid md={6} item>
              <h5 style={{ marginBottom: 12 }}>Job title / Role</h5>
              <TextField
                style={{ width: "100%" }}
                size="small"
                multiline={true}
                placeholder="Sales representative"
                onChange={handleChange}
                value={values.jobTitle}
                name="jobTitle"
              />

              {/* <h5 style={{ marginBottom: 12 }}>Tone</h5>
              <TextField
                style={{ width: "100%" }}
                size="small"
                multiline={true}
                placeholder="Professional"
                onChange={handleChange}
                value={values.tone}
                name="tone"
              /> */}
            </Grid>
          </Grid>

          <div style={{ marginTop: 32 }}>
            <Button
              variant="contained"
              disableElevation
              type="submit"
              disabled={isSubmitting}
              style={{ marginRight: 12 }}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              disableElevation
              type="submit"
              disabled={isSubmitting}
              onClick={() => {
                setShowSenderSection(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
