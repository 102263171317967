import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { SideMenu } from "jsx/components";

import { IntegrationsList } from "./components";
import { integrationsData } from "./data";

export function Integrations() {
  return (
    <Grid sx={{ display: "flex" }}>
      <SideMenu />
      <Box
        className="container"
        sx={{ flexGrow: 1, justifyContent: "center", alignItems: "center" }}
      >
        <Box sx={{ height: 400, maxWidth: 1200, margin: "0 auto" }}>
          <Box
            sx={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h1>Integrations</h1>
            <div style={{ flexGrow: 1 }}></div>
          </Box>
          <IntegrationsList integrations={integrationsData} />
        </Box>
      </Box>
    </Grid>
  );
}
