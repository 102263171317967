import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import {
  collection,
  doc,
  getDocs,
  getFirestore,
  setDoc,
} from "firebase/firestore";
import { CustomCircularProgress, SideMenu } from "jsx/components";
import { UserContext } from "jsx/context";
import { useTranscript } from "jsx/hooks/useTranscript";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useContext } from "react";
import { useParams } from "react-router-dom";

import { TranscriptQueryForm } from "./TranscriptQueryForm";
import { TranscriptsQuerySection } from "./TranscriptsQuerySection";

export function TranscriptQuery() {
  const { transcript, getTranscript } = useTranscript();
  const [queries, setQueries] = useState();
  const db = getFirestore();
  const { user, workspace } = useContext(UserContext);
  const transcriptId = useParams()["transcriptId"];
  const [currentQuery, setCurrentQuery] = useState();
  const [editingTitle, setEditingTitle] = useState();

  useEffect(() => {
    getQueries();
  }, []);

  async function getQueries() {
    try {
      const q = await getDocs(
        collection(
          db,
          "workspaces",
          workspace.id,
          "workspaces_private",
          "data",
          "transcripts",
          transcriptId,
          "queries"
        )
      );

      let map = q.docs.map((e) => {
        return {
          id: e.id,
          ...e.data(),
        };
      });

      map = _.orderBy(map, ["createdAt"], ["desc"]);

      setQueries(map);
    } catch (e) {
      console.log("error fetching transcript", e);
    }
  }

  let content;
  let title;

  async function save() {
    const q = await setDoc(
      doc(
        db,
        "workspaces",
        workspace.id,
        "workspaces_private",
        "data",
        "transcripts",
        transcriptId
      ),
      { transcriptName: editingTitle },
      { merge: true }
    );
    await getTranscript();
    setEditingTitle();
  }

  if (editingTitle) {
    title = (
      <>
        <input
          autoFocus
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              save();
            }
          }}
          onBlur={async () => {
            save();
          }}
          defaultValue={editingTitle}
          onChange={(e) => {
            setEditingTitle(e.target.value);
          }}
          style={{
            marginBottom: 4,
            fontWeight: 500,
            fontSize: 22,
            marginTop: 16,
            fontFamily: "Roboto Slab",
          }}
        />
      </>
    );
  } else {
    title = (
      <>
        <h3 style={{ marginBottom: 4, marginTop: 0, fontWeight: 500 }}>
          {transcript?.transcriptName}
        </h3>
      </>
    );
  }

  if (transcript) {
    if (transcript.status === "done") {
      content = (
        <>
          <Grid
            container
            style={{
              flexGrow: 1,
              background: "#F9FAFC",
              height: "100%",
            }}
          >
            <Grid
              item
              md={12}
              lg={6}
              sx={{
                height: { lg: "calc(100vh - 16px)" },
                overflowY: { lg: "auto", md: "" },
                paddingTop: 0,
                paddingLeft: 0,
              }}
            >
              <div
                style={{
                  background: "white",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  padding: 24,
                  borderBottom: "1px solid #E9E9E9",
                  boxSizing: "border-box",
                }}
              >
                <div
                  style={{
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                >
                  <div
                    style={{
                      cursor: "pointer",
                      display: "inline-flex",
                    }}
                    onClick={() => {
                      setEditingTitle(transcript.transcriptName);
                    }}
                  >
                    {title}
                  </div>
                  <div
                    style={{ fontSize: 14, color: "#666", marginBottom: -8 }}
                  >
                    Ask questions about your transcripts.
                  </div>
                </div>
              </div>

              <TranscriptQueryForm
                transcript={transcript}
                setCurrentQuery={setCurrentQuery}
                getQueries={getQueries}
              />
            </Grid>
            <TranscriptsQuerySection
              queries={queries}
              currentQuery={currentQuery}
            />
          </Grid>
        </>
      );
    } else {
      content = (
        <>
          <Box
            className="container"
            sx={{ flexGrow: 1, justifyContent: "center", alignItems: "center" }}
          >
            <Box sx={{ height: 400, maxWidth: 700, margin: "0 auto" }}>
              <Box
                sx={{
                  flexDirection: "column",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "30vh",
                }}
              >
                <CustomCircularProgress size={80} />
                <h2>Transcribing...</h2>
                <div style={{ marginBottom: 32 }}>
                  This page will automatically refresh when the transcription is
                  complete
                </div>
              </Box>
            </Box>
          </Box>
        </>
      );
    }
  }

  return (
    <>
      <Grid container style={{ minHeight: "100vh" }}>
        <Box
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "start",
            alignItems: "start",
            minHeight: "100%",
          }}
        >
          <SideMenu />
          {content}
        </Box>
      </Grid>
    </>
  );
}
