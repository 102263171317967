import { Grid, Paper } from "@mui/material";
import { UserContext } from "jsx/context";
import { useContext, useEffect } from "react";

import { DemoContent } from "./components";

export function Demo() {
  const { user } = useContext(UserContext);

  return (
    <div
      style={{
        padding: 0,
        margin: 0,
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        direction: "column",
      }}
    >
      <div
        style={{
          padding: 0,
          margin: 0,
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          direction: "column",
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{
            background:
              "linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(37,15,0,1) 100%)",
          }}
        >
          <Grid
            item
            style={{
              marginTop: "5vh",
            }}
            xs={12}
          >
            <div
              style={{
                fontWeight: 600,
                letterSpacing: 0.6,
                fontSize: 12,
                color: "white",
                textAlign: "center",
                marginBottom: 16,
              }}
            >
              MODERN SALES DEMO
            </div>
            <div
              className="hero-text"
              style={{
                textAlign: "center",
                color: "white",
                maxWidth: "1200px",
                fontWeight: 500,
                lineHeight: 1.5,
                marginBottom: 96,
              }}
            >
              Ready to see AI in action? Get a demo of Modern Sales.
            </div>
          </Grid>
          <Paper
            style={{
              padding: 48,
              maxWidth: 500,
              marginBottom: -128,
            }}
            elevation={0}
            variant="outlined"
          >
            <DemoContent />
          </Paper>
        </Grid>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{
            background: "white",
          }}
        >
          <Grid item xs={12} style={{ marginTop: 90 }}>
            <div style={{ maxWidth: 600, marginTop: 96 }}>
              <div style={{ fontSize: 21, lineHeight: 1.3 }}>
                Modern Share is the AI Content Platform that helps you and your
                team break through creative blocks to create amazing, original
                content 10X faster.
                <br />
                <br />
                On this demo, you'll learn: <br />
                <ul>
                  <li style={{ marginBottom: 8 }}>
                    How the AI's brain works Clear
                  </li>
                  <li style={{ marginBottom: 8 }}>Three common use cases</li>
                  <li style={{ marginBottom: 8 }}>
                    Clear understanding of plans & pricing
                  </li>
                </ul>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
