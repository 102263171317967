import { Paper, Skeleton } from "@mui/material";
import React from "react";

export function ContactLoadingCell({ key, style }) {
  return (
    <>
      <div key={key} style={{ ...style }}>
        <Paper variant="outlined" style={{ padding: 24 }}>
          <div style={{ display: "flex", flexDirection: "Row" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
              }}
            >
              <Skeleton
                variant="rectangular"
                height={32}
                width={280}
                style={{ marginBottom: 12 }}
              />
              <Skeleton
                variant="rectangular"
                height={16}
                width={180}
                style={{ marginBottom: 20 }}
              />
            </div>

            <Skeleton
              variant="rectangular"
              height={50}
              style={{ marginBottom: 20 }}
            />
          </div>
          <Skeleton
            variant="rectangular"
            height={80}
            style={{ marginBottom: 20 }}
          />
          <Skeleton
            variant="rectangular"
            height={80}
            style={{ marginBottom: 20 }}
          />
          <Skeleton variant="rectangular" height={80} />
        </Paper>
      </div>
    </>
  );
}
