import { West } from "@mui/icons-material";
import {
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { Box } from "@mui/system";
import { Link, useNavigate } from "react-router-dom";

const CustomListItem = (props) => {
  return (
    <ListItem
      style={{
        padding: 0,
        height: 30,
        margin: 0,
        overflow: "hidden",
        borderRadius: 8,
      }}
      className={props.selected ? "selected" : ""}
    >
      {props.children}
    </ListItem>
  );
};

const CutomListItemText = ({ primary, selected }) => {
  return (
    <ListItemText
      primary={primary}
      primaryTypographyProps={{
        fontSize: "14px",
        color: "#555",
        fontWeight: window.location.href.includes(primary.toLowerCase())
          ? 600
          : 400,
        fontFamily: [
          "-apple-system",
          "BlinkMacSystemFont",
          '"Segoe UI"',
          "Roboto Slab",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
      }}
    />
  );
};

export function SettingsMenu() {
  const navigate = useNavigate();

  return (
    <div
      style={{
        width: "270px",
        flexShrink: 0,
      }}
    >
      <Grid
        item
        sx={{
          width: "270px",
          borderRight: "1px solid #e4e4e4",
          height: "100vh",
          position: "fixed",
          background: "#F9FAFC",
        }}
      >
        <Box sx={{ width: "270px" }} role="presentation">
          <div style={{ padding: 8 }}>
            <IconButton
              onClick={() => {
                navigate(`/dashboard` + document.location.search);
              }}
              size="small"
              style={{ margin: 10, marginTop: 32, marginBottom: 32 }}
            >
              <West fontSize="inherit" />
            </IconButton>
            <div style={{ marginLeft: 16, fontWeight: 500 }}>
              Workspace settings
            </div>

            <List>
              {/* <Link
                to="/settings/general"
                style={{ textDecoration: "none", color: "#241c15" }}
              >
                <CustomListItem
                  disablePadding
                  selected={window.location.href.includes("/general")}
                >
                  <ListItemButton
                    className={
                      window.location.href.includes("/general")
                        ? "sidemenu-item-selected"
                        : ""
                    }
                  >
                    <CutomListItemText
                      primary={"General"}
                      primaryTypographyProps={{ fontSize: "14px" }}
                    />
                  </ListItemButton>
                </CustomListItem>
              </Link> */}
              <Link
                to="/settings/team"
                style={{ textDecoration: "none", color: "#241c15" }}
              >
                <CustomListItem
                  disablePadding
                  selected={window.location.href.includes("/team")}
                >
                  <ListItemButton
                    className={
                      window.location.href.includes("/team")
                        ? "sidemenu-item-selected"
                        : ""
                    }
                  >
                    <CutomListItemText primary={"Team"} />
                  </ListItemButton>
                </CustomListItem>
              </Link>
              <Link
                to="/settings/usage"
                style={{ textDecoration: "none", color: "#241c15" }}
              >
                <CustomListItem
                  disablePadding
                  selected={window.location.href.includes("/usage")}
                >
                  <ListItemButton
                    className={
                      window.location.href.includes("/usage")
                        ? "sidemenu-item-selected"
                        : ""
                    }
                  >
                    <CutomListItemText primary={"Usage"} />
                  </ListItemButton>
                </CustomListItem>
              </Link>
              <Link
                to="/settings/billing"
                style={{ textDecoration: "none", color: "#241c15" }}
              >
                <CustomListItem
                  disablePadding
                  selected={window.location.href.includes("/billing")}
                >
                  <ListItemButton
                    className={
                      window.location.href.includes("/billing")
                        ? "sidemenu-item-selected"
                        : ""
                    }
                  >
                    <CutomListItemText primary={"Billing"} />
                  </ListItemButton>
                </CustomListItem>
              </Link>
            </List>
          </div>
        </Box>
      </Grid>
    </div>
  );
}
