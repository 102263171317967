import { Skeleton } from "@mui/material";
import React from "react";

import { DraftCell } from "../..";
import { EmptyDraft } from "../EmptyDraft/EmptyDraft";

export function DraftsHistory({ drafts }) {
  if (!drafts) {
    return (
      <Skeleton
        variant="rectangular"
        height={400}
        style={{ marginBottom: 12, marginTop: 48 }}
      />
    );
  }

  return drafts.length ? (
    <div style={{ paddingBottom: 80 }}>
      {drafts.map((draft, i) => {
        return (
          <DraftCell
            key={i}
            defaultDraft={draft}
            style={{ marginBottom: 16 }}
          />
        );
      })}
    </div>
  ) : (
    <EmptyDraft />
  );
}
