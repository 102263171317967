import { SearchOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Grid, Button, Alert, Tooltip, Box } from "@mui/material";
import { addDoc, collection, getFirestore, setDoc } from "firebase/firestore";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { get, post } from "jsx/Api";
import MSAnalytics from "jsx/api/analytics";
import { UserContext } from "jsx/context";
import { contactData } from "jsx/data/TestData";
import AddableContactAutocomplete from "jsx/pages/Draft/components/ColdEmailForm/AddableContactAutocomplete";
import { getFirstNameLastName, getFullNameFromContact } from "jsx/utils";
import _, { last } from "lodash";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { contactFormSchema } from "./contact-form.schema";

const leadCompanyNamePH = "Microsoft";
const websitePH = "https://www.linkedin.com/in/ryanroslansky/";

export function ContactForm({
  isGenerating,
  setGenerating,
  currentContact,
  onHydratedData,
  contacts,
  setContact,
}) {
  const db = getFirestore();
  const { workspace, isLocal } = useContext(UserContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState();

  const initialValues = {
    leadCompanyName: "",
    website: currentContact?.website ?? "",
  };

  async function handleSubmit(values) {
    setGenerating(true);
    MSAnalytics.track("Button Clicked", {
      page: "Contacts",
      buttonType: `Generate`,
    });

    const website = values.website;

    setLoading(true);
    setShowError(false);

    if (website) {
      const response = await get("leads/summarizeUserForWebsite", {
        website,
      });

      const data = response.data;

      try {
        if (!data.firstName) {
          data.firstName = currentContact.firstName;
        }

        if (!data.lastName) {
          data.lastName = currentContact.lastName;
        }

        if (!data.website) {
          data.website = website;
        }
      } catch (e) {
        setShowError(true);
        setGenerating(false);
      }
      onHydratedData(data);
      setLoading(false);
      setShowError(false);
      setGenerating(false);
    }
  }

  return (
    <Box sx={{ paddingBottom: "40px" }}>
      {showError && (
        <>
          <Alert severity="error">
            Something went wrong when trying to find this person. Try again or
            try a different website.
          </Alert>
        </>
      )}

      {/* {loading && (
        <Alert severity="info">{`Looking up the person. This may take a minute.`}</Alert>
      )} */}

      <Formik
        validationSchema={contactFormSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ resetForm, values, errors }) => {
          console.log("errors", errors);
          return (
            <>
              <Form
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div>
                  <Grid
                    container
                    style={{ width: "100%", paddingLeft: 24, paddingRight: 24 }}
                  >
                    <Grid item xs={12}>
                      <Grid container style={{ width: "100%" }}>
                        <Grid item xs={12}>
                          <label htmlFor="fullName" className="form-label">
                            Full name*
                          </label>

                          <AddableContactAutocomplete
                            fullnameInput={true}
                            contacts={contacts ?? []}
                            style={{
                              background: "white",
                              width: "100%",
                              marginTop: "8px",
                            }}
                            onAddedNewContact={(contact) => {
                              setContact(contact);

                              if (!contact) {
                                return;
                              }
                            }}
                            onSelectedContact={(contact) => {
                              setContact(contact);
                              if (!contact) {
                                return;
                              }
                              localStorage.setItem(
                                "contactIdContactForm",
                                contact.id
                              );
                            }}
                            selectedContactId={
                              currentContact?.id ??
                              localStorage.getItem("contactIdContactForm")
                            }
                          />
                        </Grid>

                        {/* <Grid item xs={12} md={12}>
                    <div>
                      <label htmlFor="leadCompanyName" className="form-label">
                        Their company
                      </label>
                      <Field
                        id="leadCompanyName"
                        name="leadCompanyName"
                        placeholder={leadCompanyNamePH}
                        className="form-input"
                      />
                      <ErrorMessage
                        name="leadCompanyName"
                        className="form-error"
                        component="div"
                      />
                    </div>
                  </Grid> */}

                        <Grid item xs={12} md={12}>
                          <div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "end",
                                alignContent: "end",
                              }}
                            >
                              <label
                                htmlFor="leadCompanyName"
                                className="form-label"
                              >
                                Website*
                                <span style={{ fontWeight: 500 }}>
                                  {" "}
                                  (LinkedIn, company website, wikipedia,
                                  crunchbase, etc)
                                </span>
                              </label>
                              <Tooltip
                                title="If you provide a website, we'll try to lookup the
                        Contact from the website and auto fill information."
                              >
                                <SearchOutlined
                                  fontSize="small"
                                  style={{ color: "#999", marginLeft: 4 }}
                                />
                              </Tooltip>
                            </div>
                            <Field
                              id="website"
                              name="website"
                              placeholder={websitePH}
                              className="form-input"
                            />

                            <ErrorMessage
                              name="website"
                              className="form-error"
                              component="div"
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
                {/* <div style={{ marginTop: 32, display: "flex" }}>
            <div style={{ flexGrow: 1 }} />
            <Button
              size="large"
              variant="contained"
              disableElevation
              color="secondary"
              style={{ marginRight: 16 }}
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancel
            </Button>

            <LoadingButton
              size="large"
              variant="contained"
              disableElevation
              type="submit"
              loading={loading}
            >
              Submit
            </LoadingButton>
          </div> */}

                <div
                  style={{
                    width: "100%",
                    bottom: 0,
                    position: "fixed",
                  }}
                >
                  <Grid container style={{ paddingRight: 271 }}>
                    <Grid
                      item
                      lg={6}
                      style={{
                        display: "flex",
                        background: "white",
                        borderTop: "1px solid #DDD",
                        zIndex: 99,
                        width: "100%",
                      }}
                    >
                      <div style={{ flexGrow: 1 }} />
                      <button
                        type="submit"
                        className={`cool-button ${
                          isGenerating ? "animate" : ""
                        }`}
                        style={{
                          marginTop: 16,
                          width: 130,
                          fontSize: 18,
                          marginBottom: 16,
                          marginRight: 16,
                        }}
                        disabled={isGenerating}
                      >
                        {isGenerating ? "Generating" : "Generate"}
                      </button>
                    </Grid>
                    <Grid item lg={6}></Grid>
                  </Grid>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </Box>
  );
}
