import { Button, Grid } from "@mui/material";
import { UserContext } from "jsx/context";
import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";

export function AdminUnauthorizedPage() {
  const { user } = useContext(UserContext);

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <div
          style={{
            marginTop: "20vh",
            textAlign: "center",
          }}
        >
          <div className="hero-text">Unauthorized</div>
          <p>You're not authorized to view this page.</p>
        </div>
        <Link
          to="https://www.modernsales.ai"
          style={{ textDecoration: "none" }}
        >
          <Button size="large" variant="contained" disableElevation={true}>
            Go back to home
          </Button>
        </Link>
      </Grid>
    </>
  );
}
