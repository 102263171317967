import { LogoutOutlined } from "@mui/icons-material";
import { Button, FormControl, Grid, MenuItem, Select } from "@mui/material";
import {
  addDoc,
  collection,
  getDocs,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { getBaseUrl, post } from "jsx/Api";
import MSAnalytics from "jsx/api/analytics";
import { CustomCircularProgress } from "jsx/components";
import { UserContext } from "jsx/context";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { PricingBossModeCard, PricingBusinessCard } from "./components";
import styles from "./Pricing.module.scss";

export function Pricing() {
  const { user, isLocal, logout } = useContext(UserContext);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const db = getFirestore();

  const [billingPlans, setBillingPlans] = useState();
  const [billingPlan, setBillingPlan] = useState({});
  const [selectedPlanId, setSelectedPlanId] = useState("");

  const navigate = useNavigate();

  document.body.style = "background: #F9FAFC;";

  useEffect(() => {
    getProducts();
  }, []);

  const onSelectedPlanIdChange = (e) => {
    const billingPlan = billingPlans.filter(
      (plan) => plan.id === e.target.value
    )?.[0];

    MSAnalytics.track("Selection Changed", {
      page: "Pricing",
      selectionType: "Billing Plan Dropdown",
      billingPlan: billingPlan.name,
    });

    setSelectedPlanId(e.target.value);
    setBillingPlan(billingPlan);
  };

  const getProducts = async () => {
    try {
      const q = query(collection(db, "products"), where("active", "==", true));
      const qSnap = await getDocs(q);
      let billings = qSnap.docs.map((d) => ({ id: d.id, ...d.data() }));
      const promises = [];

      qSnap.forEach(async (doc) => {
        async function callback() {
          const priceSnap = await getDocs(
            query(
              collection(doc.ref, "prices"),
              where("active", "==", true),
              orderBy("unit_amount", "asc")
            )
          );
          const pricesArr = priceSnap.docs.map((d) => ({
            id: d.id,
            ...d.data(),
          }));

          billings.forEach((bill, i) => {
            if (bill.id === doc.id) {
              billings[i].prices = pricesArr;
            }
          });
        }

        promises.push(callback());
      });

      if (!billings && isLocal) {
        billings = [];
      }

      await Promise.all(promises);

      // annual by default
      const annualBilling = billings.find((e) => e.name === "Annually");

      setBillingPlan(annualBilling);
      setSelectedPlanId(annualBilling?.id || "");
      setBillingPlans(billings);
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmitSubscribe = async (priceId, price) => {
    if (isLocal) {
      const redirectUrl = `/dashboard?checkoutSuccess=true&planSize=${50000}&price=${89.99}&billingPlan=${"monthly"}&priceId=${"fjwojfeifjwoij"}`;

      await post("workspaces/debugAddSubscription");

      window.location.href = redirectUrl;
      navigate(redirectUrl);
      return;
    }

    setLoadingSubmit(true);
    const docRef = await addDoc(
      collection(db, "users", user.uid, "checkout_sessions"),
      {
        price: priceId,
        success_url: `${getBaseUrl()}/dashboard?checkoutSuccess=true&planSize=${
          price?.transform_quantity?.divide_by
        }&price=${price?.unit_amount / 100}&billingPlan=${
          price?.interval
        }&priceId=${priceId}`,
        cancel_url: `${getBaseUrl()}/pricing`,
        trial_from_plan: true,
      }
    );

    const unsub = onSnapshot(docRef, (snap) => {
      console.log("Current data: ", snap.data());
      const { sessionId, url } = snap.data();
      if (sessionId) {
        console.log("url", url);
        // We have a session, let's redirect to Checkout
        window.location.assign(url);
        unsub();
        setLoadingSubmit(false);
      }
    });
  };

  let content;

  if (!billingPlans) {
    content = (
      <div style={{ top: "40vh", position: "absolute", left: "50vw" }}>
        <CustomCircularProgress size={60} />
      </div>
    );
  } else {
    content = (
      <>
        <div style={{ display: "flex" }}>
          <div style={{ flexGrow: 1 }}></div>
          <Button
            size="small"
            variant="outlined"
            style={{ margin: 16 }}
            color="gray"
            disableElevation={true}
            onClick={() => {
              MSAnalytics.track("Button Clicked", {
                page: "Pricing",
                buttonType: "Sign out",
              });

              logout();
              navigate("/login");
            }}
            endIcon={<LogoutOutlined />}
          >
            Sign out
          </Button>
        </div>

        <Grid
          className={styles.container}
          container
          direction="column"
          alignItems="center"
          marginX="auto"
        >
          <div className={styles.top}>
            <div className={styles.title}>Try it free for 5 days</div>
          </div>
          <div className={styles.select}>
            <div className={styles.label}>
              Billing: <b>Save</b> 30% with annual
            </div>
            <FormControl className={styles.dropdown}>
              <Select
                size="small"
                id="select-billing"
                value={selectedPlanId}
                onChange={onSelectedPlanIdChange}
                style={{ background: "white" }}
              >
                {billingPlans.map((plan, i) => (
                  <MenuItem value={plan.id} key={i}>
                    {plan.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <Grid className={styles.cards} container justifyContent="center">
            <PricingBossModeCard
              billingPlan={billingPlan}
              selectedPlanId={selectedPlanId}
              onPlanSubmit={onSubmitSubscribe}
              loading={loadingSubmit}
            />
            <PricingBusinessCard />
          </Grid>
          <div className={styles.verification}>
            We will run an authorization to verify your card - this charge will
            be dropped after 2-3 business days
          </div>
        </Grid>
      </>
    );
  }

  return content;
}
