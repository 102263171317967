import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { collection, getDocs, getFirestore } from "firebase/firestore";
import { SideMenu } from "jsx/components";
import { UserContext } from "jsx/context";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { useContact } from "jsx/hooks";

import { ContactForm, ContactsSection } from "./components";
import { useContacts } from "jsx/hooks/useContacts";

export function ContactsGen() {
  const db = getFirestore();
  const { workspace } = useContext(UserContext);

  const [isGenerating, setGenerating] = useState(false);
  const { contact, setContact } = useContact();
  const { contacts, getContacts } = useContacts();
  const [newContact, setNewContact] = useState(false);

  const [showError, setShowError] = useState();

  return (
    <>
      <Grid container style={{ minHeight: "100vh" }}>
        <Box
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "start",
            alignItems: "start",
            minHeight: "100%",
          }}
        >
          <SideMenu />
          <Grid
            container
            style={{
              flexGrow: 1,
              background: "#F9FAFC",
              height: "100%",
            }}
          >
            <Grid
              item
              md={12}
              lg={6}
              sx={{
                height: { lg: "calc(100vh - 16px)" },
                overflowY: { lg: "auto", md: "" },
                paddingTop: 0,
                paddingLeft: 0,
              }}
            >
              <div
                style={{
                  background: "white",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  padding: 24,
                  borderBottom: "1px solid #E9E9E9",
                  boxSizing: "border-box",
                }}
              >
                <h3 style={{ marginBottom: 4, fontWeight: 500, marginTop: 0 }}>
                  Contacts
                </h3>
                <div style={{ fontSize: 14, color: "#666", marginBottom: -8 }}>
                  Find out information on a person and summarize their career.
                </div>
              </div>
              <ContactForm
                isGenerating={isGenerating}
                currentContact={contact}
                setGenerating={setGenerating}
                setShowError={setShowError}
                getContacts={getContacts}
                contacts={contacts}
                onHydratedData={(data) => {
                  const newContact = Object.assign({}, contact, data);
                  setNewContact(newContact);
                }}
                setContact={setContact}
              />
            </Grid>
            <ContactsSection
              currentContact={contact}
              contacts={contacts}
              showError={showError}
              isGenerating={isGenerating}
              getContacts={getContacts}
              onUpdate={() => {
                getContacts();
              }}
              newContact={newContact}
            />
          </Grid>
        </Box>
      </Grid>
    </>
  );
}
