import { useState } from "react";

import { DemoForFree, EnterpriseForm, TeamSizeSelection } from "..";

export function DemoContent() {
  const [showDemoVideo, setShowDemoVideo] = useState(false);
  const [showEnterpriseForm, setShowEnterpriseForm] = useState(false);

  if (showDemoVideo) {
    return <DemoForFree />;
  }
  if (showEnterpriseForm) {
    return <EnterpriseForm />;
  }

  return (
    <TeamSizeSelection
      setShowDemoVideo={setShowDemoVideo}
      setShowEnterpriseForm={setShowEnterpriseForm}
    />
  );
}
