import { Skeleton } from "@mui/material";
import React from "react";

import { AdminWorkspaceItem } from "./AdminWorkspaceItem/AdminWorkspaceItem";

export function AdminWorkspaces({ workspaces, updateWorkspace }) {
  if (!workspaces) {
    return (
      <Skeleton variant="rectangular" height={400} style={{ marginTop: 32 }} />
    );
  }

  if (!workspaces.length) {
    return <h2>No workspaces.</h2>;
  }

  return workspaces.map((workspace, i) => (
    <AdminWorkspaceItem
      key={i}
      workspace={workspace}
      updateWorkspace={updateWorkspace}
    />
  ));
}
