import GoogleIcon from "@mui/icons-material/Google";
import { LoadingButton } from "@mui/lab";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import {
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { Form, Formik } from "formik";
import MSAnalytics from "jsx/api/analytics";
import { logAnalytics } from "jsx/Logging";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { UserContext } from "../../../context/UserContext";
import GoogleButton from "react-google-button";

export function CreateAccountForm({ title, subtitle, isSignin, redirectUrl }) {
  const [errorMessage, setErrorMessage] = useState();
  const [signin, setIsSignIn] = useState(isSignin);
  const { user } = useContext(UserContext);

  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  const [loading, setIsLoading] = useState(false);
  const [showSignUpWithEmail, setShowSignUpWithEmail] = useState(true);

  const SignUpSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string().required("Required"),
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
  });

  const SignInSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string().required("Required"),
  });
  const navigate = useNavigate();

  const signInWithGoogle = (e) => {
    MSAnalytics.track("Button Clicked", {
      page: "Signup",
      buttonType: "Sign up with Google",
    });

    signInWithPopup(auth, provider)
      .then(async (result) => {
        logAnalytics("signin_complete", {
          type: "email_password",
        });
        console.log("result", result);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
      } else {
        // User is signed out
        // ...
      }
    });

    logAnalytics("viewed_signin", {});
  }, []);

  const db = getFirestore();

  function constructQueryParmas() {
    const searchParams = new URLSearchParams();
    if (redirectUrl) {
      searchParams.append("redirectUrl", redirectUrl);
    }

    return searchParams.toString();
  }

  useEffect(() => {
    if (user.uid) {
      navigate(`/onboarding` + document.location.search, { replace: true });
    }
  }, [user]);

  function signUpWithEmailSection(formik) {
    return (
      <>
        <Form>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item container sx={{ mb: 2 }}>
              <Grid item xs={6}>
                <TextField
                  type="text"
                  name="firstName"
                  placeholder="Jane"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  label="First name"
                  sx={{ width: "94%", background: "white" }}
                  size="small"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="text"
                  name="lastName"
                  placeholder="Doe"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  label="Last name"
                  sx={{ width: "100%", background: "white" }}
                  size="small"
                />
              </Grid>
            </Grid>

            <Grid item xs sx={{ width: "100%", mb: 2 }}>
              <TextField
                type="text"
                name="email"
                placeholder="email@domain.com"
                value={formik.values.email}
                onChange={formik.handleChange}
                label="Email"
                sx={{ width: "100%", background: "white" }}
                size="small"
              />
            </Grid>
            <Grid item xs sx={{ width: "100%", mb: 2 }}>
              <TextField
                type="password"
                name="password"
                placeholder="Password"
                value={formik.values.password}
                onChange={formik.handleChange}
                label="Password"
                sx={{ width: "100%", background: "white" }}
                size="small"
              />
            </Grid>

            <Grid item xs sx={{ width: "100%", mb: 2 }}>
              <LoadingButton
                className="me-2 w-100 mt-3 mb-0"
                variant="outlined"
                type="submit"
                sx={{ width: "100%", height: 50 }}
                size="large"
                style={{ textTransform: "none" }}
                loading={loading}
              >
                {signin ? "Log in with email" : "Sign up with email"}
              </LoadingButton>
            </Grid>
            <Grid item xs>
              {/* {signin && (
                <Typography
                  className="mb-0 mt-3 small text-center"
                  color="#AAA"
                  fontSize={12}
                >
                  Don't have an account?{" "}
                  <span
                    onClick={() => {
                      setIsSignIn(false);
                    }}
                  >
                    <span
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                    >
                      Sign up
                    </span>
                  </span>
                </Typography>
              )}

              {!signin && (
                <Typography
                  textAlign="center"
                  className="mb-0 mt-3 small text-center"
                  color="#AAA"
                  fontSize={12}
                >
                  Already have an account?{" "}
                  <span
                    onClick={() => {
                      setIsSignIn(true);
                    }}
                  >
                    <span
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                    >
                      Log in
                    </span>
                  </span>
                </Typography>
              )} */}
            </Grid>

            {errorMessage && (
              <Grid item xs>
                <Typography
                  textAlign="center"
                  className="mb-0 mt-3 small is-invalid"
                >
                  {errorMessage}
                </Typography>
              </Grid>
            )}

            {/* <Grid item xs>
              <Link to={"/reset?" + constructQueryParmas()}>
                <Typography
                  className="mb-0 mt-3 small text-center"
                  style={{ textDecoration: "underline" }}
                >
                  Forgot your password?
                </Typography>
              </Link>
            </Grid> */}
          </Grid>
        </Form>
      </>
    );
  }

  return (
    <Grid container style={{ minHeight: "100vh" }}>
      <Box
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "start",
          alignItems: "start",
          minHeight: "100%",
        }}
      >
        <Box sx={{ height: 400, maxWidth: 700, margin: "0 auto", p: 4 }}>
          <Grid>
            <div className="logo-icon" style={{ marginBottom: 16 }} />

            <Typography
              variant="h5"
              style={{ fontSize: 32, fontWeight: 500, color: "#444" }}
            >
              {title ?? signin ? "Login" : "Create your account"}
            </Typography>
            <Typography
              variant="body2"
              sx={{ py: 2, color: "#999", fontWeight: 300 }}
            >
              {subtitle ?? signin
                ? "Login to your account"
                : "You'll be up & running in 2 minutes."}
            </Typography>

            <Grid item xs>
              <GoogleButton
                onClick={signInWithGoogle}
                startIcon={<GoogleIcon />}
                variant="contained"
                disableElevation
                size="large"
                style={{
                  textTransform: "none",
                  width: "100%",
                  height: 50,
                  borderRadius: "2px",
                  fontWeight: 600,
                }}
                label={signin ? "Login with Google" : "Sign up with Google"}
              ></GoogleButton>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{ py: 3 }}
            style={{ flexDirection: "row", display: "flex" }}
          >
            <div
              style={{
                height: "1px",
                flexGrow: 1,
                background: "#EEE",
                marginTop: 14,
              }}
            ></div>
            <Typography
              color={"#999"}
              style={{ paddingLeft: 16, paddingRight: 16, fontSize: 13 }}
            >
              Or register with your email
            </Typography>
            <div
              style={{
                height: "1px",
                flexGrow: 1,
                background: "#EEE",
                marginTop: 14,
              }}
            ></div>
          </Grid>
          <Grid item xs>
            <Formik
              initialValues={{ fullName: "", email: "", password: "" }}
              validationSchema={signin ? SignInSchema : SignUpSchema}
              onSubmit={async (values) => {
                MSAnalytics.track("Button Clicked", {
                  page: "Signup",
                  buttonType: "Sign up with email",
                });

                const auth = getAuth();
                const email = values.email;
                const password = values.password;
                const fullName = values.fullName;
                setIsLoading(true);
                setErrorMessage();

                if (signin) {
                  signInWithEmailAndPassword(auth, email, password)
                    .then((userCredential) => {
                      logAnalytics("signin_complete", {
                        type: "email_password",
                      });
                    })
                    .catch((error) => {
                      const errorCode = error.code;
                      /* const errorMessage = error.message; */

                      if (errorCode === "auth/user-not-found") {
                        setErrorMessage("No user with that email.");
                      } else if (errorCode === "auth/wrong-password") {
                        setErrorMessage("Wrong password");
                      }

                      setIsLoading(false);
                    });

                  return;
                }

                createUserWithEmailAndPassword(auth, email, password)
                  .then(async (userCredential) => {
                    logAnalytics("signup_complete", {
                      type: "email_password",
                    });

                    // Signed in
                    // const user = userCredential.user;
                    await setDoc(
                      doc(
                        db,
                        "users",
                        auth.currentUser.uid,
                        "users_private",
                        "data"
                      ),
                      { displayName: fullName },
                      { merge: true }
                    );
                    await sendEmailVerification(auth.currentUser).then(
                      () => {}
                    );
                  })
                  .catch((error) => {
                    setIsLoading(false);
                    const errorCode = error.code;
                    /* const errorMessage = error.message; */
                    if (error.code === "auth/email-already-in-use") {
                      signInWithEmailAndPassword(auth, email, password)
                        .then((userCredential) => {
                          // Signed in
                          const user = userCredential.user;
                        })
                        .catch((error) => {
                          const errorCode = error.code;
                          /* const errorMessage = error.message; */

                          if (errorCode === "auth/wrong-password") {
                            setErrorMessage("Wrong password.");
                          }
                        });
                    }
                    if (errorCode === "auth/weak-password") {
                      setErrorMessage("Password is too weak.");
                    }
                    console.log(error.code);
                  });
              }}
            >
              {(formik) => (
                <>
                  {!showSignUpWithEmail && (
                    <div
                      className="mt-1 small text-center"
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        marginBottom: -16,
                      }}
                      onClick={() => {
                        setShowSignUpWithEmail(true);
                      }}
                    >
                      {signin && "Or log in with email"}
                      {!signin && "Or sign up with email"}
                    </div>
                  )}
                  {showSignUpWithEmail && signUpWithEmailSection(formik)}
                </>
              )}
            </Formik>
          </Grid>
          <div style={{ textAlign: "center", fontSize: 14, color: "#999" }}>
            Already have an account?{" "}
            <Link
              to="/login"
              onClick={() => {
                MSAnalytics.track("Button Clicked", {
                  page: "Signup",
                  buttonType: "Already have an account?",
                });
              }}
            >
              <span>Login</span>
            </Link>
          </div>
        </Box>
      </Box>
    </Grid>
  );
}
