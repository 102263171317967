import { Button, FormHelperText, Grid, TextField } from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";

export function EditFlowForm({
  conversation,
  saveCampaign,
  getCampaign,
  setShowFlowSection,
}) {
  return (
    <Formik
      enableReinitialize
      initialValues={{
        first: conversation?.goal ?? "",
        prompt: conversation?.prompt ?? "",
      }}
      // validationSchema={CampaignForm}
      onSubmit={async (values) => {
        saveCampaign({ flow: true });
        getCampaign();
        setShowFlowSection(false);
      }}
    >
      {({ handleChange, errors, values, isSubmitting }) => (
        <Form>
          <Grid
            container
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "start",
              alignContent: "start",
              marginBottom: 32,
            }}
            xs={10}
          >
            <div style={{ display: "flex" }}>
              <p style={{ fontSize: 15, fontWeight: 500 }}>
                If no response to initial outreach, then send a follow up email
                after
                <TextField
                  size="small"
                  multiline={true}
                  placeholder="5"
                  style={{
                    width: "50px",
                    marginTop: -16,
                    marginLeft: 8,
                    marginRight: 8,
                  }}
                  // onChange={handleChange}
                  // value={values.goal}
                  // name="goal"
                />
                days
              </p>
              {errors.goal && <FormHelperText error>Required</FormHelperText>}
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ fontSize: 15, marginLeft: 64 }}>
                Then send a follow up email again after
                <TextField
                  size="small"
                  multiline={true}
                  placeholder="5"
                  style={{
                    width: "50px",
                    marginTop: -16,
                    marginLeft: 8,
                    marginRight: 8,
                  }}
                  // onChange={handleChange}
                  // value={values.goal}
                  // name="goal"
                />
                days
              </p>
              {errors.goal && <FormHelperText error>Required</FormHelperText>}
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ fontSize: 15, marginLeft: 64 }}>
                Then send a follow up email again after
                <TextField
                  size="small"
                  multiline={true}
                  placeholder="5"
                  style={{
                    width: "50px",
                    marginTop: -16,
                    marginLeft: 8,
                    marginRight: 8,
                  }}
                  // onChange={handleChange}
                  // value={values.goal}
                  // name="goal"
                />
                days
              </p>
              {errors.goal && <FormHelperText error>Required</FormHelperText>}
            </div>
          </Grid>

          <Grid
            container
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "start",
              alignContent: "start",
            }}
            xs={10}
          >
            <div style={{ display: "flex" }}>
              <p style={{ fontSize: 15, fontWeight: 500 }}>
                If no response to follow up email (e.g., the lead has responded
                at least one time), then send a follow up email after
                <TextField
                  size="small"
                  multiline={true}
                  placeholder="5"
                  style={{
                    width: "50px",
                    marginTop: -16,
                    marginLeft: 8,
                    marginRight: 8,
                  }}
                  // onChange={handleChange}
                  // value={values.goal}
                  // name="goal"
                />
                days
              </p>
              {errors.goal && <FormHelperText error>Required</FormHelperText>}
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ fontSize: 15, marginLeft: 64 }}>
                Then send a follow up email again after
                <TextField
                  size="small"
                  multiline={true}
                  placeholder="5"
                  style={{
                    width: "50px",
                    marginTop: -16,
                    marginLeft: 8,
                    marginRight: 8,
                  }}
                  // onChange={handleChange}
                  // value={values.goal}
                  // name="goal"
                />
                days
              </p>
              {errors.goal && <FormHelperText error>Required</FormHelperText>}
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ fontSize: 15, marginLeft: 64 }}>
                Then send a follow up email again after
                <TextField
                  size="small"
                  multiline={true}
                  placeholder="5"
                  style={{
                    width: "50px",
                    marginTop: -16,
                    marginLeft: 8,
                    marginRight: 8,
                  }}
                  // onChange={handleChange}
                  // value={values.goal}
                  // name="goal"
                />
                days
              </p>
              {errors.goal && <FormHelperText error>Required</FormHelperText>}
            </div>
          </Grid>

          <div style={{ marginTop: 32 }}>
            <Button
              variant="contained"
              disableElevation
              type="submit"
              disabled={isSubmitting}
              style={{ marginRight: 12 }}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              disableElevation
              type="submit"
              disabled={isSubmitting}
              onClick={() => {
                setShowFlowSection(false);
              }}
            >
              Cancel
            </Button>
            {/* <div
              style={{
                cursor: "pointer",
                marginTop: 24,
                fontSize: 14,
                color: "#999",
                float: "right",
                marginBottom: 32,
              }}
              onClick={() => {
                setShowAdvanced(!showAdvanced);
              }}
            >
              {showAdvanced ? "Hide" : "Show"} advanced
            </div> */}
          </div>
        </Form>
      )}
    </Formik>
  );
}
