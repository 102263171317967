import { Grid } from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";

import { enterpriseFormSchema } from "./enterprise-form.schema";

export function EnterpriseForm() {
  async function handleSubmit(values) {
    await new Promise((r) => setTimeout(r, 500));
    alert(JSON.stringify(values, null, 2));
  }

  return (
    <Formik
      validationSchema={enterpriseFormSchema}
      initialValues={{
        businessEmail: "",
        firstName: "",
        lastName: "",
        companyName: "",
        companySize: "",
        howDidYouHear: "",
        whatInterestsYou: "",
        emailOptedIn: "",
      }}
      onSubmit={handleSubmit}
    >
      <Form>
        <Grid container style={{ width: "100%" }}>
          <h2
            style={{
              textAlign: "center",
              marginTop: "0px",
              fontWeight: 500,
              marginBottom: 32,
            }}
          >
            Fill out this form to request a demo of Modern Sales
          </h2>
          <Grid item xs={12}>
            <label htmlFor="businessEmail" className="form-label">
              Business email*
            </label>
          </Grid>
          <Grid item xs={12}>
            <Field
              id="businessEmail"
              name="businessEmail"
              placeholder="jane@acme.com"
              type="email"
              className="form-input"
            />
            <ErrorMessage
              name="businessEmail"
              className="form-error"
              component="div"
            />
          </Grid>
          <Grid container style={{ width: "100%" }}>
            <Grid item xs={12} md={6}>
              <div style={{ marginRight: 16 }}>
                <label htmlFor="firstName" className="form-label">
                  First Name*
                </label>
                <Field
                  id="firstName"
                  name="firstName"
                  placeholder="Jane"
                  className="form-input"
                />
                <ErrorMessage
                  name="firstName"
                  className="form-error"
                  component="div"
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div style={{ marginLeft: 16 }}>
                <label htmlFor="lastName" className="form-label">
                  Last Name*
                </label>
                <Field
                  id="lastName"
                  name="lastName"
                  placeholder="Doe"
                  className="form-input"
                />
                <ErrorMessage
                  name="lastName"
                  className="form-error"
                  component="div"
                />
              </div>
            </Grid>
          </Grid>
          <Grid container style={{ width: "100%" }}>
            <Grid item xs={12} md={6}>
              <div style={{ marginRight: 16 }}>
                <label htmlFor="companyName" className="form-label">
                  Company Name*
                </label>
                <Field
                  id="companyName"
                  name="companyName"
                  placeholder="Acme"
                  className="form-input"
                />
                <ErrorMessage
                  name="companyName"
                  className="form-error"
                  component="div"
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div style={{ marginLeft: 16 }}>
                <label htmlFor="companySize" className="form-label">
                  Select Company Size*
                </label>
                <Field as="select" id="companySize" name="companySize">
                  <option value="1-10">1-10</option>
                  <option value="11-50">11-50</option>
                  <option value="51-200">51-200</option>
                  <option value="201-1000">201-1000</option>
                  <option value="+1000">+1000</option>
                </Field>
                <ErrorMessage
                  name="companySize"
                  className="form-error"
                  component="div"
                />
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}></Grid>
          <Grid item xs={12}>
            <label htmlFor="howDidYouHear" className="form-label">
              How did you hear about us?
            </label>
          </Grid>
          <Grid item xs={12}>
            <Field
              id="howDidYouHear"
              name="howDidYouHear"
              type="howDidYouHear"
              component="textarea"
              rows="3"
              className="form-textarea"
            />
          </Grid>
          <Grid item xs={12}>
            <label htmlFor="whatInterestsYou" className="form-label">
              What interests you in using Modern Sales at your company?
            </label>
          </Grid>
          <Grid item xs={12}>
            <Field
              id="whatInterestsYou"
              name="whatInterestsYou"
              type="whatInterestsYou"
              component="textarea"
              rows="3"
              placeholder="I'm looking for..."
              className="form-textarea"
            />
          </Grid>
          <Field
            type="checkbox"
            name="emailOptedIn"
            className="checkbox"
            style={{ marginTop: 32 }}
          />
          <div style={{ fontWeight: 600, marginTop: 32, marginLeft: 4 }}>
            Check this box to receive emails from Modern Sales.
          </div>
        </Grid>
        <button type="submit" className="cool-button" style={{ marginTop: 16 }}>
          Request a demo
        </button>
      </Form>
    </Formik>
  );
}
