import { Tooltip } from "@mui/material";
import classNames from "classnames";
import React from "react";

import styles from "./PricingTooltip.module.scss";

export function PricingTooltip({
  className,
  children,
  title,
  placement = "top",
}) {
  const classes = classNames(styles.tooltip, className);

  return (
    <Tooltip className={classes} placement={placement} title={title}>
      <span>{children}</span>
    </Tooltip>
  );
}
