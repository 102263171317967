import MSAnalytics from "jsx/api/analytics";
import { isLocal } from "jsx/api/firebase.setup";
import {
  AcceptInvite,
  Admin,
  Autopilot,
  Campaign,
  Contacts,
  CreateAccount,
  Dashboard,
  Demo,
  Draft,
  Integrations,
  Lead,
  Logout,
  Onboarding,
  Pricing,
  SettingsBilling,
  SettingsTeam,
  SettingsUsage,
  SignInPage,
  UseCases,
} from "jsx/pages";
import { Contact } from "jsx/pages/Contacts/Contact";
import { ContactsGen } from "jsx/pages/Contacts/Contacts_old";
import { ContactsNew } from "jsx/pages/Contacts/ContactsNew";
import { Goal } from "jsx/pages/Goals/Goal";
import { Goals } from "jsx/pages/Goals/Goals";
import { TranscriptSummary } from "jsx/pages/Transcripts/TranscriptSummary";
import { Transcripts } from "jsx/pages/Transcripts/Transcripts";
import { TranscriptsNew } from "jsx/pages/Transcripts/TranscriptsNew";
import React, { useEffect } from "react";
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import { RouteRequiresAdmin, RouteRequiresLogin } from "../";
import { TranscriptTemplates } from "jsx/pages/Transcripts/TranscriptTemplates";
import { TranscriptQuery } from "jsx/pages/Transcripts/TranscriptQuery";
import { GoalsNew } from "jsx/pages/Goals/components/GoalsNew";

export function AppRoutes() {
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  useEffect(() => {
    MSAnalytics.page();
  }, [navigate]);

  useEffect(() => {
    const checkoutSuccess = searchParams.get("checkoutSuccess");
    const planSize = searchParams.get("planSize");
    const price = searchParams.get("price");
    const billingPlan = searchParams.get("billingPlan");
    const priceId = searchParams.get("priceId");

    if (checkoutSuccess) {
      if (
        !isLocal &&
        window.location.hostname !== "sdr-ai-staging-94980.web.app"
      ) {
        MSAnalytics.track("Purchased", {
          planSize,
          price,
          billingPlan,
          priceId,
        });
      }

      navigate(`/dashboard` + document.location.search, { replace: true });
    }
  }, [searchParams]);

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/dashboard" replace />} />
      <Route
        path="/pricing"
        element={
          <RouteRequiresLogin>
            <Pricing />
          </RouteRequiresLogin>
        }
      />
      <Route path="/use-cases" element={<UseCases />} />
      <Route
        path="/draft"
        element={
          <RouteRequiresLogin>
            <Draft />
          </RouteRequiresLogin>
        }
      />
      <Route
        path="/dashboard"
        element={
          <RouteRequiresLogin>
            <Dashboard />
          </RouteRequiresLogin>
        }
      />
      <Route
        path="/autopilot"
        element={
          <RouteRequiresLogin>
            <Autopilot />
          </RouteRequiresLogin>
        }
      />
      <Route
        path="/autopilot/:campaignId"
        element={
          <RouteRequiresLogin>
            <Campaign />
          </RouteRequiresLogin>
        }
      />
      <Route
        path="/autopilot/:campaignId/leads/:leadId"
        element={
          <RouteRequiresLogin>
            <Lead />
          </RouteRequiresLogin>
        }
      />
      <Route
        path="/contacts/all"
        element={
          <RouteRequiresLogin>
            <Contacts />
          </RouteRequiresLogin>
        }
      />

      <Route
        path="/contacts/lookup"
        element={
          <RouteRequiresLogin>
            <ContactsGen />
          </RouteRequiresLogin>
        }
      />

      <Route
        path="/contacts/lookup/:contactId"
        element={
          <RouteRequiresLogin>
            <ContactsGen />
          </RouteRequiresLogin>
        }
      />

      <Route
        path="/contacts/new"
        element={
          <RouteRequiresLogin>
            <ContactsNew />
          </RouteRequiresLogin>
        }
      />

      <Route
        path="/contacts/:contactId"
        element={
          <RouteRequiresLogin>
            <Contact />
          </RouteRequiresLogin>
        }
      />

      <Route
        path="/transcripts/all"
        element={
          <RouteRequiresLogin>
            <Transcripts />
          </RouteRequiresLogin>
        }
      />

      <Route
        path="/transcripts/templates"
        element={
          <RouteRequiresLogin>
            <Transcripts />
          </RouteRequiresLogin>
        }
      />
      <Route
        path="/transcripts/new"
        element={
          <RouteRequiresLogin>
            <TranscriptsNew />
          </RouteRequiresLogin>
        }
      />

      <Route
        path="/transcripts/:transcriptId"
        element={
          <RouteRequiresLogin>
            <TranscriptsNew />
          </RouteRequiresLogin>
        }
      />

      <Route
        path="/transcripts/templates/:transcriptId"
        element={
          <RouteRequiresLogin>
            <TranscriptTemplates />
          </RouteRequiresLogin>
        }
      />

      <Route
        path="/transcripts/summarize/:transcriptId"
        element={
          <RouteRequiresLogin>
            <TranscriptSummary />
          </RouteRequiresLogin>
        }
      />

      <Route
        path="/transcripts/query/:transcriptId"
        element={
          <RouteRequiresLogin>
            <TranscriptQuery />
          </RouteRequiresLogin>
        }
      />

      <Route
        path="/goals"
        element={
          <RouteRequiresLogin>
            <Goals />
          </RouteRequiresLogin>
        }
      />

      <Route
        path="/goals/new"
        element={
          <RouteRequiresLogin>
            <GoalsNew />
          </RouteRequiresLogin>
        }
      />
      <Route
        path="/goals/:goalId"
        element={
          <RouteRequiresLogin>
            <Goal />
          </RouteRequiresLogin>
        }
      />

      <Route
        path="/workspaces/:workspaceId/accept/:inviteCode/:workspaceName"
        element={<AcceptInvite />}
      />

      <Route
        path="/settings/general"
        element={
          <RouteRequiresLogin>
            <SettingsTeam />
          </RouteRequiresLogin>
        }
      />
      <Route
        path="/settings/team"
        element={
          <RouteRequiresLogin>
            <SettingsTeam />
          </RouteRequiresLogin>
        }
      />
      <Route
        path="/settings/usage"
        element={
          <RouteRequiresLogin>
            <SettingsUsage />
          </RouteRequiresLogin>
        }
      />
      <Route
        path="/settings/billing"
        element={
          <RouteRequiresLogin>
            <SettingsBilling />
          </RouteRequiresLogin>
        }
      />

      <Route path="/demo" element={<Demo />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/signup" element={<CreateAccount />} />
      <Route path="/login" element={<SignInPage />} />

      <Route
        path="/integrations"
        element={
          <RouteRequiresLogin>
            <Integrations />
          </RouteRequiresLogin>
        }
      />
      <Route
        path="/onboarding"
        element={
          <RouteRequiresLogin>
            <Onboarding />
          </RouteRequiresLogin>
        }
      />

      {/* ADMIN ROUTES */}
      <Route
        path="/admin"
        element={
          <RouteRequiresAdmin>
            <Admin />
          </RouteRequiresAdmin>
        }
      />
    </Routes>
  );
}
