import { DraftsOutlined, SearchOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Autocomplete,
  Breadcrumbs,
  Button,
  Chip,
  Divider,
  Grid,
  IconButton,
  Skeleton,
  TextField,
  Tooltip,
} from "@mui/material";
import { Box } from "@mui/system";
import { doc, getFirestore, onSnapshot, setDoc } from "firebase/firestore";
import { Field, Form, Formik } from "formik";
import { CustomCircularProgress, SideMenu } from "jsx/components";
import { UserContext } from "jsx/context";
import _ from "lodash";
import { useContext, useEffect, useRef, useState } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom/dist";
import { toast, ToastContainer } from "react-toastify";

export function Contact() {
  const [contact, setContact] = useState();
  const [loading, setLoading] = useState(false);
  const { workspace, user } = useContext(UserContext);
  const { contactId } = useParams();
  const formRef = useRef();
  const [searchParams] = useSearchParams();
  const [tags, setTags] = useState();
  const [tagsInputValue, setTagsInputValue] = useState("");
  const submitButtonRef = useRef(null);
  const [tagsOptions, setTagsOptions] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const newContact = searchParams.get("new");

    if (newContact) {
      toast.success(`New Contact created`, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  }, []);

  useEffect(() => {
    setTags(contact?.tags);

    const storedTags = localStorage.getItem("contactsTagsOptions") || "[]";
    const parsedStoredTags = JSON.parse(storedTags);
    const uniqueTagsOptions = Array.from(new Set(parsedStoredTags.concat(contact?.tags || [])));
    setTagsOptions(uniqueTagsOptions);
  }, [contact]);

  useEffect(() => {
    const db = getFirestore();

    const unsub = onSnapshot(
      doc(
        db,
        "workspaces",
        workspace.id,
        "workspaces_private",
        "data",
        "contacts",
        contactId
      ),
      (doc) => {
        const source = doc.metadata.hasPendingWrites ? "Local" : "Server";
        const _contact = { id: doc.id, ...doc.data() };

        setContact(_contact);
      }
    );
  }, []);

  let content;

  if (contact) {
    let detailsContent;
    let hydrateButton;

    if (contact.status === "hydrating") {
      detailsContent = (
        <>
          <Grid item xs={12}>
            <div>
              <label htmlFor="companyName" className="form-label">
                Company name
              </label>
              <div
                style={{
                  padding: 10,
                  paddingLeft: 16,
                  border: "1px solid #DDD",
                  marginTop: 8,
                  borderRadius: 4,
                }}
              >
                <Skeleton
                  variant="rectangular"
                  height={18}
                  width={150}
                  animation="wave"
                />
              </div>
            </div>
          </Grid>

          <Grid item xs={12}>
            <div>
              <label htmlFor="jobTitle" className="form-label">
                Job title
              </label>

              <div
                style={{
                  padding: 10,
                  paddingLeft: 16,
                  border: "1px solid #DDD",
                  marginTop: 8,
                  borderRadius: 4,
                }}
              >
                <Skeleton
                  variant="rectangular"
                  height={18}
                  width={190}
                  animation="wave"
                />
              </div>
            </div>
          </Grid>

          <Grid item xs={12}>
            <div>
              <label htmlFor="location" className="form-label">
                Location
              </label>
              <div
                style={{
                  padding: 10,
                  paddingLeft: 16,
                  border: "1px solid #DDD",
                  marginTop: 8,
                  borderRadius: 4,
                }}
              >
                <Skeleton
                  variant="rectangular"
                  height={18}
                  width={300}
                  animation="wave"
                />
              </div>
            </div>
          </Grid>

          <Grid item xs={12}>
            <div>
              <label htmlFor="interests" className="form-label">
                Interests
              </label>
              <div
                style={{
                  padding: 16,
                  border: "1px solid #DDD",
                  marginTop: 8,
                  borderRadius: 4,
                }}
              >
                <Skeleton variant="rectangular" height={40} animation="wave" />
              </div>
            </div>
          </Grid>

          <Grid item xs={12}>
            <div>
              <label htmlFor="summary" className="form-label">
                Summary
              </label>
              <div
                style={{
                  padding: 16,
                  border: "1px solid #DDD",
                  marginTop: 8,
                  borderRadius: 4,
                }}
              >
                <Skeleton variant="rectangular" height={160} animation="wave" />
              </div>
            </div>
          </Grid>
        </>
      );

      hydrateButton = (
        <>
          <Tooltip title="Looking up Contact details" placement="top">
            <div>
              <CustomCircularProgress size={20} />
            </div>
          </Tooltip>
        </>
      );
    } else {
      detailsContent = (
        <>
          <Grid item xs={12}>
            <div>
              <label htmlFor="companyName" className="form-label">
                Company name
              </label>
              <Field
                id="companyName"
                name="companyName"
                className="form-input"
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div>
              <label htmlFor="jobTitle" className="form-label">
                Job title
              </label>
              <Field id="jobTitle" name="jobTitle" className="form-input" />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div>
              <label htmlFor="location" className="form-label">
                Location
              </label>
              <Field id="location" name="location" className="form-input" />
            </div>
          </Grid>

          <Grid item xs={12}>
            <label
              htmlFor="jobTitle"
              className="form-label"
              style={{ marginBottom: 8 }}
            >
              Tags
            </label>
            <Autocomplete
              key={tags}
              multiple
              id="tags-outlined"
              options={tagsOptions}
              filterSelectedOptions
              value={tags ?? []}
              freeSolo
              onChange={(e, tags) => {
                setTags(tags);
                setTagsInputValue("");
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              onBlur={(e) => {
                if (!e.target.value.trim()) return;
                setTagsInputValue("");
                setTags((prev) => [...prev, e.target.value.trim()]);
              }}
              inputValue={tagsInputValue}
              onInputChange={(e) => setTagsInputValue(e.target.value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="New tag"
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <div>
              <label htmlFor="interests" className="form-label">
                Interests
              </label>
              <Field
                id="interests"
                name="interests"
                component="textarea"
                rows="2"
                className="form-textarea"
                widht="100%"
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div>
              <label htmlFor="summary" className="form-label">
                Summary
              </label>
              <Field
                id="summary"
                name="summary"
                component="textarea"
                rows="10"
                className="form-textarea"
                widht="100%"
              />
            </div>
          </Grid>
        </>
      );
    }

    content = (
      <>
        <div style={{ display: "flex" }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link to="/contacts/all">All contacts</Link>
            <div>Edit</div>
          </Breadcrumbs>

          <div style={{ flexGrow: 1 }} />
          <LoadingButton
            variant="outlined"
            type="submit"
            loading={contact?.status === "hydrating"}
            onClick={() => {
              submitButtonRef.current.click();
            }}
          >
            Update
          </LoadingButton>
        </div>

        <Box
          sx={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h2>{(contact.firstName ?? "") + " " + (contact.lastName ?? "")}</h2>
          <div style={{ flexGrow: 1 }}></div>
          {hydrateButton}

          {/* {contact.status !== "hydrating" && (
            <Tooltip
              title='Lookup and autofill data. Needs to have "Website" field filled out.'
              placement="top"
            >
              <IconButton
                aria-label="copy"
                size="medium"
                className="small-icon-button"
                placement="top"
                onClick={async () => {
                  await formRef.current.handleSubmit();
                  post("leads/hydrateContact", { contactId });
                }}
                disabled={!contact?.website}
              >
                <SearchOutlined fontSize="inherit" />
              </IconButton>
            </Tooltip>
          )} */}

          <Button
            aria-label="copy"
            className="small-icon-button"
            placement="top"
            size="small"
            onClick={() => {
              navigate(`/draft?contactId=${contact.id}`);
            }}
          >
            Open in Drafts
          </Button>
        </Box>

        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={contact}
          onSubmit={async (newValues) => {
            const db = getFirestore();

            const f = await setDoc(
              doc(
                db,
                [
                  "workspaces",
                  workspace.id,
                  "workspaces_private",
                  "data",
                  "contacts",
                  contact.id,
                ].join("/")
              ),
              { ...newValues, updatedAt: new Date(), tags: tags ?? [] }
            );

            toast.success(`Contact Updated`, {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          }}
          style={{ height: "100%", width: "100%" }}
        >
          <Form
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              width: "100%",
            }}
          >
            {contact.status === "hydration-failed" && (
              <>
                <Alert severity="warning">
                  Something went wrong when trying to lookup data on this
                  person. You can click the lookup button to retry.
                </Alert>
              </>
            )}

            <div style={{ display: "flex" }}>
              <div style={{ flexGrow: 1 }} />
            </div>

            <h4>Profile information</h4>

            <Divider />
            <div style={{ display: "flex" }}>
              <div
                style={{
                  fontSize: 14,
                  color: "#666",
                  marginTop: 16,
                }}
              >
                Need help filling this out? Try our Contact Lookup feature
              </div>
              <div style={{ flexGrow: 1 }} />
              <Button
                aria-label="copy"
                className="small-icon-button"
                placement="top"
                size="small"
                style={{ marginTop: 8 }}
                onClick={() => {
                  navigate(`/contacts/lookup/${contact.id}`);
                }}
              >
                Open in Lookup
              </Button>
            </div>

            <Grid container style={{ width: "100%" }}>
              <Grid item xs={12}>
                <div>
                  <label htmlFor="firstName" className="form-label">
                    First name
                  </label>
                  <Field
                    id="firstName"
                    name="firstName"
                    className="form-input"
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div>
                  <label htmlFor="lastName" className="form-label">
                    Last name
                  </label>
                  <Field id="lastName" name="lastName" className="form-input" />
                </div>
              </Grid>

              <Grid item xs={12}>
                <div>
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <Field id="email" name="email" className="form-input" />
                </div>
              </Grid>

              <Grid item xs={12}>
                <div>
                  <label htmlFor="linkedin" className="form-label">
                    LinkedIn
                  </label>
                  <Field id="linkedin" name="linkedin" className="form-input" />
                </div>
              </Grid>

              <Grid item xs={12}>
                <div>
                  <label htmlFor="website" className="form-label">
                    Website
                  </label>
                  <Field id="website" name="website" className="form-input" />
                </div>
              </Grid>

              {detailsContent}
            </Grid>

            <Button
              ref={submitButtonRef}
              type="submit"
              style={{ display: "none" }}
            />
          </Form>
        </Formik>
      </>
    );
  } else {
    content = (
      <>
        <Skeleton
          variant="rectangular"
          height={400}
          style={{ marginTop: 80 }}
        />
      </>
    );
  }

  return (
    <>
      <Grid container style={{ minHeight: "100vh" }}>
        <Box
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "start",
            alignItems: "start",
            minHeight: "100%",
          }}
        >
          <SideMenu />
          <Box
            className="container"
            sx={{ flexGrow: 1, justifyContent: "center", alignItems: "center" }}
          >
            <Box sx={{ height: 400, maxWidth: 700, margin: "0 auto" }}>
              {content}
            </Box>
          </Box>
        </Box>
        <ToastContainer />
      </Grid>
    </>
  );
}
