import amplitude from "@analytics/amplitude";
import googleAnalytics from "@analytics/google-analytics";
import Analytics from "analytics";

import firebaseConfig from "../../configs/firebase-config.json";

/* Initialize analytics */
const MSAnalytics = Analytics({
  app: "Modern Sales",
  version: 100,
  plugins: [
    googleAnalytics({
      measurementIds: ["G-MEF6XPBCCP"],
    }),
    amplitude({
      apiKey: "3ce8b71825059fc5e784cfc9d9ace953",
      // See options at https://bit.ly/3dRdZnE
      options: {
        includeUtm: true,
        includeReferrer: true,
        saveParamsReferrerOncePerSession: false,
      },
    }),
  ],
});

export default MSAnalytics;
