import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { SideMenu } from "jsx/components";
import { UserContext } from "jsx/context";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { OnboardTable } from "./components";
import { OnboardTranscriptsTable } from "./components/OnboardTable/OnboardTranscriptsTable";

export function Dashboard() {
  const { user, workspace } = useContext(UserContext);
  const uid = useParams()["userId"] ?? user.uid;
  const [onboardingTasks, setOnboardingTasks] = useState(
    user?.workspaceAdmin?.onboardingTasks
  );

  const db = getFirestore();

  async function getOnboardingTasks() {
    if (workspace && workspace.id) {
      console.log("works", workspace.id);
      const workspaceAdmin = await getDoc(
        doc(db, "workspaces", workspace.id, "workspaces_admin", "data")
      );

      console.log("workspaceAdmin", workspaceAdmin.data().onboardingTasks);

      setOnboardingTasks(workspaceAdmin.data().onboardingTasks);
    }
  }

  useEffect(() => {
    getOnboardingTasks();
  }, [workspace]);

  return (
    <Grid sx={{ display: "flex" }}>
      <SideMenu />
      <Box
        className="container"
        sx={{
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={{ minHeight: 400, maxWidth: 900, margin: "0 auto" }}>
          <Box
            sx={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h1>Dashboard</h1>
            <div style={{ flexGrow: 1 }}></div>
          </Box>
          <h2 style={{ fontSize: 30, marginBottom: 8, fontWeight: 500 }}>
            See how Modern Sales can transform your business
          </h2>
          <div style={{ fontSize: 18, color: "#444" }}>
            Follow these steps to get started and earn free credits as you go.
          </div>
          <OnboardTable onboardingTasks={onboardingTasks} />
          <OnboardTranscriptsTable onboardingTasks={onboardingTasks} />
        </Box>
      </Box>
    </Grid>
  );
}
