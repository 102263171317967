import { Done as DoneIcon } from "@mui/icons-material";
import React from "react";

import { PricingTooltip } from "../";
import styles from "./PricingBenefit.module.scss";

export function PricingBenefit({ benefit }) {
  const { text, tip } = benefit;

  return (
    <div className={styles.benefit}>
      <DoneIcon className={styles.icon} />
      {tip ? (
        <PricingTooltip className={styles.text} title={tip}>
          {text}
        </PricingTooltip>
      ) : (
        <span className={styles.text}>{text}</span>
      )}
    </div>
  );
}
