import { Grid, Skeleton } from "@mui/material";
import { getFunctions, httpsCallable } from "firebase/functions";
import React, { useState } from "react";

import { getSubscriptionInfo } from "../../utils";
import {
  AvailableCredits,
  BillingCredits,
  BillingInvoices,
  BillingSubscription,
  BonusCredits,
  NoSubscription,
} from "../";

export function SettingsBillingContent({
  subscription,
  subscriptions,
  bonusCredits,
}) {
  const [editPlanLoading, setEditPlanLoading] = useState(false);
  const [editPaymentDetailsLoading, setEditPaymentDetailsLoading] =
    useState(false);
  const [viewInvoicesLoading, setViewInvoicesLoading] = useState(false);

  const functions = getFunctions();

  function getPortalLink() {
    const functionRef = httpsCallable(
      functions,
      "ext-firestore-stripe-payments-82co-createPortalLink"
    );
    functionRef({
      returnUrl: `${window.location.origin}/settings/billing`,
      locale: "auto",
    }).then((result) => {
      setViewInvoicesLoading(false);
      setEditPaymentDetailsLoading(false);
      setEditPlanLoading(false);
      const data = result.data;
      window.location.assign(data.url);
    });
  }

  if (!subscription) {
    return (
      <Skeleton
        variant="rectangular"
        height={400}
        style={{ marginBottom: 12, marginTop: 48 }}
      />
    );
  }
  const { subscriptionText, subscriptionCredits } =
    getSubscriptionInfo(subscription);

  if (!subscription.items) {
    return (
      <>
        <Grid container style={{ width: "100%", marginBottom: 16 }} spacing={2}>
          <AvailableCredits
            subscriptionCredits={subscriptionCredits}
            bonusCredits={bonusCredits}
          />
          <BonusCredits bonusCredits={bonusCredits} />
        </Grid>

        <Grid container style={{ width: "100%", marginBottom: 16 }} spacing={2}>
          <NoSubscription
            editPlanLoading={editPlanLoading}
            setEditPlanLoading={setEditPlanLoading}
          />
        </Grid>
      </>
    );
  }

  return (
    <>
      <BillingCredits
        subscriptionCredits={subscriptionCredits}
        bonusCredits={bonusCredits}
      />
      <BillingSubscription
        subscription={subscription}
        subscriptionCredits={subscriptionCredits}
        subscriptionText={subscriptionText}
        editPaymentDetailsLoading={editPaymentDetailsLoading}
        editPlanLoading={editPlanLoading}
        getPortalLink={getPortalLink}
        setEditPlanLoading={setEditPlanLoading}
        setEditPaymentDetailsLoading={setEditPaymentDetailsLoading}
      />
      <BillingInvoices
        viewInvoicesLoading={viewInvoicesLoading}
        setViewInvoicesLoading={setViewInvoicesLoading}
        getPortalLink={getPortalLink}
      />
    </>
  );
}
