import {
  Drafts,
  DraftsOutlined,
  People,
  PeopleOutline,
  QuestionAnswer,
  QuestionAnswerOutlined,
  RecordVoiceOver,
  RecordVoiceOverOutlined,
} from "@mui/icons-material";
import HomeIcon from "@mui/icons-material/Home";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import PowerIcon from "@mui/icons-material/Power";
import PowerOutlinedIcon from "@mui/icons-material/PowerOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import SmartToyOutlinedIcon from "@mui/icons-material/SmartToyOutlined";
import {
  Badge,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { Box } from "@mui/system";
import { useContext } from "react";
import { Link } from "react-router-dom";

import { UserContext } from "../../context/UserContext";
import { WorkspaceSelectorButton } from "../buttons";

export function SideMenu() {
  const { logout } = useContext(UserContext);

  return (
    <div
      style={{
        width: "270px",
        flexShrink: 0,
      }}
    >
      <Grid
        item
        sx={{
          width: "270px",
          borderRight: "1px solid #e4e4e4",
          height: "100vh",
          position: "fixed",
          background: "white",
        }}
      >
        <div
          style={{
            marginLeft: 20,
            marginTop: 32,
            fontWeight: 600,
            fontSize: 22,
          }}
          className="logo-dark"
        ></div>
        <Box sx={{ width: "270px" }} role="presentation">
          <WorkspaceSelectorButton />
          <List>
            <Link
              to="/dashboard"
              style={{ textDecoration: "none", color: "#241c15" }}
            >
              <ListItem disablePadding>
                <ListItemButton
                  className={
                    window.location.href.includes("/dashboard")
                      ? "sidemenu-item-selected"
                      : ""
                  }
                >
                  <ListItemIcon
                    style={{ marginRight: -16, marginLeft: 4, color: "#333" }}
                  >
                    {window.location.href.includes("/dashboard") ? (
                      <HomeIcon color="primary" />
                    ) : (
                      <HomeOutlinedIcon />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={"Dashboard"} />
                </ListItemButton>
              </ListItem>
            </Link>
            <Link
              to="/draft"
              style={{ textDecoration: "none", color: "#241c15" }}
            >
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon
                    style={{ marginRight: -16, marginLeft: 4, color: "#333" }}
                  >
                    {window.location.href.includes("/draft") ||
                    window.location.href.includes("/autopilot") ? (
                      <Drafts color="primary" />
                    ) : (
                      <DraftsOutlined />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={"Smart Campaigns"} />
                </ListItemButton>
              </ListItem>
            </Link>
            <Link
              to="/draft"
              style={{ textDecoration: "none", color: "#241c15" }}
            >
              <ListItem
                dense
                disablePadding
                selected={window.location.href.includes("/draft")}
              >
                <ListItemButton
                  className={
                    window.location.href.includes("/draft")
                      ? "sidemenu-item-selected"
                      : ""
                  }
                >
                  <ListItemText primary={"Draft"} style={{ marginLeft: 46 }} />
                </ListItemButton>
              </ListItem>
            </Link>
            <Link
              to="/autopilot"
              style={{ textDecoration: "none", color: "#241c15" }}
            >
              <ListItem
                dense
                disablePadding
                selected={window.location.href.includes("/autopilot")}
                secondaryAction={
                  <div
                    style={{
                      padding: 4,
                      border: "1px solid #e5e3f0",
                      color: "#999",
                      background: "#fcf9f9",
                      borderRadius: 99,
                      fontSize: 12,
                      width: 32,
                      textAlign: "center",
                    }}
                  >
                    Beta
                  </div>
                }
              >
                <ListItemButton
                  className={
                    window.location.href.includes("/autopilot")
                      ? "sidemenu-item-selected"
                      : ""
                  }
                >
                  <ListItemText
                    primary={"Autopilot"}
                    style={{ marginLeft: 46 }}
                  />
                </ListItemButton>
              </ListItem>
            </Link>
            <Link
              to="/contacts/all"
              style={{ textDecoration: "none", color: "#241c15" }}
            >
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon
                    style={{ marginRight: -16, marginLeft: 4, color: "#333" }}
                  >
                    {window.location.href.includes("/contacts/all") ||
                    window.location.href.includes("/lookup") ? (
                      <People color="primary" />
                    ) : (
                      // <Badge color="primary" variant="dot">
                      <PeopleOutline />
                      // </Badge>
                    )}
                  </ListItemIcon>

                  <ListItemText primary={"Contacts"} />
                </ListItemButton>
              </ListItem>
            </Link>

            <Link
              to="/contacts/all"
              style={{ textDecoration: "none", color: "#241c15" }}
            >
              <ListItem
                dense
                disablePadding
                selected={window.location.href.includes("/contacts/all")}
              >
                <ListItemButton
                  className={
                    window.location.href.includes("/contacts/all")
                      ? "sidemenu-item-selected"
                      : ""
                  }
                >
                  <ListItemText
                    primary={"All contacts"}
                    style={{ marginLeft: 46 }}
                  />
                </ListItemButton>
              </ListItem>
            </Link>

            <Link
              to="/contacts/lookup"
              style={{ textDecoration: "none", color: "#241c15" }}
            >
              <ListItem
                dense
                disablePadding
                selected={window.location.href.includes("/contacts/lookup")}
              >
                <ListItemButton
                  className={
                    window.location.href.includes("/contacts/lookup")
                      ? "sidemenu-item-selected"
                      : ""
                  }
                >
                  <ListItemText primary={"Lookup"} style={{ marginLeft: 46 }} />
                </ListItemButton>
              </ListItem>
            </Link>

            <Link
              to="/transcripts/all"
              style={{ textDecoration: "none", color: "#241c15" }}
              className={
                window.location.href.includes("/transcripts")
                  ? "sidemenu-item-selected"
                  : ""
              }
            >
              <ListItem
                disablePadding
                secondaryAction={
                  <div
                    style={{
                      padding: 4,
                      border: "1px solid #e5e3f0",
                      color: "#999",
                      background: "#fcf9f9",
                      borderRadius: 99,
                      fontSize: 12,
                      width: 32,
                      textAlign: "center",
                    }}
                  >
                    Beta
                  </div>
                }
                selected={window.location.href.includes("/transcripts")}
              >
                <ListItemButton>
                  <ListItemIcon
                    style={{ marginRight: -16, marginLeft: 4, color: "#333" }}
                  >
                    {window.location.href.includes("/transcripts") ? (
                      <RecordVoiceOver color="primary" />
                    ) : (
                      <RecordVoiceOverOutlined />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={"Transcripts"} />
                </ListItemButton>
              </ListItem>
            </Link>
            {/* <Link
              to="/transcripts/all"
              style={{ textDecoration: "none", color: "#241c15" }}
            >
              <ListItem
                dense
                disablePadding
                selected={window.location.href.includes("/transcripts/all")}
              >
                <ListItemButton
                  className={
                    window.location.href.includes("/transcripts/all")
                      ? "sidemenu-item-selected"
                      : ""
                  }
                >
                  <ListItemText
                    primary={"All transcripts"}
                    style={{ marginLeft: 46 }}
                  />
                </ListItemButton>
              </ListItem>
            </Link>
            <Link
              to="/transcripts/summaries"
              style={{ textDecoration: "none", color: "#241c15" }}
            >
              <ListItem
                dense
                disablePadding
                selected={window.location.href.includes(
                  "/transcripts/summaries"
                )}
              >
                <ListItemButton
                  className={
                    window.location.href.includes("/transcripts/summaries")
                      ? "sidemenu-item-selected"
                      : ""
                  }
                >
                  <ListItemText
                    primary={"Summaries"}
                    style={{ marginLeft: 46 }}
                  />
                </ListItemButton>
              </ListItem>
            </Link> */}

            {window.location.hostname === "sdr-ai-staging-94980.web.app" ||
              (window.location.hostname.includes("localhost") && (
                <Link
                  to="/goals"
                  style={{ textDecoration: "none", color: "#241c15" }}
                >
                  <ListItem
                    disablePadding
                    secondaryAction={
                      <div
                        style={{
                          padding: 4,
                          border: "1px solid #e5e3f0",
                          color: "#999",
                          background: "#fcf9f9",
                          borderRadius: 99,
                          fontSize: 12,
                          width: 32,
                          textAlign: "center",
                        }}
                      >
                        Beta
                      </div>
                    }
                  >
                    <ListItemButton
                      className={
                        window.location.href.includes("/goals")
                          ? "sidemenu-item-selected"
                          : ""
                      }
                    >
                      <ListItemIcon
                        style={{
                          marginRight: -16,
                          marginLeft: 4,
                          color: "#333",
                        }}
                      >
                        {window.location.href.includes("/goals") ? (
                          <QuestionAnswer color="primary" />
                        ) : (
                          <QuestionAnswerOutlined />
                        )}
                      </ListItemIcon>
                      <ListItemText primary={"Goals"} />
                    </ListItemButton>
                  </ListItem>
                </Link>
              ))}
            <Link
              to="/integrations"
              style={{ textDecoration: "none", color: "#241c15" }}
            >
              <ListItem
                disablePadding
                secondaryAction={
                  <div
                    style={{
                      padding: 4,
                      border: "1px solid #e5e3f0",
                      color: "#999",
                      background: "#fcf9f9",
                      borderRadius: 99,
                      fontSize: 12,
                      width: 32,
                      textAlign: "center",
                    }}
                  >
                    Beta
                  </div>
                }
              >
                <ListItemButton
                  className={
                    window.location.href.includes("/integrations")
                      ? "sidemenu-item-selected"
                      : ""
                  }
                >
                  <ListItemIcon
                    style={{ marginRight: -16, marginLeft: 4, color: "#333" }}
                  >
                    {window.location.href.includes("/integrations") ? (
                      <PowerIcon color="primary" />
                    ) : (
                      <PowerOutlinedIcon />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={"Integrations"} />
                </ListItemButton>
              </ListItem>
            </Link>
          </List>
          <div
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              width: "270px",
            }}
          >
            <Link
              to="/settings/team"
              style={{ textDecoration: "none", color: "#241c15" }}
            >
              <ListItem disablePadding>
                <ListItemButton
                  className={
                    window.location.href.includes("/settings")
                      ? "sidemenu-item-selected"
                      : ""
                  }
                >
                  <ListItemIcon
                    style={{ marginRight: -16, marginLeft: 4, color: "#333" }}
                  >
                    {window.location.href.includes("/settings") ? (
                      <SettingsIcon color="primary" />
                    ) : (
                      <SettingsOutlinedIcon />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={"Settings"} />
                </ListItemButton>
              </ListItem>
            </Link>

            <Divider style={{ marginBottom: -8 }} />
            <List>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    logout();
                  }}
                >
                  <ListItemIcon
                    style={{ marginRight: -16, marginLeft: 4, color: "#333" }}
                  >
                    <LogoutIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Logout"} />
                </ListItemButton>
              </ListItem>
            </List>
          </div>
        </Box>
      </Grid>
    </div>
  );
}
