import { Button, Grid, Paper } from "@mui/material";
import { CustomCircularProgress } from "jsx/components";
import { SignInForm } from "jsx/components";
import React from "react";

export function InviteContent({
  user,
  workspaceName,
  loading,
  acceptInvite,
  logout,
}) {
  if (loading) {
    return <CustomCircularProgress size={60} style={{ marginTop: 60 }} />;
  }

  if (!user || !user.email) {
    return (
      <>
        <div style={{ fontWeight: 500, marginBottom: -48, color: "#333" }}>
          Login or sign up to accept your invite to {window.atob(workspaceName)}
        </div>
        <SignInForm isSignin={true} hideSignup={false} />
      </>
    );
  }

  return (
    <Paper variant="outlined" style={{ padding: 32, borderRadius: 4 }}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <h2 style={{ marginTop: 0 }}>Accept Invite</h2>
        <p>
          You've been invited to join{" "}
          <strong>{window.atob(workspaceName)}</strong>
        </p>
        <div style={{ display: "flex" }}>
          <Button
            size="large"
            variant="contained"
            color="primary"
            disableElevation={true}
            onClick={acceptInvite}
          >
            Accept Invite
          </Button>
          <Button
            size="large"
            variant="contained"
            style={{ marginLeft: 12 }}
            color="secondary"
            disableElevation={true}
            onClick={logout}
          >
            Signout
          </Button>
        </div>
      </Grid>
    </Paper>
  );
}
