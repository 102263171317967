import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { get } from "jsx/Api";
import { SettingsMenu } from "jsx/components";
import { UserContext } from "jsx/context";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { SettingsBillingContent } from "./components";

export function SettingsBilling() {
  const db = getFirestore();
  const { user, workspace } = useContext(UserContext);
  const [subscriptions, setSubscriptions] = useState();
  const [bonusCredits, setBonusCredits] = useState(0);
  const [subscription, setSubscription] = useState();

  const uid = useParams()["userId"] ?? user.uid;

  async function getSubscriptions() {
    const res = await get("workspaces/subscriptions");

    setSubscriptions(res.data);

    if (res.data.length) {
      setSubscription(res.data[0]);
    } else {
      setSubscription({});
    }
  }

  useEffect(() => {
    if (uid) {
      getSubscriptions();
      getBonusCredits();
    }
  }, [uid]);

  async function getBonusCredits() {
    const q = await getDoc(
      doc(db, "workspaces", workspace.id, "workspaces_admin", "data")
    );

    setBonusCredits(q.data().bonusCredits ?? 0);
  }

  return (
    <Grid container>
      <SettingsMenu />
      <Box
        className="container"
        sx={{ flexGrow: 1, justifyContent: "center", alignItems: "center" }}
      >
        <Box sx={{ height: 400, maxWidth: 900, margin: "0 auto" }}>
          <Box>
            <h2>Billing</h2>
            <h3>Available credits</h3>
            <p style={{ color: "#666", marginBottom: 32 }}></p>
            <div style={{ flexGrow: 1 }} />
            <SettingsBillingContent
              subscriptions={subscriptions}
              bonusCredits={bonusCredits}
              subscription={subscription}
            />
          </Box>
        </Box>
      </Box>
    </Grid>
  );
}
