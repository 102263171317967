import { CustomCircularProgress } from "jsx/components";
import { UserContext } from "jsx/context";
import { Onboarding, Pricing } from "jsx/pages";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export function RouteRequiresLogin(props) {
  const { user, hasProcessedAuth, workspace } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user.uid && hasProcessedAuth) {
      navigate("/login", { replace: true });
    }
  }, [user, hasProcessedAuth]);

  const shouldShowPricing =
    !user.subscriptions?.length &&
    !user.workspaceAdmin?.bonusCredits &&
    !user.admin?.bypassPricing;

  if (hasProcessedAuth && shouldShowPricing) {
    return <Pricing />;
  } else if (hasProcessedAuth && !user.admin?.workspaces) {
    return <Onboarding />;
  } else if (hasProcessedAuth && user && user.uid) {
    return (
      <>
        {workspace?.impersonating && (
          <div
            style={{
              background: "rgba(255,0,0,0.2)",
              position: "fixed",
              left: 0,
              right: 0,
              zIndex: 9999,
              color: "white",
              fontWeight: 900,
              padding: 16,
            }}
          >
            IMPERSONATING
          </div>
        )}
        {props.children}
      </>
    );
  } else {
    return (
      <div style={{ top: "40vh", position: "absolute", left: "50vw" }}>
        <CustomCircularProgress size={60} />
      </div>
    );
  }
}
