import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { PromptSection, SideMenu } from "jsx/components";
import { UserContext } from "jsx/context";
import {
  useConservationsService,
  useEmailsService,
  useLeadsService,
} from "jsx/hooks";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { EmailSection, LeadSection } from "./components";

export function Lead({ campaign: defaultCampaign, lead: defaultLead }) {
  const { user } = useContext(UserContext);
  const { campaignId, leadId } = useParams();

  const [campaign, setCampaign] = useState(defaultCampaign);
  const [conversation, setConversation] = useState();
  const [emails, setEmails] = useState();
  const [lead, setLead] = useState(defaultLead);
  const [editedPrompt, setEditedPrompt] = useState();

  const uid = useParams()["userId"] ?? user.uid;

  const { getLead } = useLeadsService(campaignId);
  const { getEmails } = useEmailsService(campaignId, leadId);
  const { getConversation, saveConversation } = useConservationsService(
    campaignId,
    leadId
  );

  useEffect(() => {
    if (uid && !lead) {
      getLead(leadId, (data) => setLead(data));
    }

    if (uid && !campaign) {
      // getCampaign(campaignId, (data) => setCampaign(data));
    }

    if (uid) {
      onConversationGet();
    }
  }, [uid]);

  useEffect(() => {
    if (conversation) {
      onEmailsGet();
    }
  }, [conversation]);

  function onConversationSave(values) {
    saveConversation(values, conversation.id, () => setEditedPrompt(false));
  }

  function onConversationGet() {
    getConversation((data) => setConversation(data));
  }

  function onEmailsGet() {
    getEmails(conversation.id, (data) => setEmails(data));
  }

  return (
    <Grid container>
      <Box style={{ display: "flex", width: "100%" }}>
        <SideMenu />
        <Grid
          container
          spacing={4}
          style={{
            paddingLeft: 32,
            paddingTop: 32,
            width: "100%",
            minHeight: "calc(100vh + 32px)",
          }}
        >
          <Grid
            item
            xs={12}
            lg={6}
            style={{
              paddingRight: 40,
              paddingBottom: 48,
              paddingLeft: 32,
            }}
          >
            <LeadSection lead={lead} />
            {/* {lead ? lead.name : null} */}
            {/* <PromptSection
              conversation={conversation}
              getConversation={onConversationGet}
              saveConversation={onConversationSave}
            /> */}
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
            style={{
              background: "#F9FAFC",
              paddingRight: 32,
              borderLeft: "1px solid #E9E9E9",
            }}
          >
            <EmailSection
              lead={lead}
              emails={emails}
              conversation={conversation}
              onConversationGet={onConversationGet}
              onEmailsGet={onEmailsGet}
            />
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
}
