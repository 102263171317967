export const uniqueArrayOfObjects = (a, field = "id") => [
  ...new Map(a.map((item) => [item[field], item])).values(),
];

export function getFirstNameLastName(fullName) {
  const nameArray = fullName.split(" ");

  let lastName;

  // extract the first name and last name
  const firstName = nameArray[0];

  if (nameArray.length > 1) {
    lastName = nameArray[nameArray.length - 1];
  }

  return { firstName, lastName };
}

export const removeFromObjectUndefinedValues = (obj) => Object.fromEntries(Object.entries(obj).filter(([_, v]) => typeof v !== 'undefined'));

export function getFullNameFromContact(contact) {
  if (contact.fullName) {
    return contact.fullName;
  } else {
    return ((contact.firstName ?? "") + " " + (contact.lastName ?? "")).trim();
  }
}
