import { collection, getDocs, getFirestore } from "firebase/firestore";
import { UserContext } from "jsx/context";
import _ from "lodash";
import { useContext } from "react";

export function useTeamMembersService() {
  const db = getFirestore();
  const { user, workspace } = useContext(UserContext);

  async function getTeamMembers(callback) {
    const q = await getDocs(
      collection(
        db,
        "workspaces",
        workspace.id,
        "workspaces_private",
        "data",
        "team"
      )
    );

    let map = q.docs.map((e) => {
      return {
        id: e.id,
        ...e.data(),
      };
    });

    map = _.orderBy(map, "createdAt.seconds", "desc");

    callback(map); // setTeamMembers(map);
  }

  return { getTeamMembers };
}
