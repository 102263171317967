import { Button, Grid } from "@mui/material";
import React from "react";

export function TeamSizeSelection({ setShowDemoVideo, setShowEnterpriseForm }) {
  function onDemoVideoShow() {
    setShowDemoVideo(true);
  }
  function onEnterpriseFormShow() {
    setShowEnterpriseForm(true);
  }

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <div
          style={{
            fontWeight: 500,
            letterSpacing: 0.6,
            fontSize: 14,
            marginBottom: -16,
          }}
        >
          TO GET THE RIGHT DEMO FOR YOU...
        </div>
        <h2 style={{ marginBottom: 64, fontWeight: 600 }}>
          How many people could use Modern Sales on your team?
        </h2>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Button
            size="large"
            variant="contained"
            disableElevation={true}
            style={{ width: "100%", height: "50px", fontSize: 18 }}
            className="try-for-free-button"
            onClick={onDemoVideoShow}
          >
            1-50 people
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <Button
            size="large"
            variant="contained"
            disableElevation={true}
            style={{
              width: "100%",
              height: "50px",
              fontSize: 18,
              background: "black",
              color: "white",
            }}
            color="secondary"
            onClick={onEnterpriseFormShow}
          >
            50+ people
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
