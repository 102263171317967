import { Box, Button, Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { addDoc, collection, getDocs, getFirestore } from "firebase/firestore";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { post } from "jsx/Api";
import { SideMenu } from "jsx/components";
import { CustomNoRowsOverlay } from "jsx/components/EmptyState";
import { UserContext } from "jsx/context";
import _ from "lodash";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

const LeadForm = Yup.object().shape({
  name: Yup.string().required("Required"),
  text: Yup.string().required("Required"),
});

const columns = [
  {
    field: "transcriptName",
    headerName: "Name",
    width: 400,
    editable: false,
  },
  {
    field: "createdAt",
    headerName: "Created at",
    width: 400,
    editable: false,
    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }

      return dayjs(new Date(params.value.seconds * 1000)).fromNow();
    },
  },
];

export function GoalsNew({ loadGoals }) {
  const [transcripts, setTranscripts] = useState([]);
  const [loading, setLoading] = useState(false);
  const db = getFirestore();
  const { workspace, user } = useContext(UserContext);
  const [selectionModel, setSelectionModel] = useState([]);
  const [selectionError, setSelectionError] = useState(false);

  const navigate = useNavigate();

  const getTranscripts = useCallback(async () => {
    try {
      const q = await getDocs(
        collection(
          db,
          "workspaces",
          workspace.id,
          "workspaces_private",
          "data",
          "transcripts"
        )
      );

      let map = q.docs.map((e) => {
        return {
          id: e.id,
          ...e.data(),
        };
      });

      map = _.orderBy(map, ["createdAt"], ["desc"]);
      setTranscripts(map);
    } catch (e) {
      console.log("error fetching transcript", e);
    }
  }, [db, setTranscripts, user.uid]);

  useEffect(() => {
    getTranscripts();
  }, [getTranscripts]);

  const onCreateGoal = async (values) => {
    try {
      setLoading(true);
      // if (selectionModel.length === 0) {
      //   setSelectionError(true);

      //   setLoading(false);
      //   return;
      // }

      const { data } = await post("goals/statementToQuestion", {
        statement: values.text,
      });

      const g = await addDoc(
        collection(
          db,
          "workspaces",
          workspace.id,
          "workspaces_private",
          "data",
          "goals"
        ),
        {
          transcripts: selectionModel,
          name: values.name,
          text: values.text,
          createdAt: new Date(),
          question: data,
        }
      );
      setSelectionModel([]);
      setLoading(false);
      navigate(`/goals/${g.id}`, { replace: true });
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Grid container style={{ minHeight: "100vh" }}>
      <Box
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "start",
          alignItems: "start",
          minHeight: "100%",
        }}
      >
        <SideMenu />
        <Box
          className="container"
          sx={{ flexGrow: 1, justifyContent: "center", alignItems: "center" }}
        >
          <Box sx={{ height: 400, maxWidth: 700, margin: "0 auto" }}>
            <Box
              sx={{
                flexDirection: "row",
                display: "flex",
              }}
            >
              <h2 style={{ marginBottom: 0 }}>New Goal</h2>
              <div style={{ flexGrow: 1 }}></div>
            </Box>
            <Box sx={{ paddingBottom: "40px" }}>
              <Formik
                enableReinitialize
                initialValues={{
                  name: "",
                }}
                validationSchema={LeadForm}
                onSubmit={onCreateGoal}
              >
                {({ handleChange, errors, values }) => (
                  <Form>
                    <label htmlFor="name" className="form-label">
                      Name*
                    </label>
                    <Field
                      id="name"
                      name="name"
                      placeholder="Name"
                      className="form-input"
                    />
                    <ErrorMessage
                      name="name"
                      className="form-error"
                      component="div"
                    />

                    <label htmlFor="name" className="form-label">
                      Goal*
                    </label>
                    <Field
                      id="text"
                      name="text"
                      placeholder="Ask them to accept customer returns within 30 days after shipment."
                      className="form-input"
                    />
                    <ErrorMessage
                      name="text"
                      className="form-error"
                      component="div"
                    />

                    <div>
                      <label
                        htmlFor="name"
                        className="form-label"
                        style={{ marginBottom: 8 }}
                      >
                        Transcripts
                      </label>

                      <DataGrid
                        autoHeight
                        pageSize={20}
                        onSelectionModelChange={(newSelectionModel) => {
                          setSelectionModel(newSelectionModel);
                          setSelectionError(false);
                        }}
                        rows={transcripts}
                        columns={columns}
                        checkboxSelection
                        disableSelectionOnClick
                        experimentalFeatures={{ newEditingApi: true }}
                        onRowClick={(e) => {}}
                        components={{
                          NoRowsOverlay: CustomNoRowsOverlay,
                        }}
                        style={{ minHeight: 400 }}
                      />
                      {/* {selectionError && (
                        <div class="form-error">
                          You need to add at least one transcript
                        </div>
                      )} */}
                    </div>

                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "end",
                        mt: 2,
                      }}
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        disabled={loading}
                        sx={{ marginLeft: "auto" }}
                        disableElevation
                      >
                        Create goal
                      </Button>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
}
