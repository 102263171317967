import { writeBatch } from "firebase/firestore";

export const range = (from, to) => {
  const arrSize = to - from;
  const arr = new Array(arrSize).fill(from);

  return arr.reduce((acc, next, idx) => acc.concat(next + idx), []);
};

function defaultOnBatch(batch) {
  return batch.commit();
}

export function batchLimitParallel({
  firestore,
  limit = 500,
  onEach = (item, updates) => item,
  onBatch = defaultOnBatch,
  items,
}) {
  const numBatches = Math.ceil(items.length / limit);

  return Promise.all(
    range(0, numBatches).map((index) => {
      const updates = writeBatch(firestore);
      const batch = items.slice(index * limit, (index + 1) * limit);

      return Promise.all(batch.map((item) => onEach(item, updates))).then(() => onBatch(updates));
    })
  );
}
