import axios from "axios";
import { EventSourcePolyfill, NativeEventSource } from "event-source-polyfill";
import { getAuth, getIdToken } from "firebase/auth";

import firebaseConfig from "../configs/firebase-config.json";

export function getBaseApiUrl(path) {
  if (
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1"
  ) {
    return "http://localhost:8090";
  } else if (firebaseConfig.projectId.includes("staging")) {
    // stage
    // special case
    if (path?.includes("getContactSummary")) {
      return "https://cyclops-vxrvf4bg2q-ue.a.run.app";
    }

    return "https://sdr-ai-api-vxrvf4bg2q-ue.a.run.app";
  } else {
    // prod
    if (path?.includes("getContactSummary")) {
      return "https://cyclops-aajw7n3haq-ue.a.run.app";
    }

    return "https://sdr-ai-api-aajw7n3haq-ue.a.run.app";
  }
}

export function getBaseUrl() {
  if (
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1"
  ) {
    return "http://localhost:3000";
  } else if (window.location.hostname === "sdr-ai-staging-94980.web.app") {
    return "https://sdr-ai-staging-94980.web.app";
  } else {
    return "https://app.modernsales.ai";
  }
}

export async function postBeacon(path, params) {
  navigator.sendBeacon(`${getBaseApiUrl()}/v1/${path}`, params);
}

export async function post(path, params, options = {}, axiosOptions = {}) {
  let parsedWorkspace;
  if (!params) {
    params = {};
  }

  try {
    parsedWorkspace = JSON.parse(localStorage.getItem("workspace"));
  } catch (e) {}

  const auth = getAuth();
  const authedUser = auth.currentUser;
  if (!authedUser && !Object.keys(params).includes("token")) {
    throw new Error("Missing API auth: no user or token");
  }

  let config = { headers: options };
  if (parsedWorkspace?.id) {
    params["workspaceId"] = parsedWorkspace.id;
  }

  if (authedUser) {
    const accessToken = await getIdToken(authedUser);

    config = {
      ...config,
      headers: { Authorization: `Bearer ${accessToken}`, ...config.headers },
      withCredentials: true,
    };
  }

  try {
    const result = await axios.post(
      `${getBaseApiUrl(path)}/v1/${path}`,
      params,
      { ...config, ...axiosOptions }
    );

    return result;
  } catch (e) {
    throw e;
  }
}

export async function postNoAuth(path, params) {
  try {
    const result = await axios.post(`${getBaseApiUrl()}/v1/${path}`, params);

    return result;
  } catch (e) {
    throw e;
  }
}

export async function get(path, params, options = {}) {
  const auth = getAuth();
  const authedUser = auth.currentUser;
  if (!authedUser) {
    return;
  }

  if (!params) {
    params = {};
  }

  let parsedWorkspace;

  try {
    parsedWorkspace = JSON.parse(localStorage.getItem("workspace"));
  } catch (e) {
    console.log("error", e);
  }

  if (parsedWorkspace?.id) {
    params["workspaceId"] = parsedWorkspace.id;
  }

  const accessToken = await getIdToken(authedUser);
  const config = {
    headers: { Authorization: `Bearer ${accessToken}`, ...options },
    withCredentials: true,
    params,
  };

  try {
    const result = await axios.get(`${getBaseApiUrl(path)}/v1/${path}`, config);

    return result;
  } catch (e) {
    throw e;
  }
}

export async function eventSource(path, params = {}, options = {}) {
  const auth = getAuth();
  const authedUser = auth.currentUser;
  if (!authedUser) {
    return;
  }

  const accessToken = await getIdToken(authedUser);
  const config = {
    headers: { Authorization: `Bearer ${accessToken}`, ...options },
    withCredentials: true,
    params,
  };

  try {
    return new EventSourcePolyfill(`${getBaseApiUrl()}/v1/${path}`, config);
  } catch (e) {
    console.log("error", e);
    throw e;
  }
}
