import { Button, Skeleton } from "@mui/material";
import MSAnalytics from "jsx/api/analytics";
import React from "react";

import { LeadsList } from "..";

export function LeadsSection({
  leads,
  campaign,
  onLeadDelete,
  setShowAddModal,
  getLeads,
}) {
  if (!leads) {
    return (
      <Skeleton variant="rectangular" height={300} style={{ marginTop: 32 }} />
    );
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyItems: "center",
          alignItems: "center",
          marginTop: 48,
        }}
      >
        <h2>Recipients</h2>
        <div style={{ flexGrow: 1 }} />
        <Button
          variant="contained"
          disableElevation
          onClick={() => {
            MSAnalytics.track("Button Clicked", {
              page: "Autopilot",
              buttonType: `Add from contacts`,
            });

            setShowAddModal(true);
          }}
          color="secondary"
        >
          Add from contacts
        </Button>
      </div>
      <LeadsList
        leads={leads}
        onLeadDelete={onLeadDelete}
        campaign={campaign}
        getLeads={getLeads}
      />
    </>
  );
}
