import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { SettingsMenu } from "jsx/components";

import { UsageContent } from "./components";

export function SettingsUsage() {
  return (
    <Grid sx={{ display: "flex" }}>
      <SettingsMenu />
      <Box
        className="container"
        sx={{ flexGrow: 1, justifyContent: "center", alignItems: "center" }}
      >
        <Box sx={{ height: 400, maxWidth: 1000, margin: "0 auto" }}>
          <Box>
            <h2>Usage</h2>
            <p style={{ color: "#666", marginBottom: 32 }}>
              Review your usage for the upcoming billing cycle. Note that
              billing is based on UTC.
            </p>
            <div style={{ flexGrow: 1 }}></div>
          </Box>
          <UsageContent />
        </Box>
      </Box>
    </Grid>
  );
}
