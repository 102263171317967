import { UserContext } from "jsx/context";
import { useContext } from "react";

export function Logout() {
  const { logout } = useContext(UserContext);

  logout();

  return <></>;
}
