import { doc, getDoc, getFirestore, setDoc } from "firebase/firestore";
import { UserContext } from "jsx/context";
import { useContext } from "react";

export function useCampaignsService() {
  const db = getFirestore();
  const { user, workspace } = useContext(UserContext);

  async function getCampaign(campaignId, callback) {
    try {
      const q = await getDoc(
        doc(
          db,
          "workspaces",
          workspace.id,
          "workspaces_private",
          "data",
          "campaigns",
          campaignId
        )
      );

      console.log("exists", q.exists());

      if (callback) {
        callback({
          id: q.id,
          ...q.data(),
        });
      }
    } catch (e) {
      console.log("error fetching campaign", e);
    }
  }

  async function saveCampaign(campaignId, editedName) {
    let updatedData = {
      name: editedName,
    };
    updatedData = Object.fromEntries(
      Object.entries(updatedData).filter(([_, v]) => v != null)
    );

    const q = await setDoc(
      doc(
        db,
        "workspaces",
        workspace.id,
        "workspaces_private",
        "data",
        "campaigns",
        campaignId
      ),
      updatedData,
      { merge: true }
    );

    getCampaign();
  }

  return { getCampaign, saveCampaign };
}
