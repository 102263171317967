import { Grid } from "@mui/material";
import {
  collection,
  doc,
  getDocs,
  getFirestore,
  setDoc,
} from "firebase/firestore";
import { Field, Form, Formik } from "formik";
import { post } from "jsx/Api";
import { UserContext } from "jsx/context";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { CustomNoRowsOverlay } from "jsx/components/EmptyState";
import dayjs from "dayjs";
import { DataGrid } from "@mui/x-data-grid";
import _ from "lodash";

const initialValues = {
  text: "",
  // query2: "",
  // query3: "",
};

const columns = [
  {
    field: "transcriptName",
    headerName: "Name",
    width: 400,
    editable: false,
  },
  {
    field: "createdAt",
    headerName: "Created at",
    width: 400,
    editable: false,
    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }

      return dayjs(new Date(params.value.seconds * 1000)).fromNow();
    },
  },
];

export function GoalsForm({ transcripts, setTranscriptsAnswers, goal }) {
  const { workspace, user } = useContext(UserContext);
  const [isGeneratingGoals, setGeneratingGoals] = useState(false);
  const formRef = useRef();
  const { goalId } = useParams();
  const db = useMemo(() => getFirestore(), []);
  const [selectionModel, setSelectionModel] = useState([]);
  const [allTranscripts, setAllTranscripts] = useState([]);

  useEffect(() => {
    if (transcripts) {
      setSelectionModel(transcripts.map((e) => e.id));
    }
  }, [transcripts]);

  const handleSubmit = useCallback(
    async (values) => {
      if (!goalId) {
        console.log("goalId not defined");
        return;
      }

      let question;

      // Generate new question if goal is different
      if (values.text !== goal.text) {
        const r = await post("goals/statementToQuestion", {
          statement: values.text,
        });

        question = r.data;
      } else {
        question = goal.question;
      }

      setGeneratingGoals(true);
      setDoc(
        doc(
          db,
          "workspaces",
          workspace.id,
          "workspaces_private",
          "data",
          "goals",
          goalId
        ),
        {
          question: question,
          text: values.text,
        },
        { merge: true }
      );

      try {
        console.log("START00000");
        const promises = transcripts.map(async (transcript) => {
          await post(
            `transcripts/createTranscriptionQueryTask/${workspace.id}/${transcript.id}/${goalId}`,
            {
              query: question,
            }
          );
        });

        console.log("START11111");

        await Promise.all(promises);
        setGeneratingGoals(false);
      } catch (error) {
        console.log("error", error);
        toast.error("Generating answers failed");
        setGeneratingGoals(false);
      }
    },
    [db, goalId, transcripts, workspace.id]
  );

  const getTranscripts = useCallback(async () => {
    try {
      const q = await getDocs(
        collection(
          db,
          "workspaces",
          workspace.id,
          "workspaces_private",
          "data",
          "transcripts"
        )
      );

      let map = q.docs.map((e) => {
        return {
          id: e.id,
          ...e.data(),
        };
      });

      map = _.orderBy(map, ["createdAt"], ["desc"]);
      setAllTranscripts(map);
    } catch (e) {
      console.log("error fetching transcript", e);
    }
  }, [db, setAllTranscripts, user.uid]);

  useEffect(() => {
    getTranscripts();
  }, [getTranscripts]);

  console.log("selectionModel", selectionModel);

  return (
    <div>
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={{
          text: goal.text ?? initialValues.text,
        }}
        onSubmit={handleSubmit}
        style={{ height: "100%" }}
      >
        <Form
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <div
            style={{
              paddingRight: 24,
              paddingBottom: 48,
              marginLeft: 24,
            }}
          >
            <Grid container style={{ width: "100%" }}>
              <Grid item xs={12} md={12}>
                <div>
                  <label htmlFor="text" className="form-label">
                    Goal
                  </label>
                  <Field
                    id="text"
                    name="text"
                    placeholder={initialValues.text}
                    component="textarea"
                    rows="4"
                    className="form-textarea"
                  />
                </div>
              </Grid>
              <Grid container style={{ width: "100%" }}>
                <Grid item xs={12} md={12}>
                  <div>
                    <label
                      htmlFor="name"
                      className="form-label"
                      style={{ marginBottom: 8 }}
                    >
                      Transcripts
                    </label>
                    <Field
                      as="select"
                      name="color"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <option value="transcripts">Red</option>
                    </Field>

                    {/* <DataGrid
                      autoHeight
                      pageSize={20}
                      selectionModel={selectionModel}
                      onSelectionModelChange={(newSelectionModel) => {
                        setSelectionModel(newSelectionModel);
                      }}
                      rows={allTranscripts ?? []}
                      columns={columns}
                      checkboxSelection
                      disableSelectionOnClick
                      experimentalFeatures={{ newEditingApi: true }}
                      onRowClick={(e) => {}}
                      components={{
                        NoRowsOverlay: CustomNoRowsOverlay,
                      }}
                      style={{ minHeight: 400, background: "white" }}
                    /> */}
                    {/* {selectionError && (
                        <div class="form-error">
                          You need to add at least one transcript
                        </div>
                      )} */}
                  </div>
                </Grid>
              </Grid>
              {/* <Grid item xs={12} md={12}>
                <div>
                  <label htmlFor="query2" className="form-label">
                    Query 2
                  </label>
                  <Field
                    id="query2"
                    name="query2"
                    placeholder={initialValues.query2}
                    component="textarea"
                    rows="4"
                    className="form-textarea"
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={12}>
                <div>
                  <label htmlFor="query3" className="form-label">
                    Query 3
                  </label>
                  <Field
                    id="query3"
                    name="query3"
                    placeholder={initialValues.query3}
                    component="textarea"
                    rows="4"
                    className="form-textarea"
                  />
                </div>
              </Grid> */}
            </Grid>
          </div>
          <div
            style={{
              width: "100%",
              bottom: 0,
              position: "fixed",
            }}
          >
            <Grid container style={{ paddingRight: 303 }}>
              <Grid
                item
                lg={6}
                style={{
                  display: "flex",
                  background: "white",
                  borderTop: "1px solid #DDD",
                  zIndex: 99,
                  width: "100%",
                }}
              >
                <div style={{ flexGrow: 1 }} />
                <button
                  type="submit"
                  className={`cool-button ${
                    isGeneratingGoals ? "animate" : ""
                  }`}
                  style={{
                    marginTop: 16,
                    width: 130,
                    fontSize: 18,
                    marginBottom: 16,
                    marginRight: 16,
                  }}
                  disabled={isGeneratingGoals}
                >
                  {isGeneratingGoals ? "Generating" : "Generate"}
                </button>
              </Grid>
              <Grid item lg={6}></Grid>
            </Grid>
          </div>
        </Form>
      </Formik>
      <ToastContainer />
    </div>
  );
}
