import { Button } from "@mui/material";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import { useGoogleLogin } from "@react-oauth/google";
import { post } from "jsx/Api";
import AlertDialog from "jsx/components/PromptSection/components/AuthSendersSection/AuthSendersContent/GmailConnectDialog";
import { useState } from "react";

export function ConnectGmailButton(props) {
  const { onSuccess } = props;
  const [loading, setLoading] = useState(false);
  const [showGmail, setShowGmail] = useState(false);

  const login = useGoogleLogin({
    scope:
      "https://www.googleapis.com/auth/gmail.compose https://www.googleapis.com/auth/gmail.readonly",
    flow: "auth-code",
    redirect_uri: "postmessage",
    onSuccess: async (tokenResponse) => {
      setLoading(true);
      const res = await post("addGmailToken", { code: tokenResponse.code });
      onSuccess(res.data);
      setLoading(false);
    },
  });

  return (
    <>
      <AlertDialog
        login={login}
        open={showGmail}
        handleClose={() => {
          setShowGmail(false);
        }}
      />
      <Button
        onClick={() => {
          setShowGmail(true);
        }}
        variant="contained"
        disableElevation
        color="secondary"
        style={{ ...props.style }}
        disabled={loading}
        startIcon={
          loading ? (
            <CircularProgress
              variant="indeterminate"
              disableShrink
              sx={{
                color: (theme) =>
                  theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
                animationDuration: "550ms",
                left: 0,
                [`& .${circularProgressClasses.circle}`]: {
                  strokeLinecap: "round",
                },
              }}
              size={20}
              thickness={4}
            />
          ) : (
            <></>
          )
        }
      >
        {props.add ? "Add" : "Connect"} Gmail account
      </Button>
    </>
  );
}
