import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import GoogleButton from "react-google-button";
import { Add, AddOutlined, PlusOneOutlined } from "@mui/icons-material";

export default function AlertDialog({ open, handleClose, login }) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            alignItems: "center",
            padding: 24,
          }}
        >
          <div style={{ display: "flex" }}>
            <div className="logo-icon" style={{ width: 50, height: 50 }} />
            <Add
              fontSize="large"
              style={{
                color: "#AAA",
                marginTop: 8,
                marginLeft: 8,
                marginRight: 8,
              }}
            />
            <img
              src="https://cdn-icons-png.flaticon.com/512/281/281769.png"
              style={{ width: 50, height: 50 }}
            />
          </div>

          <h2>Connect your Gmail account</h2>
          <div style={{ marginTop: 0, marginBottom: 24 }}>
            <GoogleButton
              label="Connect with Google"
              onClick={() => {
                login();
              }}
            />
          </div>

          <div
            id="alert-dialog-description"
            style={{ fontSize: 14, color: "#333" }}
          >
            <div>
              Modern Sales requests access to your Gmail account in order to
              draft, send and read emails on your behalf. No emails will ever be
              sent without your explicit consent and no emails will ever be
              deleted. You can revoke access to your inbox at any time.{" "}
            </div>
            <div style={{ marginTop: 16 }}>
              In addition, Modern Sales use and transfer to any other app of
              information received from Google APIs will adhere to{" "}
              <span>
                <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes">
                  Google API Services User Data Policy
                </a>
              </span>
              , including the Limited Use requirements.
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
