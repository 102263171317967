import { Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

export function DemoForFree() {
  const navigate = useNavigate();

  function navigateToRegistration() {
    navigate("/signup");
  }

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <div
          style={{
            fontWeight: 500,
            letterSpacing: 0.6,
            fontSize: 14,
            marginBottom: -16,
          }}
        >
          WATCH THE INSTANT DEMO
        </div>
        <h2 style={{ marginBottom: 64, fontWeight: 600 }}>
          See how to create amazing content 10X faster with AI
        </h2>
      </div>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          md={12}
          style={{ flexDirection: "column", display: "flex" }}
        >
          <button
            className="try-for-free-button"
            style={{ width: "100%" }}
            onClick={navigateToRegistration}
          >
            <div style={{ marginTop: -2 }}>Try Modern Sales For Free</div>
            <div
              style={{
                fontSize: "14px",
                color: "white",
                fontWeight: 600,
                marginTop: 4,
              }}
            >
              Get 10,000 Credits
            </div>
          </button>
        </Grid>
      </Grid>
    </>
  );
}
