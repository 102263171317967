import { Button, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { addDoc, collection, getDocs, getFirestore } from "firebase/firestore";
import MSAnalytics from "jsx/api/analytics";
import { SideMenu } from "jsx/components";
import { UserContext } from "jsx/context";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { CampaignsList } from "./components";

export function Autopilot() {
  const db = getFirestore();
  const navigate = useNavigate();
  const { user, workspace } = useContext(UserContext);
  const [campaigns, setCampaigns] = useState();

  const uid = useParams()["userId"] ?? user.uid;

  useEffect(() => {
    if (uid) {
      getCampaigns();
    }
  }, [uid]);

  async function getCampaigns() {
    const q = await getDocs(
      collection(
        db,
        "workspaces",
        workspace.id,
        "workspaces_private",
        "data",
        "campaigns"
      )
    );

    let map = q.docs.map((e) => {
      return {
        id: e.id,
        ...e.data(),
      };
    });

    map = _.orderBy(map, ["createdAt"], ["desc"]);
    setCampaigns(map);

    if (map.length === 0) {
      createCampaign();
    }
  }

  async function createCampaign() {
    MSAnalytics.track("Button Clicked", {
      page: "Autopilot",
      buttonType: `Create Campaign`,
    });

    const result = await addDoc(
      collection(
        db,
        "workspaces",
        workspace.id,
        "workspaces_private",
        "data",
        "campaigns"
      ),
      {
        createdAt: new Date(),
        name: "Untitled",
        prompt: `<|im_start|>system
You are a [SENDER_JOB_TITLE] named [SENDER_FIRST_NAME]. [SENDER_FIRST_NAME] works for the company [SENDER_COMPANY]. [COMPANY_SUMMARY] [SENDER_FIRST_NAME] has the personality of Steve Jobs - he speaks with charisma and confidence while inspiring the user to book a sales meeting.
Context:
- [GOAL]`,
      }
    );

    navigate(`/autopilot/${result.id}?new=true`);
  }

  return (
    <>
      <Grid container>
        <SideMenu />
        <Box
          className="container"
          sx={{ flexGrow: 1, justifyContent: "center", alignItems: "center" }}
        >
          <Box sx={{ height: 400, maxWidth: 900, margin: "0 auto" }}>
            <Box
              sx={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h2>Autopilot</h2>
              <div style={{ flexGrow: 1 }}></div>
              <Button
                variant="contained"
                disableElevation
                onClick={createCampaign}
              >
                Create campaign
              </Button>
            </Box>
            <CampaignsList campaigns={campaigns} getCampaigns={getCampaigns} />
          </Box>
        </Box>
      </Grid>
    </>
  );
}
