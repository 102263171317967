import { InfoOutlined } from "@mui/icons-material";
import { Grid, Paper, Tooltip } from "@mui/material";
import React from "react";

import { AvailableCredits, BonusCredits } from "..";

export function BillingCredits({ subscriptionCredits, bonusCredits }) {
  return (
    <Grid container style={{ width: "100%", marginBottom: 16 }} spacing={2}>
      <AvailableCredits
        subscriptionCredits={subscriptionCredits}
        bonusCredits={bonusCredits}
      />

      <Grid item xs={4}>
        <Paper
          variant="outlined"
          style={{ padding: 24, paddingTop: 24, paddingBottom: 24 }}
        >
          <div style={{ display: "flex" }}>
            <div style={{ color: "#666", fontWeight: 500, fontSize: 14 }}>
              Subscription credits
            </div>
            <Tooltip
              title="These credits are available until the end of the current billing cycle then reset"
              placement="top"
            >
              <InfoOutlined
                sx={{
                  fontSize: 16,
                  color: "#CCC",
                  marginLeft: "8px",
                }}
              />
            </Tooltip>
          </div>
          <h2 style={{ margin: 0, marginTop: 8 }}>
            {subscriptionCredits.toLocaleString()}
          </h2>
        </Paper>
      </Grid>

      <BonusCredits bonusCredits={bonusCredits} />
    </Grid>
  );
}
