import { Grid, Paper, Skeleton } from "@mui/material";
import { UserContext } from "jsx/context";
import { useWorkspaceUsage } from "jsx/hooks";
import { useContext } from "react";
import Chart from "react-apexcharts";
import { useParams } from "react-router-dom";

export function UsageContent() {
  const { user } = useContext(UserContext);
  const uid = useParams()["userId"] ?? user.uid;

  const { creditsUsed, options, series } = useWorkspaceUsage(uid);

  if (!series.length) {
    return (
      <Skeleton
        variant="rectangular"
        height={400}
        style={{ marginBottom: 12, marginTop: 48 }}
      />
    );
  }

  return (
    <>
      <Grid container style={{ width: "100%" }}>
        {/* <Grid item xs={3}>
          <Paper
            variant="outlined"
            style={{ padding: 24, paddingTop: 24, paddingBottom: 24 }}
          >
            <div style={{ color: "#666", fontWeight: 500, fontSize: 14 }}>
              Credits available
            </div>
            <h2 style={{ margin: 0, marginTop: 8 }}>{creditsUsed}</h2>
          </Paper>
        </Grid> */}

        <Grid item xs={3} style={{ marginLeft: 16 }}>
          <Paper
            variant="outlined"
            style={{ padding: 24, paddingTop: 24, paddingBottom: 24 }}
          >
            <div style={{ color: "#666", fontWeight: 500, fontSize: 14 }}>
              Credits used
            </div>
            <h2 style={{ margin: 0, marginTop: 8 }}>{creditsUsed}</h2>
          </Paper>
        </Grid>
      </Grid>

      <Chart options={options} series={series} type="bar" width="100%" />
    </>
  );
}
