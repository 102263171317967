import { doc, getDoc, getFirestore } from "firebase/firestore";
import { UserContext } from "jsx/context";
import { useContext, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

export function useTranscript() {
  const db = getFirestore();
  const { workspace } = useContext(UserContext);
  const [searchParams] = useSearchParams();
  const [transcript, setTranscript] = useState();
  let { transcriptId } = useParams();

  useEffect(() => {
    if (!transcriptId) {
      transcriptId = searchParams.get("transcriptId");
    }

    if (transcriptId) {
      getTranscript(transcriptId);
    }
  }, [searchParams, transcriptId]);

  async function getTranscript(contactId) {
    const cc = await getDoc(
      doc(
        db,
        [
          "workspaces",
          workspace.id,
          "workspaces_private",
          "data",
          "transcripts",
          transcriptId,
        ].join("/")
      )
    );

    setTranscript({ id: cc.id, ...cc.data() });
  }

  return { transcript, setTranscript, getTranscript };
}
