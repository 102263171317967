import { ArticleOutlined, CopyAllOutlined } from "@mui/icons-material";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  TextareaAutosize,
  Tooltip,
} from "@mui/material";
import dayjs from "dayjs";
import { UserContext } from "jsx/context";
import React, { useContext, useState } from "react";

export function TranscriptSummaryCell({ style, transcript, onSummaryChange }) {
  const { user, isSiteAdminUNSAFE } = useContext(UserContext);
  const [viewPrompt, setViewPrompt] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  let buttons;
  let promptSection;

  function handleTooltipOpen() {
    setTooltipOpen(true);
    setTimeout(() => {
      setTooltipOpen(false);
    }, 2000);
  }

  return (
    <div style={{ ...style }}>
      <Grid container style={{ width: "100%" }}>
        <Grid item xs={12}>
          <label htmlFor="summary" className="form-label">
            Summary
          </label>
          <Tooltip title={tooltipOpen ? "Copied" : "Copy"} placement="top">
            <IconButton
              aria-label="copy"
              size="small"
              className="small-icon-button"
              placement="top"
              onClick={() => {
                navigator.clipboard.writeText(transcript?.summary);
                handleTooltipOpen();
              }}
            >
              <CopyAllOutlined fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={12}>
          <TextareaAutosize
            onChange={onSummaryChange}
            defaultValue={transcript?.summary.trim()}
            id="query"
            name="query"
            type="query"
            className="form-textarea"
            component="textarea"
            rows="3"
            style={{ background: "white", overflow: "scroll" }}
          />
        </Grid>
      </Grid>
      <Grid container>{promptSection}</Grid>
    </div>
  );
}
