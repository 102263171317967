import { createTheme } from "@mui/material";

export const getMainTheme = (darkMode) =>
  createTheme({
    typography: {
      button: {
        textTransform: "none",
      },

      fontFamily: [
        "Roboto Slab",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },

    palette: {
      primary: {
        // main: "#6366f1",
        main: "#6366f1",
      },
      secondary: {
        main: "#F0F0F0",
      },
      gray: {
        main: "#333",
      },
      blue: {
        main: "#1a90ff",
      },
      green: {
        main: "#16A34A",
      },
      mode: darkMode ? "dark" : "light",
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 2,
            fontFamily: [
              "-apple-system",
              "BlinkMacSystemFont",
              '"Segoe UI"',
              "Roboto",
              '"Helvetica Neue"',
              "Arial",
              "sans-serif",
              '"Apple Color Emoji"',
              '"Segoe UI Emoji"',
              '"Segoe UI Symbol"',
            ].join(","),
          },
        },
      },

      // Name of the component
      MuiButtonBase: {
        defaultProps: {
          // The props to change the default for.
          disableRipple: true, // No more ripple, on the whole application 💣!
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          // Name of the slot
          root: {
            "& fieldset": {
              borderColor: "#E9EBF0",
            },

            "&:hover fieldset": {
              borderColor: "red",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#3E68A8",
            },
          },
        },
      },
    },
  });
