import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  setDoc,
} from "firebase/firestore";
import { UserContext } from "jsx/context";
import { useContext } from "react";

export function useConservationsService(campaignId, leadId) {
  const db = getFirestore();
  const { user, workspace } = useContext(UserContext);

  async function getConversation(callback) {
    const qs = await getDocs(
      collection(
        db,
        "workspaces",
        workspace.id,
        "workspaces_private",
        "data",
        "campaigns",
        campaignId,
        "leads",
        leadId,
        "conversations"
      )
    );

    if (qs.docs.length > 0) {
      const q = qs.docs[0];

      callback({
        id: q.id,
        ...q.data(),
      });
    } else {
      // Sometimes conversations doesn't exist
      // WE just create it if it doesn't exists

      const camapignsCollection = await getDoc(
        doc(
          db,
          [
            "workspaces",
            workspace.id,
            "workspaces_private",
            "data",
            "campaigns",
            campaignId,
          ].join("/")
        )
      );

      const campaign = camapignsCollection.data();

      const conversation = await addDoc(
        collection(
          db,
          "workspaces",
          workspace.id,
          "workspaces_private",
          "data",
          "campaigns",
          campaignId,
          "leads",
          leadId,
          "conversations"
        ),
        {
          createdAt: new Date(),
          goal: campaign.goal,
          prompt: campaign.prompt,
          authedSender: campaign.authedSender,
        }
      );

      callback({
        id: conversation.id,
        createdAt: new Date(),
        goal: campaign.goal,
        prompt: campaign.prompt,
        authedSender: campaign.authedSender,
      });
    }
  }

  async function saveConversation(values, conversationId, callback) {
    const q = await setDoc(
      doc(
        db,
        "workspaces",
        workspace.id,
        "workspaces_private",
        "data",
        "campaigns",
        campaignId,
        "leads",
        leadId,
        "conversations",
        conversationId
      ),
      values,
      { merge: true }
    );

    callback();
  }

  return { getConversation, saveConversation };
}
