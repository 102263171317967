import { doc, getDoc, getFirestore } from "firebase/firestore";
import { UserContext } from "jsx/context";
import { useContext, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

export function useContact() {
  const db = getFirestore();
  const { workspace } = useContext(UserContext);
  const [searchParams] = useSearchParams();
  const [contact, setContact] = useState();
  let { contactId } = useParams();

  useEffect(() => {
    if (!contactId) {
      contactId = searchParams.get("contactId");
    }

    if (contactId) {
      getContact(contactId);
    }
  }, [searchParams, contactId]);

  async function getContact(contactId) {
    const cc = await getDoc(
      doc(
        db,
        [
          "workspaces",
          workspace.id,
          "workspaces_private",
          "data",
          "contacts",
          contactId,
        ].join("/")
      )
    );

    setContact({ id: cc.id, ...cc.data() });
  }

  return { contact, setContact };
}
