import {
  DraftsOutlined,
  EmailOutlined,
  UnfoldLessOutlined,
} from "@mui/icons-material";
import { Button, Grid, IconButton, Tooltip } from "@mui/material";
import {
  addDoc,
  collection,
  doc,
  getFirestore,
  setDoc,
} from "firebase/firestore";
import { Field, Form, Formik } from "formik";
import { UserContext } from "jsx/context";
import React, { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormikContext } from "formik";
import { post } from "jsx/Api";

export function ContactExpandedCell({
  contact,
  onUpdate,
  onCollapse,
  onAddContact,
  submitRef,
  newContact,
}) {
  const { workspace } = useContext(UserContext);

  const navigate = useNavigate();

  return (
    <Formik
      enableReinitialize
      initialValues={{
        firstName: contact?.firstName ?? newContact?.firstName ?? "",
        lastName: contact?.lastName ?? newContact?.lastName ?? "",
        website: contact?.website ?? newContact?.website ?? "",
        companyName: contact?.companyName ?? newContact?.companyName ?? "",
        jobTitle: contact?.jobTitle ?? newContact?.jobTitle ?? "",
        location: contact?.location ?? newContact?.location ?? "",
        interests: contact?.interests ?? newContact?.interests ?? "",
        summary: contact?.summary ?? newContact?.summary ?? "",
      }}
      onSubmit={async (values) => {
        const db = getFirestore();

        if (contact.id) {
          const meep = await setDoc(
            doc(
              db,
              [
                "workspaces",
                workspace.id,
                "workspaces_private",
                "data",
                "contacts",
                contact.id,
              ].join("/")
            ),
            { ...values, updatedAt: new Date() },
            { merge: true }
          );
        } else {
          const cc = await addDoc(
            collection(
              db,
              [
                "workspaces",
                workspace.id,
                "workspaces_private",
                "data",
                "contacts",
              ].join("/")
            ),
            { ...values, createdAt: new Date(), source: "contacts" }
          );

          await post("workspaces/completeOnboardingStep", {
            onboardingStep: "createContact",
          });

          navigate(`/contacts/${cc.id}`);
        }

        if (onUpdate) {
          onUpdate();
        }
      }}
      style={{ height: "100%", width: "100%" }}
    >
      <Form
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
        }}
      >
        {onCollapse && (
          <div style={{ display: "flex" }}>
            <div style={{ flexGrow: 1 }} />
            <IconButton
              style={{ color: "#CCC", marginBottom: -16 }}
              onClick={(e) => {
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
                onCollapse();
              }}
            >
              <UnfoldLessOutlined />
            </IconButton>
          </div>
        )}
        <Grid container style={{ width: "100%", marginTop: -32 }}>
          <div style={{ flexGrow: 1 }} />

          <Grid item xs={12}>
            <div>
              <label htmlFor="firstName" className="form-label">
                First name
              </label>
              <Field id="firstName" name="firstName" className="form-input" />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <label htmlFor="lastName" className="form-label">
                Last name
              </label>
              <Field id="lastName" name="lastName" className="form-input" />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <label htmlFor="website" className="form-label">
                Website
              </label>
              <Field id="website" name="website" className="form-input" />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div>
              <label htmlFor="companyName" className="form-label">
                Company name
              </label>
              <Field
                id="companyName"
                name="companyName"
                className="form-input"
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <label htmlFor="jobTitle" className="form-label">
                Job title
              </label>
              <Field id="jobTitle" name="jobTitle" className="form-input" />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div>
              <label htmlFor="location" className="form-label">
                Location
              </label>
              <Field id="location" name="location" className="form-input" />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div>
              <label htmlFor="interests" className="form-label">
                Interests
              </label>
              <Field
                id="interests"
                name="interests"
                component="textarea"
                rows="2"
                className="form-textarea"
                widht="100%"
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div>
              <label htmlFor="summary" className="form-label">
                Summary
              </label>
              <Field
                id="summary"
                name="summary"
                component="textarea"
                rows="6"
                className="form-textarea"
                widht="100%"
              />
            </div>
          </Grid>
          <div style={{ display: "flex", width: "100%" }}>
            <div style={{ flexGrow: 1 }} />
            <div
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                margin: 8,
                zIndex: 1,
              }}
            ></div>
          </div>
        </Grid>
        <button ref={submitRef} type="submit" style={{ display: "none" }} />
      </Form>
    </Formik>
  );
}
