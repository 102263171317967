import { Button, FormHelperText, TextField } from "@mui/material";
import { Form, Formik } from "formik";
import _ from "lodash";
import React from "react";

import { editConversationFormSchema } from "./edit-conversation-form.schema";

export function EditConversationForm({
  conversation,
  showAdvanced,
  isSiteAdminUNSAFE,
  getConversation,
  saveConversation,
  setShowAdvanced,
  setShowCampaignSection,
  setShowConversationSection,
}) {
  return (
    <Formik
      enableReinitialize
      initialValues={{
        goal: conversation?.goal ?? "",
        //   senderCaseStudies: campaign?.senderCaseStudies ?? "",
        //   senderCustomerWins: campaign?.senderCustomerWins ?? "",
        //   constraints: campaign?.constraints ?? "",
        //   senderOtherInfo: campaign?.senderOtherInfo ?? "",
        prompt: conversation?.prompt ?? "",
      }}
      validationSchema={editConversationFormSchema}
      onSubmit={async (values) => {
        const filtered = _(values)
          .omitBy(_.isEmpty)
          .omitBy(_.isUndefined)
          .omitBy(_.isNull)
          .value();

        await saveConversation(filtered);
        setShowConversationSection(false);
        await getConversation();
      }}
    >
      {({ handleChange, errors, values, isSubmitting }) => (
        <Form>
          <h5 style={{ marginBottom: 12 }}>Goal of the outreach *</h5>
          <TextField
            size="small"
            multiline={true}
            placeholder="Your main goal is to set up a meeting. To do so, you can offer this link calendly.com/modernsales."
            style={{ width: "100%" }}
            onChange={handleChange}
            value={values.goal}
            name="goal"
          />
          {errors.goal && <FormHelperText error>Required</FormHelperText>}
          {/* <h5 style={{ marginBottom: 12, marginTop: 32 }}>
                  Case studies
                </h5>
                <TextField
                  size="small"
                  multiline={true}
                  placeholder="Modern Sales boosted qualified leads for Acme, Inc by 345% in the first month, which led to annual contracts worth over $1.1M."
                  style={{ width: "100%" }}
                  onChange={handleChange}
                  value={values.senderCaseStudies}
                  name="senderCaseStudies"
                />
                <h5 style={{ marginBottom: 12, marginTop: 32 }}>
                  Other customer wins
                </h5>
                <TextField
                  size="small"
                  multiline={true}
                  placeholder="Never repeat the same text in consecutive messages."
                  style={{ width: "100%" }}
                  onChange={handleChange}
                  value={values.senderCustomerWins}
                  name="senderCustomerWins"
                />
                <h5 style={{ marginBottom: 12, marginTop: 32 }}>Constraints</h5>
                <TextField
                  size="small"
                  multiline={true}
                  placeholder="Never send any other links."
                  style={{ width: "100%" }}
                  onChange={handleChange}
                  value={values.constraints}
                  name="constraints"
                />
                <h5 style={{ marginBottom: 12, marginTop: 32 }}>
                  Other information that can be useful
                </h5>
                <TextField
                  size="small"
                  multiline={true}
                  placeholder="Sell widgets, be polite"
                  style={{ width: "100%" }}
                  onChange={handleChange}
                  value={values.senderOtherInfo}
                  name="senderOtherInfo"
                /> */}
          {showAdvanced && (
            <>
              <h5 style={{ marginBottom: 12, marginTop: 32 }}>Prompt</h5>
              <TextField
                multiline={true}
                placeholder="Prompt"
                style={{ width: "100%" }}
                onChange={handleChange}
                value={values.prompt}
                name="prompt"
              />
            </>
          )}
          <div style={{ marginTop: 32 }}>
            <Button
              variant="contained"
              disableElevation
              type="submit"
              disabled={isSubmitting}
              style={{ marginRight: 12 }}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              disableElevation
              type="submit"
              disabled={isSubmitting}
              onClick={() => {
                setShowCampaignSection(false);
              }}
            >
              Cancel
            </Button>
            {isSiteAdminUNSAFE && (
              <div
                style={{
                  cursor: "pointer",
                  marginTop: 24,
                  fontSize: 14,
                  color: "#999",
                  float: "right",
                  marginBottom: 32,
                }}
                onClick={() => {
                  setShowAdvanced(!showAdvanced);
                }}
              >
                {showAdvanced ? "Hide" : "Show"} advanced
              </div>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
}
