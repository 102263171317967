import { useEffect, useState } from "react";

const getStorageData = (keyName, defaultValue) => {
  const savedItem = localStorage.getItem(keyName);
  const parsedItem = JSON.parse(savedItem);
  return parsedItem || defaultValue;
};

export function useLocalStorage(keyName, initialValue = "") {
  const [value, setValue] = useState(() => {
    return getStorageData(keyName, initialValue);
  });

  function removeFromStorage() {
    localStorage.removeItem(keyName);
  }

  useEffect(() => {
    localStorage.setItem(keyName, JSON.stringify(value));
  }, [keyName, value]);

  return [value, setValue, removeFromStorage];
}
