import { Box, Button, TextField } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import Modal from "@mui/material/Modal";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  setDoc,
} from "firebase/firestore";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { UserContext } from "jsx/context";
import _ from "lodash";
import { useContext, useState } from "react";
import * as Yup from "yup";

const LeadForm = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  role: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
});

const style = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 560,
  bgcolor: "background.paper",
  border: "2px solid #333",
  boxShadow: 24,
  p: 4,
};

export function AddTeamMemberModal({ showModal, shouldHideModal, teamMember }) {
  const [loading, setLoading] = useState(false);
  const db = getFirestore();
  const { user, workspace } = useContext(UserContext);

  return (
    <Modal
      open={showModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onBackdropClick={() => {
        shouldHideModal();
      }}
    >
      <Box sx={style}>
        <h2 id="modal-modal-title">
          {teamMember ? "Edit" : "Add"} team member
        </h2>
        <div>
          <Formik
            enableReinitialize
            initialValues={{
              firstName: teamMember?.firstName ?? "",
              email: teamMember?.email ?? "",
              role: teamMember?.role ?? "admin",
            }}
            validationSchema={LeadForm}
            onSubmit={async (values) => {
              setLoading(true);
              const filtered = _(values)
                .omitBy(_.isEmpty)
                .omitBy(_.isUndefined)
                .omitBy(_.isNull)
                .value();

              const email = values.email.toLowerCase();

              console.log("0000");
              const userRef = await getDoc(
                doc(
                  db,
                  "workspaces",
                  workspace.id,
                  "workspaces_private",
                  "data",
                  "team",
                  email
                )
              );
              console.log("11111");

              const { firstName, role } = values;
              console.log("2222", workspace);

              if (!userRef.exists()) {
                const inviteCode = uuidv4();
                await setDoc(
                  doc(
                    db,
                    "workspaces",
                    workspace.id,
                    "workspaces_private",
                    "data",
                    "team",
                    email
                  ),
                  {
                    firstName,
                    email,
                    role,
                    status: "invited",
                    createdAt: new Date(),
                    inviteCode,
                    inviteLink: `/workspaces/${
                      workspace.id
                    }/accept/${inviteCode}/${btoa(workspace.name)}`,
                  },
                  { merge: true }
                );
              }
              console.log("3333", workspace.name);

              setLoading(false);
              shouldHideModal(true);
            }}
          >
            {({ handleChange, errors, values }) => (
              <Form>
                <label htmlFor="firstName" className="form-label">
                  First Name*
                </label>
                <Field
                  id="firstName"
                  name="firstName"
                  placeholder="Jane"
                  className="form-input"
                />
                <ErrorMessage
                  name="firstName"
                  className="form-error"
                  component="div"
                />
                <label htmlFor="firstName" className="form-label">
                  Email*
                </label>
                <Field
                  id="email"
                  name="email"
                  placeholder="jane@email.com"
                  className="form-input"
                />
                <ErrorMessage
                  name="email"
                  className="form-error"
                  component="div"
                />

                <label htmlFor="companySize" className="form-label">
                  Role*
                </label>
                <Field as="select" id="role" name="role" e>
                  <option value="admin">Admin</option>
                  <option value="member">Member</option>
                </Field>
                <ErrorMessage
                  name="role"
                  className="form-error"
                  component="div"
                />

                <div style={{ marginTop: 32, float: "right" }}>
                  <Button
                    size="large"
                    variant="contained"
                    disableElevation
                    color="secondary"
                    style={{ marginRight: 16 }}
                    onClick={() => {
                      shouldHideModal();
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    size="large"
                    variant="contained"
                    disableElevation
                    type="submit"
                    disabled={loading}
                  >
                    {teamMember ? "Save" : "Add"}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Box>
    </Modal>
  );
}

function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}
