import React from "react";
import {
  ContentCopy,
  CopyAllOutlined,
  FileUpload,
  UploadOutlined,
} from "@mui/icons-material";
import { Box, Card, Grid, Paper, Typography } from "@mui/material";

export const ChooseImportMethod = ({ onChoose }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "stretch", gap: 4 }}>
      <Grid item xs={4}>
        <Paper
          className="integration-cell"
          variant="outlined"
          style={{
            padding: 24,
            paddingTop: 32,
            paddingBottom: 32,
          }}
          onClick={() => onChoose("file")}
        >
          <UploadOutlined fontSize="large" style={{ color: "#333" }} />
          <div style={{ marginBottom: 8, marginTop: 8, fontSize: 18 }}>
            Upload a file
          </div>
          <div style={{ color: "#888" }}>
            Import contacts from a CSV or tab-delimited TXT file.
          </div>
        </Paper>
      </Grid>

      <Grid item xs={4}>
        <Paper
          className="integration-cell"
          variant="outlined"
          style={{
            padding: 24,
            paddingTop: 32,
            paddingBottom: 32,
          }}
          onClick={() => onChoose("text")}
        >
          <CopyAllOutlined fontSize="large" style={{ color: "#333" }} />
          <div style={{ marginBottom: 8, marginTop: 8, fontSize: 18 }}>
            Copy and paste
          </div>
          <div style={{ color: "#888" }}>
            Directly paste in new contacts from a spreadsheet or similar list.
          </div>
        </Paper>
      </Grid>
    </Box>
  );
};
