import GoogleIcon from "@mui/icons-material/Google";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Fade,
  Grid,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import {
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { Form, Formik } from "formik";
import MSAnalytics from "jsx/api/analytics";
import { logAnalytics } from "jsx/Logging";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import GoogleButton from "react-google-button";

import { UserContext } from "../../context/UserContext";

export function SignInButton({ sx } = { sx: {} }) {
  const [modalOpen, setModalOpen] = useState(true);
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "min(90%, 1260px)",
    width: 600,
    minHeight: 680,
    boxShadow: "0px 4px 10px rgba(0,0,0, 0.2)",
    overflow: "hidden",
    overflowY: "auto",
    borderRadius: 16,
  };

  return (
    <>
      <Button
        onClick={() => setModalOpen(true)}
        sx={sx}
        variant="contained"
        disableElevation
      >
        Sign in
      </Button>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Fade in={modalOpen}>
          <Paper style={modalStyle}>
            <Grid
              container
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
              sx={{ height: 500 }}
            >
              <Grid item xs>
                <SignInForm isSignin={true} />
              </Grid>
            </Grid>
          </Paper>
        </Fade>
      </Modal>
    </>
  );
}

export function SignInForm({ title, subtitle, isSignin, redirectUrl }) {
  const { workspaceId, inviteCode, workspaceName } = useParams();

  const [errorMessage, setErrorMessage] = useState();
  const [signin, setIsSignIn] = useState(isSignin);
  const { user } = useContext(UserContext);

  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  const [loading, setIsLoading] = useState(false);
  const [showSignUpWithEmail, setShowSignUpWithEmail] = useState(true);

  const SignUpSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string().required("Required"),
  });

  const SignInSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string().required("Required"),
  });
  const navigate = useNavigate();

  const signInWithGoogle = (e) => {
    if (signin) {
      MSAnalytics.track("Button Clicked", {
        page: "Login",
        buttonType: "Login with Google",
      });
    } else {
      MSAnalytics.track("Button Clicked", {
        page: "Login",
        buttonType: "Sign up with Google",
      });
    }
    signInWithPopup(auth, provider)
      .then(async (result) => {
        logAnalytics("signin_complete", {
          type: "email_password",
        });
        console.log("result", result);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
      } else {
        // User is signed out
        // ...
      }
    });

    logAnalytics("viewed_signin", {});
  }, []);

  const db = getFirestore();

  function constructQueryParmas() {
    const searchParams = new URLSearchParams();
    if (redirectUrl) {
      searchParams.append("redirectUrl", redirectUrl);
    }

    return searchParams.toString();
  }

  useEffect(() => {
    if (user.uid) {
      if (!inviteCode) {
        navigate(`/dashboard` + document.location.search, { replace: true });
      }
    }
  }, [user]);

  function signUpWithEmailSection(formik) {
    return (
      <>
        <Form>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            {!signin && (
              <Grid item xs sx={{ width: "100%", mb: 2 }}>
                <TextField
                  placeholder="Name"
                  type="text"
                  name="fullName"
                  label="Name"
                  value={formik.values.fullName}
                  onChange={formik.handleChange}
                  sx={{ width: "100%", background: "white" }}
                />
              </Grid>
            )}
            <Grid item xs sx={{ width: "100%", mb: 2 }}>
              <TextField
                type="text"
                name="email"
                placeholder="email@domain.com"
                value={formik.values.email}
                onChange={formik.handleChange}
                label="Email"
                sx={{ width: "100%", background: "white" }}
              />
            </Grid>
            <Grid item xs sx={{ width: "100%", mb: 2 }}>
              <TextField
                type="password"
                name="password"
                placeholder="Password"
                value={formik.values.password}
                onChange={formik.handleChange}
                label="Password"
                sx={{ width: "100%", background: "white" }}
              />
            </Grid>

            <Grid item xs sx={{ width: "100%", mb: 2 }}>
              <LoadingButton
                className="me-2 w-100 mt-3 mb-0"
                variant="outlined"
                type="submit"
                sx={{ width: "100%", height: 50 }}
                size="large"
                style={{ textTransform: "none" }}
                loading={loading}
              >
                {signin ? "Log in with email" : "Sign up with email"}
              </LoadingButton>
            </Grid>
            <Grid item xs>
              {signin && (
                <Typography
                  className="mb-0 mt-3 small text-center"
                  color="#AAA"
                  fontSize={12}
                >
                  Don't have an account?{" "}
                  <span
                    onClick={() => {
                      setIsSignIn(false);
                    }}
                  >
                    <span
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                    >
                      Sign up
                    </span>
                  </span>
                </Typography>
              )}

              {!signin && (
                <Typography
                  textAlign="center"
                  className="mb-0 mt-3 small text-center"
                  color="#AAA"
                  fontSize={12}
                >
                  Already have an account?{" "}
                  <span
                    onClick={() => {
                      MSAnalytics.track("Button Clicked", {
                        page: "Login",
                        buttonType: "Already have an account?",
                      });

                      setIsSignIn(true);
                    }}
                  >
                    <span
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                    >
                      Log in
                    </span>
                  </span>
                </Typography>
              )}
            </Grid>

            {errorMessage && (
              <Grid item xs>
                <Typography
                  textAlign="center"
                  className="mb-0 mt-3 small is-invalid"
                >
                  {errorMessage}
                </Typography>
              </Grid>
            )}

            {/* <Grid item xs>
              <Link to={"/reset?" + constructQueryParmas()}>
                <Typography
                  className="mb-0 mt-3 small text-center"
                  style={{ textDecoration: "underline" }}
                >
                  Forgot your password?
                </Typography>
              </Link>
            </Grid> */}
          </Grid>
        </Form>
      </>
    );
  }

  return (
    <Grid
      container
      direction="column"
      // justifyContent="center"
      // alignItems="center"
      sx={{ height: "100%", width: "100%", p: 2, pt: 10 }}
    >
      <div className="logo-icon" style={{ marginBottom: 16 }} />

      <Grid>
        <Typography
          variant="h5"
          style={{ fontSize: 32, fontWeight: 500, color: "#444" }}
        >
          {title ?? signin ? "Login" : "Sign up"}
        </Typography>
        <Typography
          variant="body2"
          sx={{ py: 2, color: "#999", fontWeight: 300 }}
        >
          {subtitle ?? signin
            ? "Login to your account"
            : "Create a free account."}
        </Typography>

        <Grid item xs>
          <GoogleButton
            onClick={signInWithGoogle}
            startIcon={<GoogleIcon />}
            variant="contained"
            disableElevation
            size="large"
            style={{
              textTransform: "none",
              width: "100%",
              height: 50,
              fontWeight: 600,
            }}
            label={signin ? "Login with Google" : "Sign up with Google"}
          ></GoogleButton>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{ py: 3 }}
        style={{ flexDirection: "row", display: "flex" }}
      >
        <div
          style={{
            height: "1px",
            flexGrow: 1,
            background: "#EEE",
            marginTop: 14,
          }}
        ></div>
        <Typography
          variant="overline"
          color={"#CCC"}
          style={{ paddingLeft: 16, paddingRight: 16 }}
        >
          OR
        </Typography>
        <div
          style={{
            height: "1px",
            flexGrow: 1,
            background: "#EEE",
            marginTop: 14,
          }}
        ></div>
      </Grid>
      <Grid item xs>
        <Formik
          initialValues={{ fullName: "", email: "", password: "" }}
          validationSchema={signin ? SignInSchema : SignUpSchema}
          onSubmit={async (values) => {
            if (signin) {
              MSAnalytics.track("Button Clicked", {
                page: "Login",
                buttonType: "Login with Email",
              });
            } else {
              MSAnalytics.track("Button Clicked", {
                page: "Login",
                buttonType: "Sign up with Email",
              });
            }

            const auth = getAuth();
            const email = values.email;
            const password = values.password;
            const fullName = values.fullName;
            setIsLoading(true);
            setErrorMessage();

            if (signin) {
              signInWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                  logAnalytics("signin_complete", {
                    type: "email_password",
                  });
                })
                .catch((error) => {
                  const errorCode = error.code;
                  /* const errorMessage = error.message; */

                  if (errorCode === "auth/user-not-found") {
                    setErrorMessage("No user with that email.");
                  } else if (errorCode === "auth/wrong-password") {
                    setErrorMessage("Wrong password");
                  }

                  setIsLoading(false);
                });

              return;
            }

            createUserWithEmailAndPassword(auth, email, password)
              .then(async (userCredential) => {
                logAnalytics("signup_complete", {
                  type: "email_password",
                });

                // Signed in
                // const user = userCredential.user;
                await setDoc(
                  doc(
                    db,
                    "users",
                    auth.currentUser.uid,
                    "users_private",
                    "data"
                  ),
                  { displayName: fullName },
                  { merge: true }
                );
                // await sendEmailVerification(auth.currentUser).then(() => {});
              })
              .catch((error) => {
                setIsLoading(false);
                const errorCode = error.code;
                /* const errorMessage = error.message; */
                if (error.code === "auth/email-already-in-use") {
                  signInWithEmailAndPassword(auth, email, password)
                    .then((userCredential) => {
                      // Signed in
                      const user = userCredential.user;
                    })
                    .catch((error) => {
                      const errorCode = error.code;
                      /* const errorMessage = error.message; */

                      if (errorCode === "auth/wrong-password") {
                        setErrorMessage("Wrong password.");
                      }
                    });
                }
                if (errorCode === "auth/weak-password") {
                  setErrorMessage("Password is too weak.");
                }
                console.log(error.code);
              });
          }}
        >
          {(formik) => (
            <>
              {!showSignUpWithEmail && (
                <div
                  className="mt-1 small text-center"
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    marginBottom: -16,
                  }}
                  onClick={() => {
                    setShowSignUpWithEmail(true);
                  }}
                >
                  {signin && "Or log in with email"}
                  {!signin && "Or sign up with email"}
                </div>
              )}
              {showSignUpWithEmail && signUpWithEmailSection(formik)}
            </>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
}
