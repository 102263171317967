import { DeleteOutline } from "@mui/icons-material";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Skeleton,
  Tooltip,
} from "@mui/material";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import dayjs from "dayjs";
import {
  collection,
  collectionGroup,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import { CustomCircularProgress, SideMenu } from "jsx/components";
import { CustomNoRowsOverlay } from "jsx/components/EmptyState";
import { UserContext } from "jsx/context";
import _ from "lodash";
import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Link, useNavigate } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const columns = [
  // { field: "id", headerName: "ID", width: 90 },
  {
    field: "name",
    headerName: "Name",
    width: 260,
    editable: false,
  },
  {
    field: "createdAt",
    headerName: "Created at",
    width: 200,
    editable: false,
    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }

      return dayjs(new Date(params.value.seconds * 1000)).fromNow();
    },
  },
  {
    field: "transcripts",
    headerName: "Tagged transcripts",
    width: 200,
    editable: false,
    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }

      return params.value.length;
    },
  },
];

export function Goals() {
  const { workspace, user } = useContext(UserContext);
  const navigate = useNavigate();
  const [goals, setGoals] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [loadGoals, setLoadGoals] = useState(true);
  const [selectionModel, setSelectionModel] = useState([]);

  const db = useMemo(() => getFirestore(), []);

  let content;
  if (goals) {
    content = (
      <>
        <DataGrid
          autoHeight
          pageSize={20}
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          rows={goals}
          columns={columns}
          checkboxSelection
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          onRowClick={(e) => {
            navigate(`/goals/${e.id}`);
          }}
          components={{
            NoRowsOverlay: CustomNoRowsOverlay,
          }}
          style={{ minHeight: 400 }}
        />
      </>
    );
  } else {
    content = (
      <Skeleton variant="rectangular" height={400} style={{ marginTop: 32 }} />
    );
  }

  const getGoals = useCallback(async () => {
    const res = await getDocs(
      collection(
        db,
        "workspaces",
        workspace.id,
        "workspaces_private",
        "data",
        "goals"
      )
    );
    let map = res.docs.map((e) => {
      return {
        id: e.id,
        ...e.data(),
      };
    });

    map = _.orderBy(map, ["createdAt"], ["desc"]);

    setGoals(map);
    setLoadGoals(false);
  }, [db, workspace.id]);

  useEffect(() => {
    getGoals();
  }, [getGoals]);

  async function deleteSelected() {
    for (const e of selectionModel) {
      await deleteDoc(
        doc(
          db,
          "workspaces",
          workspace.id,
          "workspaces_private",
          "data",
          "goals",
          e
        )
      );
    }

    await getGoals();
  }

  let selectedSection;

  if (selectionModel.length) {
    selectedSection = (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            justifyItems: "start",
            alignItems: "start",
          }}
        >
          <div style={{ fontWeight: 500, marginTop: 16, color: "#333" }}>
            Selected {selectionModel.length}
          </div>
          <Tooltip title="Delete" placement="top">
            <IconButton
              style={{ marginTop: 10, marginBottom: -2, marginLeft: 16 }}
              aria-label="copy"
              size="small"
              className="small-icon-button"
              placement="top"
              onClick={() => {
                deleteSelected();
              }}
            >
              <DeleteOutline fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </div>
      </>
    );
  } else {
    selectedSection = <div style={{ height: 36 }}></div>;
  }

  return (
    <>
      <Grid container style={{ minHeight: "100vh" }}>
        <Box
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "start",
            alignItems: "start",
            minHeight: "100%",
          }}
        >
          <SideMenu />
          <Box
            className="container"
            sx={{ flexGrow: 1, justifyContent: "center", alignItems: "center" }}
          >
            <Box sx={{ height: 400, maxWidth: 900, margin: "0 auto" }}>
              <Box
                sx={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: 2,
                }}
              >
                <div>
                  <h2 style={{ marginBottom: 0 }}>Goals</h2>
                  {selectedSection}
                </div>

                <div style={{ flexGrow: 1 }}></div>
                <Link to={"/goals/new"} style={{ textDecoration: "none" }}>
                  <Button variant="contained" disableElevation>
                    New Goal
                  </Button>
                </Link>
              </Box>
              {content}
            </Box>
          </Box>
        </Box>
      </Grid>

      {/* <Grid container style={{ minHeight: "100vh" }}>
        <Box
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "start",
            alignItems: "start",
            minHeight: "100%",
          }}
        >
          <SideMenu />
          <Grid
            container
            spacing={4}
            style={{
              flexGrow: 1,
              background: "#F9FAFC",
              height: "100%",
            }}
          >
            <Grid
              item
              style={{
                flexGrow: 1,
                height: "calc(100vh - 16px)",
                overflowY: "auto",
                paddingTop: 0,
                width: "100%",
              }}
            >
              <div
                style={{
                  background: "white",
                  display: "flex",
                  flexDirection: "column",
                  padding: 24,
                  borderBottom: "1px solid #E9E9E9",
                }}
              >
                <h3 style={{ marginBottom: 4, fontWeight: 500 }}>Goals</h3>
              </div>
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    width: "400px",
                    maxHeight: "300px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  {loadGoals && <CustomCircularProgress />}
                  {goals.length > 0 ? (
                    <Box
                      sx={{
                        width: "500px",
                        maxHeight: "300px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "10px",
                        flexWrap: "wrap",
                      }}
                    >
                      {goals.map((card) => (
                        <Item
                          role="button"
                          key={card.id}
                          onClick={() => {
                            navigate(`/goals/${card.id}`);
                          }}
                          sx={{
                            cursor: "pointer",
                            py: 1,
                            px: 2,
                            "&:hover": { backgroundColor: "rgba(0,0,0,0.1)" },
                          }}
                        >
                          <ListItemText primary={card.name} />
                        </Item>
                      ))}
                    </Box>
                  ) : (
                    <></>
                  )}
                  <Button
                    variant="contained"
                    sx={{ mt: 2 }}
                    onClick={() => setShowCreateModal(true)}
                  >
                    Create new goal
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <AddCardModal
          showModal={showCreateModal}
          shouldHideModal={() => setShowCreateModal(false)}
          transcripts={transcripts}
          loadGoals={getGoals}
        />
      </Grid> */}
    </>
  );
}
