import { LoadingButton } from "@mui/lab";
import { Grid, Paper } from "@mui/material";
import React from "react";

export function BillingInvoices({
  viewInvoicesLoading,
  setViewInvoicesLoading,
  getPortalLink,
}) {
  return (
    <Grid container style={{ width: "100%", marginBottom: 16 }} spacing={2}>
      <Grid item xs={12}>
        <Paper
          variant="outlined"
          style={{
            padding: 24,
            paddingTop: 24,
            paddingBottom: 24,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <h4 style={{ margin: 0, fontSize: 18, marginBottom: 4 }}>
              Invoices
            </h4>
            <div style={{ color: "#666" }}>View your payment history</div>
          </div>
          <div style={{ flexGrow: 1 }}></div>
          <LoadingButton
            variant="outlined"
            size="small"
            color="gray"
            onClick={() => {
              setViewInvoicesLoading(true);
              getPortalLink();
            }}
            loading={viewInvoicesLoading}
          >
            View invoices
          </LoadingButton>
        </Paper>
      </Grid>
    </Grid>
  );
}
