import { PermMediaOutlined, TextSnippet } from "@mui/icons-material";
import { Avatar, Button } from "@mui/material";
import { CircularProgressWithLabel } from "jsx/components";
import { useRef, useState } from "react";

// drag drop file component
export default function Uploader({ progress, onFileChanged, cancel }) {
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      console.log("drag active");
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFiles(e.dataTransfer.files);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFiles(e.target.files);
    }
  };

  function handleFiles(files) {
    const file = files[0];
    setSelectedFile(file);
    if (onFileChanged) {
      onFileChanged(file);
    }
  }

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };

  let content;

  if (progress) {
    const progressText = progress < 100 ? "Uploading..." : "Transcribing...";
    content = (
      <>
        <div>
          <CircularProgressWithLabel value={progress} />

          <h4>{progressText}</h4>
          <Button
            onClick={cancel}
            variant="outlined"
            color="gray"
            disableElevation
          >
            Cancel
          </Button>
        </div>
      </>
    );
  } else if (selectedFile) {
    console.log("selectedFile", selectedFile);
    content = (
      <>
        <div>
          <h4
            className={dragActive ? "drag-active" : ""}
            style={{ fontSize: 22, lineHeight: "100%" }}
          >
            Selected file to upload
          </h4>
          <button className="upload-button" style={{ color: "#6366f1" }}>
            {selectedFile.name}
          </button>
        </div>
      </>
    );
  } else {
    content = (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Avatar
            style={{ margin: 0 }}
            sx={{ width: 56, height: 56, background: "#EEE" }}
          >
            <PermMediaOutlined fontSize="medium" color="primary" />
          </Avatar>

          <h4
            className={dragActive ? "drag-active" : ""}
            style={{ fontSize: 22, lineHeight: "100%", marginBottom: 16 }}
          >
            Drag and drop a audio or video file to upload
          </h4>
          <button
            className="upload-button"
            onClick={onButtonClick}
            style={{ color: "#999", color: "#6366f1" }}
          >
            or click here to browse
          </button>
        </div>
      </>
    );
  }

  return (
    <form
      id="form-file-upload"
      onDragEnter={handleDrag}
      onSubmit={(e) => e.preventDefault()}
    >
      <input
        ref={inputRef}
        type="file"
        id="input-file-upload"
        multiple={true}
        onChange={handleChange}
      />
      <label
        id="label-file-upload"
        htmlFor="input-file-upload"
        className={dragActive ? "drag-active" : ""}
      >
        {content}
      </label>
      {dragActive && (
        <div
          id="drag-file-element"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        ></div>
      )}
    </form>
  );
}
