import dayjs from "dayjs";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { UserContext } from "jsx/context";
import { useContext, useEffect, useMemo, useState } from "react";

const defaultOptions = {
  chart: {
    id: "basic-bar",
    toolbar: {
      show: false,
    },
  },
  xaxis: {
    type: "datetime",
    tickAmount: 6,
  },
  colors: ["#6366f1"],
  dataLabels: {
    enabled: false,
  },
};

export function useWorkspaceUsage(uid) {
  const db = getFirestore();
  const { user, workspace } = useContext(UserContext);
  const [workspaceUsage, setWorkspaceUsage] = useState();

  useEffect(() => {
    if (uid && workspace) {
      getUsage();
    }
  }, [uid, workspace]);

  async function getUsage() {
    const workspaceUsage = await getDoc(
      doc(
        db,
        "workspaces",
        workspace.id,
        "workspaces_admin",
        "data",
        "usage",
        "summary"
      )
    );

    setWorkspaceUsage(workspaceUsage.data() ?? {});
  }

  const {
    options = {},
    series = [],
    creditsUsed = 0,
  } = useMemo(() => {
    if (!workspaceUsage) {
      return [];
    }

    const data = [];
    let creditsUsed = 0;

    for (let i = 30; i > -2; i--) {
      const dateString = dayjs(new Date())
        .subtract(i, "days")
        .format("YYYY-MM-DD");

      const value = workspaceUsage[dateString] ?? 0;

      data.push({ x: new Date(dateString).getTime(), y: value });
      creditsUsed += value;
    }

    const series = [
      {
        name: "Words generated",
        data,
      },
    ];

    return { data, options: defaultOptions, series, creditsUsed };
  }, [workspaceUsage]);

  return { creditsUsed, options, series };
}
