import { Grid } from "@mui/material";
import { post } from "jsx/Api";
import { UserContext } from "jsx/context";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { InviteContent } from "./components";

export function AcceptInvite() {
  const { workspaceId, inviteCode, workspaceName } = useParams();
  const [loading, setIsLoading] = useState(false);

  const { user, logout, reloadUser, updateWorkspace, hasProcessedAuth } =
    React.useContext(UserContext);

  const navigate = useNavigate();

  async function acceptInvite() {
    const params = {
      invitationWorkspaceId: workspaceId,
      inviteCode: inviteCode,
      name: window.atob(workspaceName),
    };

    setIsLoading(true);
    try {
      await post(`workspaces/acceptTeamInvite`, params);
      await reloadUser();
      updateWorkspace({ id: workspaceId, name: window.atob(workspaceName) });
      navigate(`/dashboard` + document.location.search, { replace: true });
    } catch (error) {
      console.log("error", error);
      alert("Not the correct email or code.");
    }
    setIsLoading(false);
  }

  useEffect(() => {
    //
  }, [inviteCode]);

  useEffect(() => {
    // We don't redirect the user until we get the user context
    // if(isWaitingForSignin && user.email) {
    //     history.push(signURL ? signURL : roundURL);
    // }
  }, [user.email]);

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{
        marginTop: "20vh",
      }}
    >
      <InviteContent
        user={user}
        workspaceName={workspaceName}
        loading={loading || !hasProcessedAuth}
        acceptInvite={acceptInvite}
        logout={logout}
      />
    </Grid>
  );
}
