import { Alert, Box, Button, Chip, TextField } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import Modal from "@mui/material/Modal";
import { DataGrid } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { Form, Formik } from "formik";
import MSAnalytics from "jsx/api/analytics";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { CustomNoRowsOverlay } from "../EmptyState";
import { useContacts } from "jsx/hooks/useContacts";
import { UploadCSVContactsModal } from "jsx/pages/Contacts/components/Uploading/UploadCSVContactsModal";

const LeadForm = Yup.object().shape({
  firstName: Yup.string().required(),
  email: Yup.string().email("Invalid email").required("Required"),
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  // border: "2px solid #333",
  boxShadow: 24,
  p: 4,
  borderRadius: 1,
  maxHeight: "85vh",
};

export function EditLeadModal({ showModal, shouldHideModal, onAddContacts }) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [selectionModel, setSelectionModel] = useState([]);
  const { contacts, getContacts } = useContacts();
  const [selectionError, setSelectionError] = useState();
  const [showUploadCSVModal, setShowUploadCSVModal] = useState(false);

  const columns = useMemo(() => {
    const columns = [
      // { field: "id", headerName: "ID", width: 90 },
      {
        field: "firstName",
        headerName: "First Name",
        width: 130,
        editable: false,
      },
      {
        field: "lastName",
        headerName: "Last Name",
        width: 130,
        editable: false,
      },
      //   {
      //     field: "email",
      //     headerName: "Email",
      //     width: 240,
      //     editable: false,
      //   },
      {
        field: "companyName",
        headerName: "Company",
        width: 150,
        editable: false,
      },
      {
        field: "email",
        headerName: "Email",
        width: 150,
        editable: false,
      },

      // {
      //   field: "actions",
      //   headerName: "Actions",
      //   width: 100,
      //   editable: false,
      //   valueFormatter: (params) => {
      //     if (params.value == null) {
      //       return "";
      //     }

      //     return dayjs(new Date(params.value.seconds * 1000)).fromNow();
      //   },

      //   renderCell: (params) => {
      //     return (
      //       <>
      //         <Button
      //           variant="outlined"
      //           size="small"
      //           color="gray"
      //           sx={{ color: "#666", borderColor: "#CCC" }}
      //           onClick={(e) => {
      //             e.stopPropagation();
      //             e.preventDefault();

      //             navigate(`/draft?contactId=${params.row.id}`);
      //           }}
      //         >
      //           Draft email
      //         </Button>
      //       </>
      //     );
      //   },
      // },
      {
        field: "tags",
        headerName: "Tags",
        width: 180,
        editable: false,
        renderCell: (params) => {
          return (
            params.formattedValue &&
            params.formattedValue.map((e) => {
              return (
                <Chip
                  variant="outlined"
                  label={e}
                  size="small"
                  style={{ marginRight: 4 }}
                />
              );
            })
          );
        },
      },
    ];

    return columns;
  }, []);

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        shouldHideModal();
      }
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  return (
    <>
      <UploadCSVContactsModal
        showModal={showUploadCSVModal}
        shouldHideModal={() => {
          getContacts();
          setShowUploadCSVModal(false);
        }}
      />

      <Modal
        open={showModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onBackdropClick={() => {
          shouldHideModal();
        }}
      >
        <Box sx={style}>
          {selectionError && (
            <Alert severity="error">
              All contacts you wish to add must have an email address. Either
              deselect them or edit the contact information to include an email
            </Alert>
          )}

          <Box
            sx={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <h3>Add contacts to campaign</h3>
            <div style={{ flexGrow: 1 }} />
            <Button
              variant="outlined"
              disableElevation
              style={{ marginRight: 16 }}
              onClick={() => setShowUploadCSVModal(true)}
            >
              Import contacts
            </Button>
            <Link to={"/contacts/new"} style={{ textDecoration: "none" }}>
              <Button variant="contained" disableElevation>
                New Contact
              </Button>
            </Link>
          </Box>
          <DataGrid
            autoPageSize={true}
            density="compact"
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
            rows={contacts}
            columns={columns}
            checkboxSelection
            experimentalFeatures={{ newEditingApi: true }}
            components={{
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            style={{ minHeight: "60vh", maxHeight: "60vh" }}
          />
          <div style={{ display: "flex", marginTop: 16 }}>
            <div style={{ flexGrow: 1 }}></div>
            <Button
              onClick={shouldHideModal}
              variant="contained"
              color="secondary"
              disableElevation
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              disableElevation
              style={{ marginLeft: 8 }}
              onClick={() => {
                setSelectionError(false);
                const selectedContacts = contacts.filter((e) => {
                  return selectionModel.includes(e.id);
                });

                // make sure all contacts have emails
                const contactsWithNoEmails = selectedContacts.filter(
                  (e) => !e.email
                );

                if (contactsWithNoEmails.length) {
                  setSelectionError(true);
                  return;
                }
                shouldHideModal();
                onAddContacts(selectedContacts);
              }}
              disabled={!selectionModel.length}
            >
              Add contacts
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}
