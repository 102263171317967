import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Grid, Tab } from "@mui/material";
import React, { useState } from "react";

import { EmptyQuery } from "./EmptyQuery";
import { TranscriptQueryHistory } from "./QueryHistory";
import { TranscriptQueryCell } from "./TranscriptCell";

export function TranscriptsQuerySection({ queries, currentQuery }) {
  const [currentTab, setCurrentTab] = useState("1");

  const onCurrentTabChange = (tab, value) => {
    setCurrentTab(value);
  };

  return (
    <Grid
      item
      md={12}
      lg={6}
      style={{
        height: "calc(100vh)",
        overflowY: "auto",
        background: "white",
        paddingRight: 0,
        paddingLeft: 0,
        borderLeft: "1px solid #E9E9E9",
      }}
    >
      {/* {isGeneratingDraft && <DraftLoadingCell style={{ marginBottom: 16 }} />} */}

      <TabContext value={currentTab}>
        <Box
          sx={{
            borderBottom: 1,
            width: "100%",
            zIndex: 1,
            background: "white",
            position: { lg: "fixed", md: "block" },
            marginTop: { lg: "-1px", md: "0px" },
            borderTop: 1,
            borderColor: "divider",
          }}
        >
          <TabList
            onChange={onCurrentTabChange}
            aria-label="lab API tabs example"
          >
            <Tab label="New Outputs" value="1" />
            <Tab label="Query History" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1" style={{ marginTop: 48 }}>
          {currentQuery ? (
            <div style={{ paddingBottom: 48 }}>
              <TranscriptQueryCell
                key="current"
                query={currentQuery}
                style={{ marginBottom: 16 }}
              />
            </div>
          ) : (
            <EmptyQuery />
          )}
        </TabPanel>
        <TabPanel value="2" style={{ marginTop: 48 }}>
          <TranscriptQueryHistory queries={queries} />
        </TabPanel>
      </TabContext>
    </Grid>
  );
}
