import { CheckCircle } from "@mui/icons-material";
import EastIcon from "@mui/icons-material/East";
import { Box, Button, Grid } from "@mui/material";
import MSAnalytics from "jsx/api/analytics";
import React from "react";
import { Link } from "react-router-dom";

export function OnboardRow({ title, credits, complete, path }) {
  let leftIcon;
  let tryButton;

  if (complete) {
    leftIcon = (
      <div>
        <CheckCircle className="onboard-icon done" />
      </div>
    );
  } else {
    leftIcon = (
      <div>
        <EastIcon className="onboard-icon" />
      </div>
    );
    tryButton = (
      <Button
        variant="outlined"
        size="small"
        color="gray"
        sx={{ color: "#666", borderColor: "#CCC", height: "fit-content" }}
      >
        Try it
      </Button>
    );
  }

  const renderRow = () => {
    return (
      <Box
        sx={{
          py: 0,
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Grid
          container
          className={`onboard ${complete ? "done" : ""}`}
          style={{ display: "flex" }}
        >
          <Grid item xs={8} style={{ display: "flex" }}>
            {leftIcon}
            <div
              style={{
                marginTop: 2,
                textDecoration: complete ? "line-through" : "none",
              }}
            >
              {title}
            </div>
          </Grid>
          <Grid
            item
            xs={3}
            sx={{
              color: "#888",
              fontWeight: 400,
              marginTop: 2,
              display: "flex",
              maxWidth: { lg: "20%", md: "15%", sm: "13%" },
            }}
          >
            {credits}
          </Grid>
          <Grid item xs={1} style={{ display: "flex" }}>
            {tryButton}
          </Grid>
        </Grid>
      </Box>
    );
  };

  if (path) {
    return (
      <Link
        target={path.includes("http") ? "_blank" : ""}
        to={path}
        style={{ textDecoration: "none", color: "#333" }}
        onClick={() => {
          MSAnalytics.track("Button Clicked", {
            page: "Dashboard",
            buttonType: `Onboard Row - ${title}`,
          });
        }}
      >
        {renderRow()}
      </Link>
    );
  }

  return renderRow();
}
