import { Skeleton } from "@mui/material";
import { ConnectGmailButton } from "jsx/components/buttons";
import React from "react";

import { AuthSendersForm } from "../EditAuthSendersForm/EditAuthSendersForm";

export function AuthSendersContent({
  authedSenders,
  showSenderSection,
  onGmailButtonSuccess,
  campaign,
  getAuthedSenders,
  getCampaign,
  saveCampaign,
  setAuthedSenders,
  setShowSenderSection,
}) {
  if (!authedSenders) {
    return (
      <Skeleton variant="rectangular" height={80} style={{ marginTop: 32 }} />
    );
  }

  if (!authedSenders.length) {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ color: "#999" }}>
          You don't have any connected email accounts.
        </div>
        <div style={{ flexGrow: 1 }} />
        <ConnectGmailButton
          style={{ marginLeft: 16, marginTop: -32 }}
          onSuccess={onGmailButtonSuccess}
        />
      </div>
    );
  } else if (showSenderSection && authedSenders.length) {
    return (
      <AuthSendersForm
        campaign={campaign}
        authedSenders={authedSenders}
        getAuthedSenders={getAuthedSenders}
        getCampaign={getCampaign}
        saveCampaign={saveCampaign}
        setAuthedSenders={setAuthedSenders}
        setShowSenderSection={setShowSenderSection}
      />
    );
  }
}
