import React from "react";

import { TeamMembersTableRow } from "../TeamMembersTableRow/TeamMembersTableRow";

export function TeamMembersList({ teamMembers, onUserDelete }) {
  if (!teamMembers) {
    return null;
  }

  return teamMembers.map((teamMember) => {
    return (
      <TeamMembersTableRow
        key={teamMember.id}
        teamMember={teamMember}
        onUserDelete={onUserDelete}
      />
    );
  });
}
