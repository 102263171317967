import { LoadingButton } from "@mui/lab";
import { Button, Grid } from "@mui/material";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { CreateWorkSpaceForm } from "jsx/components";
import { UserContext } from "jsx/context";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { QontoConnector, QontoStepIcon } from "./components";

export const CompanyDetailsForm = Yup.object().shape({
  senderCompanyName: Yup.string().required(
    "Please complete this required field."
  ),
  senderCompanyContext: Yup.string().required(
    "Please complete this required field."
  ),
  senderFirstName: Yup.string().required(
    "Please complete this required field."
  ),
  senderJobTitle: Yup.string().required("Please complete this required field."),
});

// const steps = [
//   "Create your account",
//   "Create a workspace",
//   "Company details",
//   "Goals",
// ];

const steps = ["Create your account", "Create a workspace"];

export function Onboarding() {
  const [activeStep, setActiveStep] = React.useState();
  const { user, reloadUser, workspace } = React.useContext(UserContext);

  const firstName = user?.displayName?.split(" ")[0] ?? "";
  const [loading, setLoading] = React.useState();

  const navigate = useNavigate();

  React.useEffect(() => {
    if (!workspace.name) {
      setActiveStep(1);
    } else {
      if (user.jobTitle) {
        setActiveStep(3);
      } else {
        setActiveStep(2);
      }
    }
  }, [workspace, user]);

  const initialValues = {
    senderFirstName: firstName,
    senderJobTitle: "VP of Marketing",
    senderCompanyContext:
      "IBM specializes in computer hardware, middleware, and software, and provides hosting and consulting services in areas ranging from mainframe computers to nanotechnology.",
    senderCompanyName: workspace.name,
  };

  React.useEffect(() => {
    if (user.admin?.workspaces) {
      navigate(`/dashboard` + document.location.search);
      return;
    } else {
      reloadUser();
    }
  }, [user.admin?.workspaces]);
  const db = getFirestore();

  async function handleSubmit(values) {
    setLoading(true);
    await setDoc(
      doc(db, "workspaces", workspace.id, "workspaces_private", "data"),
      {
        companyName: values.senderCompanyName,
        senderCompanyContext: values.senderCompanyContext,
      },
      { merge: true }
    );

    await setDoc(
      doc(db, "users", user.uid, "users_private", "data"),
      {
        jobTitle: values.senderJobTitle,
        firstName: values.senderFirstName,
      },
      { merge: true }
    );
    await reloadUser();
  }

  function viewForStepper(step) {
    if (step === 1) {
      return (
        <div>
          <CreateWorkSpaceForm />
        </div>
      );
    } else if (step === 2) {
      return (
        <>
          <div>
            <h2>Tell us about you and your business</h2>

            <Formik
              enableReinitialize
              validationSchema={CompanyDetailsForm}
              initialValues={{
                senderFirstName: initialValues.senderFirstName,
                senderJobTitle: "",
                senderCompanyContext: "",
                senderCompanyName: initialValues.senderCompanyName,
              }}
              onSubmit={handleSubmit}
              style={{ height: "100%" }}
            >
              <Form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <div>
                  <Grid container style={{ width: "100%" }}>
                    <h4 style={{ marginBottom: 0, marginTop: 24 }}>
                      Your company info
                    </h4>
                    <Grid container style={{ width: "100%" }}>
                      <Grid item xs={12} md={12}>
                        <div>
                          <label
                            htmlFor="senderCompanyName"
                            className="form-label"
                          >
                            Your company name / product name
                          </label>
                          <Field
                            id="senderCompanyName"
                            name="senderCompanyName"
                            placeholder="IBM"
                            className="form-input"
                          />
                          <ErrorMessage
                            name="senderCompanyName"
                            className="form-error"
                            component="div"
                          />
                        </div>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <label
                        htmlFor="senderCompanyContext"
                        className="form-label"
                      >
                        Summary of your company*
                      </label>
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        id="senderCompanyContext"
                        name="senderCompanyContext"
                        type="senderCompanyContext"
                        component="textarea"
                        rows="3"
                        placeholder={initialValues.senderCompanyContext}
                        className="form-textarea"
                      />
                      <ErrorMessage
                        name="senderCompanyContext"
                        className="form-error"
                        component="div"
                      />
                    </Grid>
                    <h4 style={{ marginBottom: 0, marginTop: 24 }}>
                      Your info
                    </h4>
                    <Grid container style={{ width: "100%" }}>
                      <Grid item xs={12} md={6}>
                        <div style={{ marginRight: 16 }}>
                          <label
                            htmlFor="senderFirstName"
                            className="form-label"
                          >
                            Your first name*
                          </label>
                          <Field
                            id="senderFirstName"
                            name="senderFirstName"
                            placeholder="Jane"
                            className="form-input"
                          />
                          <ErrorMessage
                            name="senderFirstName"
                            className="form-error"
                            component="div"
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div>
                          <label
                            htmlFor="senderJobTitle"
                            className="form-label"
                          >
                            Your job title / role*
                          </label>
                          <Field
                            id="senderJobTitle"
                            name="senderJobTitle"
                            placeholder={initialValues.senderJobTitle}
                            className="form-input"
                          />
                          <ErrorMessage
                            name="senderJobTitle"
                            className="form-error"
                            component="div"
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ flexGrow: 1 }} />
                  <LoadingButton
                    type="submit"
                    style={{
                      marginTop: 32,
                    }}
                    variant="contained"
                    disableElevation
                    size="large"
                    loading={loading}
                  >
                    Next
                  </LoadingButton>
                </div>
              </Form>
            </Formik>
          </div>
        </>
      );
    }
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <div style={{ width: "800px", maxWidth: "100%", marginTop: 48 }}>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<QontoConnector />}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          {viewForStepper(activeStep)}
        </Grid>
      </div>
    </Grid>
  );
}
