import { post } from "jsx/Api";
import { EmailCell } from "jsx/components";
import React from "react";
import { useParams } from "react-router-dom";

import { InboxButtons } from "../InboxButtons/InboxButtons";

export function DraftSection({
  lead,
  conversation,
  currentDraft,
  isRefreshingInbox,
  isGeneratingDraft,
  setGeneratingDraft,
  setCurrentDraft,
  onConversationGet,
  checkForUpdates,
}) {
  const { campaignId, leadId } = useParams();

  async function sendDraft() {
    await post("leads/sendDraftEmailsForConversation", {
      campaignId,
      leadId,
      conversationId: conversation.id,
    });

    await onConversationGet();
    await checkForUpdates();
    await onConversationGet();

    post("workspaces/completeOnboardingStep", {
      onboardingStep: "sendWithAutopilot",
    });
  }

  return (
    <>
      <div style={{ display: "flex", marginTop: 0 }}>
        <h3>Conversation</h3>
        <div style={{ flexGrow: 1 }} />
        <div style={{ marginTop: 18 }}>
          <InboxButtons
            conversation={conversation}
            isRefreshingInbox={isRefreshingInbox}
            isGeneratingDraft={isGeneratingDraft}
            setGeneratingDraft={setGeneratingDraft}
            setCurrentDraft={setCurrentDraft}
            checkForUpdates={checkForUpdates}
            onConversationGet={onConversationGet}
          />
        </div>
      </div>

      {isGeneratingDraft && (
        <EmailCell
          from={conversation.authedSender}
          to={lead.email}
          draft={currentDraft}
          html={
            currentDraft?.html ??
            currentDraft?.text?.replaceAll("\n", `<br />`) ??
            ""
          }
          style={{ marginBottom: 32 }}
          sendDraft={async () => {}}
          campaignId={campaignId}
          leadId={leadId}
          conversationId={conversation.id}
          getConversation={onConversationGet}
        />
      )}

      {!isGeneratingDraft && conversation?.draft && (
        <EmailCell
          from={conversation.draft.authedSender}
          to={lead.email}
          draft={conversation.draft}
          html={
            conversation?.draft.html ??
            conversation?.draft.text.replaceAll("\n", `<br />`)
          }
          style={{ marginBottom: 32 }}
          sendDraft={async () => {
            await sendDraft();
            await onConversationGet();
          }}
          campaignId={campaignId}
          leadId={leadId}
          conversationId={conversation.id}
          getConversation={onConversationGet}
        />
      )}
    </>
  );
}
