export const defaultPricingMarks = [
  {
    value: 0,
    label: "1,000",
  },
  {
    value: 3,
    label: "10,000",
  },
];

export const bossModeBenefits = [
  {
    text: "Draft hyper-personalized emails instantly",
    tip: "Generate personalized sales emails in one click",
  },
  {
    text: "Deliver relevant messaging by automatically integrating their recent company news and social posts",
    tip: "Include your pitch and notable news or connections",
  },
  {
    text: "Google Chrome extension for Gmail",
    tip: "Use Modern Sales AI directly from Gmail",
  },
  {
    text: "Import leads and create email campaigns on autopilot",
    tip: "Relevant messaging is automatically included in every email",
  },
  {
    text: "Increase your plan at any time",
    tip: "Reach out to our team for pricing on more than 10 team members",
  },
];
