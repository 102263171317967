import { EditOutlined, PeopleOutline } from "@mui/icons-material";
import { Box, Button, Grid, IconButton, Tooltip } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  setDoc,
} from "firebase/firestore";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { eventSource, post } from "jsx/Api";
import MSAnalytics from "jsx/api/analytics";
import { UserContext } from "jsx/context";
import { uniqueArrayOfObjects } from "jsx/utils";
import _ from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import AddableContactAutocomplete from "./AddableContactAutocomplete";

import { coldEmailFormSchema } from "./cold-email-form.schema";

const initialValues = {
  senderFirstName: "Jane",
  senderJobTitle: "VP of Marketing",
  goal: "Try to schedule a 30 minute meeting with recipient next Tuesday or Thursday.",
  senderCompanyContext:
    "We've developed a new computer chip that improves compute efficiency by 37%.",
  senderCompanyName: "IBM",
  leadFirstName: "John",
  leadJobTitle: "VP of Engineering",
  leadCompanyName: "Microsoft",
  leadBio:
    "This person went to Harvard and played tennis there. They have worked at IBM for a year.",
  leadEthnicity: "German",
  leadCollegeName: "Cornell",
  leadSport: "Tennis",
  leadVC: "Sequoia",
  sharedInterest: "Stamp collecting",
};

export function ColdEmailForm({
  drafts,
  defaultContact,
  setDrafts,
  setCurrentDraft,
}) {
  const db = getFirestore();
  const { workspace, user } = useContext(UserContext);
  const [isGeneratingDraft, setGeneratingDraft] = useState(false);
  const [contact, setContact] = useState(defaultContact);
  const [contacts, setContacts] = useState();
  const formRef = useRef();
  const navigate = useNavigate();
  const [workspaceAdmin, setWorkspaceAdmin] = useState();

  useEffect(() => {
    getDrafts();
    getContacts();
    getWorkspaceAdmin();
  }, []);

  async function getWorkspaceAdmin() {
    const q = await getDoc(
      doc(db, "workspaces", workspace.id, "workspaces_admin", "data")
    );

    setWorkspaceAdmin(q.data());
  }

  async function updateUserValues(values) {
    await setDoc(
      doc(db, "workspaces", workspace.id, "workspaces_private", "data"),
      {
        companyName: values.senderCompanyName,
        senderCompanyContext: values.senderCompanyContext,
      },
      { merge: true }
    );

    const userValues = {
      jobTitle: values.senderJobTitle,
      firstName: values.senderFirstName,
    };

    await setDoc(
      doc(db, "users", user.uid, "users_private", "data"),
      userValues,
      { merge: true }
    );
  }

  async function getContacts() {
    const db = getFirestore();

    try {
      const q = await getDocs(
        collection(
          db,
          "workspaces",
          workspace.id,
          "workspaces_private",
          "data",
          "contacts"
        )
      );

      let map = q.docs.map((e) => {
        return {
          id: e.id,
          ...e.data(),
        };
      });

      map = _.orderBy(map, ["createdAt"], ["desc"]);

      console.log("CONTACTS", map);

      setContacts(map);
    } catch (e) {
      console.log("error fetching contacts", e);
    }
  }

  async function getDrafts() {
    const q = await getDocs(
      collection(
        db,
        "workspaces",
        workspace.id,
        "workspaces_private",
        "data",
        "drafts"
      )
    );

    let map = q.docs.map((e) => {
      return {
        id: e.id,
        ...e.data(),
      };
    });

    map = _.orderBy(map, ["createdAt"], ["desc"]);
    setDrafts(map);
  }

  async function handleSubmit(values) {
    MSAnalytics.track("Button Clicked", {
      page: "Drafts",
      buttonType: `Generate`,
    });

    // save values
    updateUserValues(values);

    setGeneratingDraft(true);
    const docRef = [
      "workspaces",
      workspace.id,
      "workspaces_private",
      "data",
      "drafts",
    ].join("/");
    const doc = await addDoc(collection(db, docRef), { ...values });

    let text = "";
    var source = await eventSource(
      `leads/createColdOutreachEmail/${doc.id}/${workspace.id}`
    );
    source.onmessage = function (e) {
      const data = JSON.parse(e.data);
      text = text.concat(data.choices[0].text);
      let subject = "";
      let body = text.trim();

      if (body.includes("Subject: ")) {
        subject = body.split("Subject: ")[1].split("\n")[0].trim();
      }

      if (body.includes("subject: ")) {
        subject = body.split("subject: ")[1].split("\n")[0].trim();
      }

      body = body
        .split("\n")
        .filter((e) => !e.toLowerCase().includes("subject:"))
        .join("\n");

      body = body.trim();

      const newDraft = {
        subject,
        body,
        createdAt: { seconds: Date.now() / 1000 },
        from: values.senderFirstName,
        to: values.leadFirstName,
        id: doc.id,
      };

      setCurrentDraft(newDraft);
    };

    source.addEventListener("close", function (e) {
      handleAccumulatedMessageText(text, values, doc.id);
      setGeneratingDraft(false);
      setTimeout(() => {
        // dumb but do it for now
        // Sometimes it loads before the server saves to firestore?
        getDrafts();
      }, 200);
      source.close();

      post("workspaces/completeOnboardingStep", {
        onboardingStep: "generateDraft",
      });
    });

    source.onerror = function (e) {
      console.log("error", e.status);
      setGeneratingDraft(false);

      toast.error("You have reached credits limit", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      deleteDoc(doc);
      source.close();
    };
    saveLastInput(values);

    updateOrAddContact(values);
  }

  async function updateOrAddContact(values) {
    const leadData = {
      firstName: values.leadFirstName,
      companyName: values.leadCompanyName,
      jobTitle: values.leadJobTitle,
      summary: values.leadBio,
    };

    if (values.leadEthnicity) {
      leadData["ethnicity"] = values.leadEthnicity;
    }

    const filteredLeadData = _(leadData)
      .omitBy(_.isEmpty)
      .omitBy(_.isUndefined)
      .omitBy(_.isNull)
      .value();

    // if no contact id, then create new contact
    if (!contact.id) {
      const cc = await addDoc(
        collection(
          db,
          [
            "workspaces",
            workspace.id,
            "workspaces_private",
            "data",
            "contacts",
          ].join("/")
        ),
        {
          ...filteredLeadData,
          createdAt: new Date(),
          status: "hydrated",
          source: "drafts",
        }
      );

      console.log("filteredLeadData", filteredLeadData);
      await setContact({ id: cc.id, ...filteredLeadData });
      getContacts();
      toast.success(`New Contact created for ${contact?.firstName}`, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else {
      if (
        filteredLeadData.companyName === contact.companyName &&
        filteredLeadData.firstName === contact.firstName &&
        filteredLeadData.jobTitle === contact.jobTitle
      ) {
        return;
      }

      await setDoc(
        doc(
          db,
          [
            "workspaces",
            workspace.id,
            "workspaces_private",
            "data",
            "contacts",
            contact.id,
          ].join("/")
        ),
        {
          ...filteredLeadData,
          createdAt: new Date(),
          status: "hydrated",
        }
      );

      toast.success(`Updated Contact info for ${contact?.firstName}`, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  }

  const handleAccumulatedMessageText = useCallback(
    (text, values, id) => {
      let subject;
      let body;
      const response = text;
      let lines = response.split("\n");

      if (response.includes("subject: ")) {
        subject = response.split("subject: ")[1].split("\n")[0].trim();
      }

      if (response.includes("subject: ")) {
        subject = response.split("subject: ")[1].split("\n")[0].trim();
      }

      lines = lines.filter((e) => !e.toLowerCase().includes("subject:"));

      if (!subject) {
        subject = "";
      }

      body = lines.join("\n").trim();
      body = body.trim().replaceAll("\n", "<br>");

      // remove leading <br>
      var regex = /^\s*(?:<br\s*\/?\s*>)+|(?:<br\s*\/?\s*>)+\s*$/gi;
      body = body.replace(regex, ""); //changed replacement

      body = body.trim().replaceAll("<br>", "\n");

      const newDraft = {
        subject,
        body,
        createdAt: { seconds: Date.now() / 1000 },
        from: values.senderFirstName,
        to: values.leadFirstName,
        prompt,
        id,
      };

      console.log(newDraft);
      setDrafts(uniqueArrayOfObjects([newDraft, ...drafts]));
    },
    [drafts]
  );

  function saveLastInput(values) {
    for (const [key, value] of Object.entries(values)) {
      console.log(key, value);
      localStorage.setItem(key, value);
    }
    localStorage.setItem("contactId", contact.id);
  }

  const firstName = user.displayName.split(" ")[0] ?? "";

  const action = (
    <React.Fragment>
      <Button
        color="primary"
        variant="contained"
        disableElevation
        size="small"
        onClick={() => {
          navigate(`/contacts/${contact.id}`);
        }}
      >
        View in Contacts
      </Button>
    </React.Fragment>
  );

  return (
    <Box
      sx={{
        paddingBottom: { md: "0px", lg: "60px" },
      }}
    >
      <Formik
        innerRef={formRef}
        validationSchema={coldEmailFormSchema}
        initialValues={{
          senderFirstName:
            localStorage.getItem("senderFirstName") ??
            firstName ??
            user.firstName ??
            initialValues.senderFirstName,
          senderJobTitle:
            localStorage.getItem("senderJobTitle") ??
            user.jobTitle ??
            initialValues.senderJobTitle,
          goal: localStorage.getItem("goal") ?? initialValues.goal,
          senderCompanyContext:
            localStorage.getItem("senderCompanyContext") ??
            workspace.senderCompanyContext ??
            initialValues.senderCompanyContext,
          senderCompanyName:
            localStorage.getItem("senderCompanyName") ??
            workspace.name ??
            workspace.companyName ??
            initialValues.senderCompanyName,
          leadFirstName: contact?.firstName ?? "",
          leadJobTitle: contact?.jobTitle ?? "",
          leadCompanyName: contact?.companyName ?? "",
          leadEthnicity: contact?.ethnicity ?? "",
          leadBio: contact?.summary ?? "",
          persona: localStorage.getItem("persona") ?? "default",
        }}
        onSubmit={handleSubmit}
        style={{ height: "100%" }}
      >
        {({ resetForm, values, errors }) => {
          return (
            <>
              <Form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    paddingRight: 24,
                    paddingBottom: 48,
                    marginLeft: 24,
                  }}
                >
                  <Grid container style={{ width: "100%" }}>
                    <Grid item xs={12}>
                      <label htmlFor="goal" className="form-label">
                        What is the goal of this email?*
                      </label>
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        id="goal"
                        name="goal"
                        type="goal"
                        component="textarea"
                        rows="3"
                        className="form-textarea"
                      />
                    </Grid>
                    <ErrorMessage
                      name="goal"
                      className="form-error"
                      component="div"
                    />
                    <h4 style={{ marginBottom: 0, marginTop: 24 }}>
                      Your info
                    </h4>
                    <Grid container style={{ width: "100%" }}>
                      <Grid item xs={12} md={6}>
                        <div style={{ marginRight: 16 }}>
                          <label
                            htmlFor="senderFirstName"
                            className="form-label"
                          >
                            Your first name*
                          </label>
                          <Field
                            id="senderFirstName"
                            name="senderFirstName"
                            placeholder={initialValues.senderFirstName}
                            className="form-input"
                          />
                          <ErrorMessage
                            name="senderFirstName"
                            className="form-error"
                            component="div"
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div>
                          <label
                            htmlFor="senderJobTitle"
                            className="form-label"
                          >
                            Your job title / role
                          </label>
                          <Field
                            id="senderJobTitle"
                            name="senderJobTitle"
                            placeholder={initialValues.senderJobTitle}
                            className="form-input"
                          />
                          <ErrorMessage
                            name="senderJobTitle"
                            className="form-error"
                            component="div"
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container style={{ width: "100%" }}>
                      <Grid item xs={12} md={12}>
                        <div>
                          <label
                            htmlFor="senderCompanyName"
                            className="form-label"
                          >
                            Your company name / product name
                          </label>
                          <Field
                            id="senderCompanyName"
                            name="senderCompanyName"
                            placeholder={initialValues.senderCompanyName}
                            className="form-input"
                          />
                          <ErrorMessage
                            name="senderCompanyName"
                            className="form-error"
                            component="div"
                          />
                        </div>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <label
                        htmlFor="senderCompanyContext"
                        className="form-label"
                      >
                        Context to include in email
                      </label>
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        id="senderCompanyContext"
                        name="senderCompanyContext"
                        type="senderCompanyContext"
                        component="textarea"
                        rows="3"
                        placeholder={initialValues.senderCompanyContext}
                        className="form-textarea"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <div>
                        <label htmlFor="persona" className="form-label">
                          Persona
                        </label>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      {/* <Field
                        as="select"
                        id="persona"
                        name="persona"
                        className="form-input"
                        disabled={
                          !workspaceAdmin ||
                          !workspaceAdmin.personas ||
                          !workspaceAdmin.personas.length
                        }
                      >
                        <option value="default">Default</option>
                        {workspaceAdmin &&
                          workspaceAdmin.personas &&
                          workspaceAdmin.personas.map((e) => {
                            return <option value={e}>{e}</option>;
                          })}
                      </Field> */}

                      <Field
                        as="select"
                        id="persona"
                        name="persona"
                        className="form-input"
                      >
                        <option value="default">Default</option>
                        <option value="sameCulturePersona">Same Culture</option>
                        <option value="sameCollegePersona">Same College</option>
                        <option value="favoriteSportPersona">
                          Favorite Sport
                        </option>
                        <option value="commonInvestorPersona">
                          Common Investor
                        </option>
                        <option value="sharedInterestPersona">
                          Shared Interest
                        </option>
                      </Field>
                    </Grid>

                    <div style={{ width: "100%" }}>
                      <div style={{ display: "flex" }}>
                        <h4 style={{ marginBottom: 0, marginTop: 36 }}>
                          Recipient info
                        </h4>
                        <div style={{ flexGrow: 1 }} />
                        {contact?.id && (
                          <Link
                            to={`/contacts/${contact.id}`}
                            style={{ textDecoration: "none" }}
                          >
                            <Button
                              variant="outlined"
                              size="small"
                              style={{
                                marginTop: 32,
                                marginBottom: -12,
                                marginLeft: 4,
                              }}
                              startIcon={<PeopleOutline />}
                            >
                              Edit in Contacts
                            </Button>
                          </Link>
                        )}
                      </div>

                      <Grid container style={{ width: "100%" }}>
                        <Grid item xs={12} md={6}>
                          <div style={{ marginRight: 16 }}>
                            <label
                              htmlFor="leadFirstName"
                              className="form-label"
                            >
                              Recipient's first name*
                            </label>
                            {/* <Field
                        id="leadFirstName"
                        name="leadFirstName"
                        className="form-input"
                        placeholder={initialValues.leadFirstName}
                      /> */}
                            <AddableContactAutocomplete
                              contacts={contacts}
                              style={{
                                background: "white",
                                width: "100%",
                                marginTop: "8px",
                              }}
                              onAddedNewContact={(contact) => {
                                setContact(contact);

                                if (!contact) {
                                  return;
                                }
                                resetForm({
                                  values: {
                                    ...values,
                                    leadFirstName: contact.firstName ?? "",
                                  },
                                });
                              }}
                              onSelectedContact={(contact) => {
                                setContact(contact);

                                resetForm({
                                  values: {
                                    ...values,
                                    leadFirstName: contact?.firstName ?? "",
                                    leadJobTitle: contact?.jobTitle ?? "",
                                    leadCompanyName: contact?.companyName ?? "",
                                    leadBio: contact?.summary ?? "",
                                  },
                                });
                              }}
                              selectedContactId={
                                contact?.id ??
                                defaultContact?.id ??
                                localStorage.getItem("contactId")
                              }
                              generate={async () => {
                                await formRef.current.handleSubmit();
                              }}
                            />

                            <ErrorMessage
                              name="leadFirstName"
                              className="form-error"
                              component="div"
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <div>
                            <label
                              htmlFor="leadJobTitle"
                              className="form-label"
                            >
                              Recipient's job title / role
                            </label>
                            <Field
                              id="leadJobTitle"
                              name="leadJobTitle"
                              className="form-input"
                              placeholder={initialValues.leadJobTitle}
                            />
                            <ErrorMessage
                              name="leadJobTitle"
                              className="form-error"
                              component="div"
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <div>
                            <label
                              htmlFor="leadCompanyName"
                              className="form-label"
                            >
                              Recipient's company
                            </label>
                            <Field
                              id="leadCompanyName"
                              name="leadCompanyName"
                              placeholder={initialValues.leadCompanyName}
                              className="form-input"
                            />
                            <ErrorMessage
                              name="leadCompanyName"
                              className="form-error"
                              component="div"
                            />
                          </div>
                        </Grid>

                        {values.persona === "sameCulturePersona" && (
                          <>
                            <Grid item xs={12}>
                              <div>
                                <label
                                  htmlFor="leadEthnicity"
                                  className="form-label"
                                >
                                  Recipient's ethnicity
                                </label>
                              </div>
                            </Grid>
                            <Grid item xs={12}>
                              <Field
                                id="leadEthnicity"
                                name="leadEthnicity"
                                placeholder={initialValues.leadEthnicity}
                                className="form-input"
                              />
                            </Grid>
                          </>
                        )}

                        {values.persona === "sameCollegePersona" && (
                          <>
                            <Grid item xs={12}>
                              <div>
                                <label
                                  htmlFor="leadCollegeName"
                                  className="form-label"
                                >
                                  Recipient's college
                                </label>
                              </div>
                            </Grid>
                            <Grid item xs={12}>
                              <Field
                                id="leadCollegeName"
                                name="leadCollegeName"
                                placeholder={initialValues.leadCollegeName}
                                className="form-input"
                              />
                            </Grid>
                          </>
                        )}

                        {values.persona === "favoriteSportPersona" && (
                          <>
                            <Grid item xs={12}>
                              <div>
                                <label
                                  htmlFor="leadSport"
                                  className="form-label"
                                >
                                  Recipient's favorite sport
                                </label>
                              </div>
                            </Grid>
                            <Grid item xs={12}>
                              <Field
                                id="leadSport"
                                name="leadSport"
                                placeholder={initialValues.leadSport}
                                className="form-input"
                              />
                            </Grid>
                          </>
                        )}

                        {values.persona === "commonInvestorPersona" && (
                          <>
                            <Grid item xs={12}>
                              <div>
                                <label htmlFor="leadVC" className="form-label">
                                  Recipient's VC
                                </label>
                              </div>
                            </Grid>
                            <Grid item xs={12}>
                              <Field
                                id="leadVC"
                                name="leadVC"
                                placeholder={initialValues.leadVC}
                                className="form-input"
                              />
                            </Grid>
                          </>
                        )}

                        {values.persona === "sharedInterestPersona" && (
                          <>
                            <Grid item xs={12}>
                              <div>
                                <label
                                  htmlFor="sharedInterest"
                                  className="form-label"
                                >
                                  Recipient's ethnicity
                                </label>
                              </div>
                            </Grid>
                            <Grid item xs={12}>
                              <Field
                                id="sharedInterest"
                                name="sharedInterest"
                                placeholder={initialValues.sharedInterest}
                                className="form-input"
                              />
                            </Grid>
                          </>
                        )}

                        <Grid item xs={12} md={12}>
                          <div>
                            <label htmlFor="leadBio" className="form-label">
                              Recipient's bio
                            </label>
                            <Field
                              id="leadBio"
                              name="leadBio"
                              placeholder={initialValues.leadBio}
                              component="textarea"
                              rows="4"
                              className="form-textarea"
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </div>
                <div
                  style={{
                    width: "100%",
                    bottom: 0,
                    position: "fixed",
                  }}
                >
                  <Grid container style={{ paddingRight: 271 }}>
                    <Grid
                      item
                      lg={6}
                      style={{
                        display: "flex",
                        background: "white",
                        borderTop: "1px solid #DDD",
                        zIndex: 99,
                        width: "100%",
                      }}
                    >
                      <div style={{ flexGrow: 1 }} />
                      <button
                        type="submit"
                        className={`cool-button ${
                          isGeneratingDraft ? "animate" : ""
                        }`}
                        style={{
                          marginTop: 16,
                          width: 130,
                          fontSize: 18,
                          marginBottom: 16,
                          marginRight: 16,
                        }}
                        disabled={isGeneratingDraft}
                      >
                        {isGeneratingDraft ? "Generating" : "Generate"}
                      </button>
                    </Grid>
                    <Grid item lg={6}></Grid>
                  </Grid>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
      <ToastContainer />
    </Box>
  );
}
