import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  setDoc,
} from "firebase/firestore";
import { UserContext } from "jsx/context";
import { batchLimitParallel } from "jsx/utils/batch";
import { getContentWhereFieldInArray } from "jsx/utils/firestore";
import _ from "lodash";
import { useContext } from "react";

export function useLeadsService(campaignId) {
  const db = getFirestore();
  const { user, workspace } = useContext(UserContext);

  async function addLeads(leads, campaign) {
    let someLeadEmailsExists = false;
    const emailsToAdd = leads.map((contact) => contact.email);

    const leadsCollectionRef = collection(
      db,
      [
        "workspaces",
        workspace.id,
        "workspaces_private",
        "data",
        "campaigns",
        campaignId,
        "leads",
      ].join("/")
    );

    const existingLeads = await getContentWhereFieldInArray({
      collectionRef: leadsCollectionRef,
      field: "email",
      valuesToSearchIn: emailsToAdd,
    });

    const existEmailsInDB = existingLeads.map((contact) => contact.email);
    if (existEmailsInDB.length) {
      someLeadEmailsExists = true;
    }

    const leadsToAdd = leads.filter(
      (contact) => !existEmailsInDB.includes(contact.email)
    );

    await batchLimitParallel({
      firestore: db,
      items: leadsToAdd,
      limit: 250,
      onEach: async (lead, batch) => {
        const leadRef = doc(
          db,
          "workspaces",
          workspace.id,
          "workspaces_private",
          "data",
          "campaigns",
          campaignId,
          "leads",
          lead.id
        );
        const convRef = doc(
          collection(
            db,
            "workspaces",
            workspace.id,
            "workspaces_private",
            "data",
            "campaigns",
            campaignId,
            "leads",
            lead.id,
            "conversations"
          )
        );

        batch.set(
          leadRef,
          {
            ...lead,
            createdAt: new Date(),
            updatedAt: new Date(),
            contactId: lead.id,
            status: "uncontacted",
          },
          { merge: true }
        );
        batch.set(
          convRef,
          {
            createdAt: new Date(),
            goal: campaign.goal,
            prompt: campaign.prompt,
            authedSender: campaign.authedSender,
          },
          { merge: true }
        );
      },
    });

    return {
      leadsToAdd,
      someLeadEmailsExists,
    };
  }

  async function createLead(options, campaign) {
    // Check if lead already exists
    const result = await getDoc(
      doc(
        db,
        "workspaces",
        workspace.id,
        "workspaces_private",
        "data",
        "campaigns",
        campaignId,
        "leads",
        options.id
      )
    );

    if (result.exists()) {
      alert(`Lead with the email ${options.email} already exists.`);
      return false;
    }

    await setDoc(
      doc(
        db,
        "workspaces",
        workspace.id,
        "workspaces_private",
        "data",
        "campaigns",
        campaignId,
        "leads",
        options.id
      ),
      { ...options, createdAt: new Date(), contactId: options.id },
      { merge: true }
    );

    getLeads();

    console.log("FINAL COUNTDOWN");

    await addDoc(
      collection(
        db,
        "workspaces",
        workspace.id,
        "workspaces_private",
        "data",
        "campaigns",
        campaignId,
        "leads",
        options.id,
        "conversations"
      ),
      {
        createdAt: new Date(),
        goal: campaign.goal,
        prompt: campaign.prompt,
        authedSender: campaign.authedSender,
      }
    );

    return true;
  }

  async function editLead(options, leadId) {
    const result = await setDoc(
      doc(
        db,
        "workspaces",
        workspace.id,
        "workspaces_private",
        "data",
        "campaigns",
        campaignId,
        "leads",
        leadId
      ),
      { ...options },
      { merge: true }
    );

    getLead(leadId, campaignId);
  }

  async function deleteLead(leadId, callback) {
    await deleteDoc(
      doc(
        db,
        "workspaces",
        workspace.id,
        "workspaces_private",
        "data",
        "campaigns",
        campaignId,
        "leads",
        leadId
      )
    );

    getLeads();

    callback(); //setLeads(null);
  }

  async function getLead(leadId, callback) {
    const q = await getDoc(
      doc(
        db,
        "workspaces",
        workspace.id,
        "workspaces_private",
        "data",
        "campaigns",
        campaignId,
        "leads",
        leadId
      )
    );

    callback({
      id: q.id,
      ...q.data(),
    });
  }

  async function getLeads(callback) {
    const q = await getDocs(
      collection(
        db,
        "workspaces",
        workspace.id,
        "workspaces_private",
        "data",
        "campaigns",
        campaignId,
        "leads"
      )
    );

    let map = q.docs.map((e) => {
      return {
        id: e.id,
        ...e.data(),
      };
    });

    map = _.orderBy(map, "createdAt.seconds", "desc");

    if (callback) {
      callback(map);
    }

    // const q = await getDocs(
    //   query(
    //     collection(
    //       db,
    //       "workspaces",
    //       workspace.id,
    //       "workspaces_private",
    //       "data",
    //       "contacts"
    //     ),
    //     where("__name__", "in", goal.transcripts)
    //   )
    // );

    // let map = q.docs.map((e) => {
    //   return {
    //     id: e.id,
    //     ...e.data(),
    //   };
    // });

    // map = _.orderBy(map, "createdAt.seconds", "desc");

    // if (callback) {
    //   callback(map);
    // }
  }

  return { createLead, editLead, deleteLead, getLead, getLeads, addLeads };
}
