import React from "react";

export const UserContext = React.createContext(
  {
    user: {
      displayName: "",
      photoURL: "",
      uid: "",
      email: "",
      organizations: [],
    },
  },
  { company: {} }
);
