import { Paper } from "@mui/material";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { useContext, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { UserContext } from "../../context/UserContext";
import { AuthSendersSection } from "./components/AuthSendersSection/AuthSendersSection";
import { CampaignSection } from "./components/CampaignSection/CampaignSection";
import { ConversationSection } from "./components/ConversationSection/ConversationSection";
import { FlowSection } from "./components/FlowSection/FlowSection";

export function PromptSection({
  campaign,
  conversation,
  getCampaign,
  getConversation,
  saveConversation,
}) {
  const [searchParams] = useSearchParams();
  const [showCampaignSection, setShowCampaignSection] = useState(
    searchParams.get("new")
  );
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [flow, setFlow] = useState({});

  async function saveCampaign(values) {
    const q = await setDoc(
      doc(
        db,
        "workspaces",
        workspace.id,
        "workspaces_private",
        "data",
        "campaigns",
        campaign.id
      ),
      values,
      { merge: true }
    );
  }

  const db = getFirestore();
  const { workspace, isSiteAdminUNSAFE } = useContext(UserContext);

  return (
    <Paper
      variant="outlined"
      style={{ padding: 24, marginTop: 48, paddingTop: 32, paddingBottom: 32 }}
    >
      {campaign && (
        <CampaignSection
          campaign={campaign}
          showCampaignSection={showCampaignSection}
          showAdvanced={showAdvanced}
          isSiteAdminUNSAFE={isSiteAdminUNSAFE}
          getCampaign={getCampaign}
          saveCampaign={saveCampaign}
          setShowAdvanced={setShowAdvanced}
          setShowCampaignSection={setShowCampaignSection}
        />
      )}
      {conversation && (
        <ConversationSection
          conversation={conversation}
          showAdvanced={showAdvanced}
          showCampaignSection={showCampaignSection}
          isSiteAdminUNSAFE={isSiteAdminUNSAFE}
          getConversation={getConversation}
          saveConversation={saveConversation}
          setShowAdvanced={setShowAdvanced}
          setShowCampaignSection={setShowCampaignSection}
        />
      )}
      {campaign && (
        <AuthSendersSection
          campaign={campaign}
          getCampaign={getCampaign}
          saveCampaign={saveCampaign}
        />
      )}
      {(flow || true) && (
        <FlowSection
          flow={flow}
          campaign={campaign}
          conversation={conversation}
          getCampaign={getCampaign}
          saveCampaign={saveCampaign}
        />
      )}
    </Paper>
  );
}
