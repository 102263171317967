import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { SideMenu } from "jsx/components";
import { useContact } from "jsx/hooks";
import { useState } from "react";

import { ColdEmailForm, DraftsSection } from "./components";

export function Draft() {
  const { contact } = useContact();
  const [currentDraft, setCurrentDraft] = useState();
  const [drafts, setDrafts] = useState();

  return (
    <>
      <Grid container style={{ minHeight: "100vh" }}>
        <Box
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "start",
            alignItems: "start",
            minHeight: "100%",
          }}
        >
          <SideMenu />
          <Grid
            container
            style={{
              flexGrow: 1,
              background: "#F9FAFC",
              height: "100%",
              marginLeft: 0,
            }}
          >
            <Grid
              item
              md={12}
              lg={6}
              sx={{
                height: { lg: "calc(100vh - 16px)" },
                overflowY: { lg: "auto", md: "" },
                paddingTop: 0,
                paddingLeft: 0,
              }}
            >
              <div
                style={{
                  background: "white",
                  display: "flex",
                  flexDirection: "column",
                  padding: 24,
                  borderBottom: "1px solid #E9E9E9",
                }}
              >
                <h3 style={{ marginBottom: 4, fontWeight: 500, marginTop: 0 }}>
                  Draft
                </h3>
                <div style={{ fontSize: 14, color: "#666", marginBottom: -8 }}>
                  Write cold emails that actually work and get responses.
                </div>
              </div>

              <ColdEmailForm
                drafts={drafts}
                defaultContact={contact}
                setDrafts={setDrafts}
                setCurrentDraft={setCurrentDraft}
                key={contact}
              />
            </Grid>
            <DraftsSection drafts={drafts} currentDraft={currentDraft} />
          </Grid>
        </Box>
      </Grid>
    </>
  );
}
